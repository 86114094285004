@charset "utf-8";

// ////////////////////
// //newsTop
// ////////////////////
// .newsTop{
// 	position: relative;
// 	top: -36px;
// 	margin-bottom: -36px;
// 	align-items: center;
// 	z-index: 2;
// 	height: 70px;
// 	overflow: hidden;

// 	@include media('tab'){
// 		overflow: visible;
// 		height: auto;
// 		align-items: unset;
// 	}

// 	@include media(){
// 		top:-20px;
// 		margin-bottom: -20px;
// 		overflow: visible;
// 		height: auto;
// 	}
	
// 	&__inner{
// 		width: calc(100% - 146px);

// 		@include media('tab'){
// 			flex: 0 0 100%;
// 		}
// 	}
// 	&__link{
// 		background-color: #ffffff;
// 		text-decoration: none;
// 		color: #212121;
// 		padding:21px 40px;
// 		flex-wrap: nowrap;
// 		@include media('tab'){
// 			padding:15px;
// 		}

// 		@include media(){
// 			padding:12px 15px;
// 			flex-wrap: wrap;
// 		}
// 	}
// 	&__date{
// 		font-size: 1.4rem;
// 		margin-right: 20px;

// 		@include media(){
// 			font-size: 1.0rem;
// 			margin-right: 10px;
// 		}
// 	}
// 	&__cat{
// 		font-size: 1.2rem;
// 		background-color: #EDEDED;
// 		padding:5px 10px;
// 		margin-right: 20px;
// 		width: 94px;
// 		flex: 0 0 94px;
// 		text-align: center;
		
// 		@include media('tab'){
			
// 		}
// 		@include media(){
// 			font-size: 1.0rem;
// 			padding:1px 5px;
// 		}
// 	}
// 	&__ttl{
// 		font-size: 1.6rem;
// 		@include media(){
// 			font-size: 1.3rem;
// 			margin-top:9px;
// 		}
// 	}
// 	&__ctr{
// 		flex: 0 0 146px;
// 		max-width: 146px;
// 		height: 70px;
// 		min-height: 70px;
// 		@include media('tab'){
// 			height: auto;
// 			min-height: auto;
// 			display: none;
// 		}
// 		@include media(){
// 			display: none;
// 		}
// 		li{
// 			height: 100%;
// 			a{
// 				border-left: 1px solid #ffffff;
// 				background-color: #212121;
// 				height: 100%;
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				padding:0 15px;
// 			}
// 		}
// 	}
// 	&__prev{
// 		a{
// 			width:42px;
// 			img{
// 				width:7.2px;
// 			}
// 		}
// 	}
// 	&__next{
// 		a{
// 			width:42px;
// 			img{
// 				width:7.2px;
// 			}
// 		}
// 	}
// 	&__menu{
// 		a{
// 			padding: 0 20px;
// 			width:62px;
// 			position: relative;
// 			span{
// 				background-color: #ffffff;
// 				height: 1px;
// 				display: block;
// 				position: absolute;
// 				width:18px;
// 				left: 20px;

// 				&:nth-of-type(1){
// 					top:calc(50% - 6.5px);
// 				}
// 				&:nth-of-type(2){
// 					top:calc(50% - 1px);
// 				}
// 				&:nth-of-type(3){
// 					bottom:calc(50% - 6.5px);
// 				}
// 			}
// 		}
// 	}
// }



////////////////////
//news
////////////////////
.news{
	// padding-top: 55px;
	// padding-bottom: 70px;
	// @include media('tab') {
	// 	padding-left: 30px;
	// 	padding-right: 30px;
	// }
	// @include media() {
	// 	padding:25px 20px 30px 20px;
	// }
	padding-bottom: 100px;
	@include media() {
		padding-bottom: 60px;
	}
	.newsList{
		padding-top: 50px;
		@include media() {
			padding-top: 0;
		}
	}
	&__article{
		padding-top: 80px;
		padding-bottom: 45px;
		@include media() {
			padding-top: 0;
		}
		.articleDetails__ttl{
			margin-bottom: 37px;
			@include media() {
				margin-bottom: 20px;
			}
		}
	}
	&__ttl{
		text-align: center;
		margin-bottom: 40px;
		@include media() {
			text-align: left;
			margin-bottom: 18px;
		}
	}
	&__ttl-en{
		display: block;
	}
	&__list{
		margin-bottom: 50px;
		@include media() {
			margin-bottom: 13px;
		}
	}
	&__item{
		padding-bottom: 28px;
		border-bottom: 1px solid #DCDDDD;
		margin-bottom: 26px;
		&:last-of-type{
			border:none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		@include media() {
			margin-bottom: 20px;
			padding-bottom: 14px;
		}
	}
	&__link{
		text-decoration: none;
		color:#212121;
		flex-wrap: nowrap;
		@include media('tab') {
			flex-wrap: nowrap;
		}
		@include media() {
			flex-wrap: wrap;
		}
	}
	&__cat{
		font-size: 1.2rem;
		background-color: #EDEDED;
		padding:5px 0;
		text-align: center;
		width:94px;
		flex: 0 0 94px;
		margin-right: 30px;
		@include media('tab') {
		}
		@include media() {
			font-size: 1.0rem;
			width:60px;
			flex: 0 0 60px;
			padding:1px 0;
			margin-right: 10px;
		}
	}
	&__date{
		font-size: 1.2rem;
		margin-right: 30px;
		@include media() {
			font-size: 1.1rem;
		}
	}
	&__headline{
		font-size: 1.6rem;
		@include media('tab') {
			margin-top:10px;
		}
		@include media() {
			font-size: 1.3rem;
			margin-top:6px;
		}
	}
	&__bt{
		width:180px;
		margin-left: auto;
		margin-right: auto;
	}
}

////////////////////
//newsList
////////////////////
.newsList{
	padding-bottom: 100px;
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	@include media() {
		padding-bottom: 60px;
	}
	&__item{
		border-bottom: 1px solid #efefef;
		@include media() {
		}
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__link{
		text-decoration: none;
		color: #212121;
		flex-wrap: nowrap;
		padding: 20px 0;
		align-items: center;
		@include media() {
			flex-wrap: wrap;
		}
		&:hover{
			.newsList__ttl{
				text-decoration: underline;
			}
		}
		
	}
	&__date{
		margin-right: 20px;
		@include media() {
			order:2;
			font-size: 1.1rem;
		}
	}
	&__cat{
		font-size: 1.2rem;
		background-color: #fff;
		padding: 3px 0;
		text-align: center;
		width: 92px;
		flex: 0 0 92px;
		margin-right: 22px;
		@include media() {
			order:1;
			font-size: 1.0rem;
			width: 60px;
			flex: 0 0 60px;
			padding: 1px 0;
			margin-right: 10px;
		}
	}
	&__ttl{
		font-size: 1.6rem;
		@include media('tab') {
			
		}
		@include media() {
			order:3;
			font-size: 1.3rem;
			margin-top: 6px;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}


.snsList{
	position: relative;
	z-index: 2;
	&__item{
		width:148px;
		padding:0 4px;
		@include media() {
			width: 50%;
		}
	}
	&__link{
		font-size: 1.3rem;
		font-weight: 700;
		text-align: center;
		color:#ffffff;
		display: block;
		text-decoration: none;
		padding:7px 0;
		position: relative;
		display: flex;
		justify-content: center;
		align-items:center;
		border-radius: 4px;
		@include media() {
			font-size: 1.2rem;
		}
		&:hover{
			position: relative;
			top: 4px;
		}
		&--tw{
			background-color: #1DA1F2;
			&:before{
				content:"";
				background: url(../img/icon_twitter.svg) no-repeat center center / cover;
				width:23px;
				height: 19px;
				display: block;
				margin-right: 6px;
				@include media() {
					width:19px;
					height: 16px;
					margin-right: 5px;
				}
			}
		}
		&--fb{
			background-color: #1777F2;
			&:before{
				content:"";
				background: url(../img/icon_facebook.svg) no-repeat center center / cover;
				width:10px;
				height: 18px;
				display: block;
				margin-right: 6px;
				@include media() {
					width:9px;
					height: 16px;
					margin-right: 5px;
				}
			}
			
		}
	}
}
