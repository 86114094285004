$bpc: 1341px; // PC
$pc: 1340px; // PC
$lpc: 1170px; // ノート
$tab: 1080px; // タブレット
$sp: 768px;  // スマホ
@mixin bpc {
  @media (min-width: ($pc)) {
    @content;
  }
}

@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}
@mixin lpc {
  @media (max-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

////////////////////////////////////////
//interview
////////////////////////////////////////
// .breadcrumb{
//     @include sp {
//         display: none !important;
//     }
// }
.interview{
    font-size: 1.6rem;
    padding: 0 40px 100px 40px;
    background-color: #fff;
    color: #333333;
    margin-top: 37px;
    @include sp {
        margin-top: 0;
        font-size: 1.3rem;
        padding: 0 20px 40px 20px;
    }
    &.wrap{
        @include sp {
            padding-bottom: 40px;
        }
    }
    &__header{
        margin-left: -40px;
        margin-right: -40px;
        margin-bottom: 40px;
        @include sp {
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: 14px;
        }
        &Ttl{}
        &Sp{
            display: none;
            @include sp {
                display: block;
                padding: 20px 20px 0 20px;
            }
        }
        &Comp{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
        &Cat{
            font-size: 1.0rem;
            color: #fff;
            font-weight: bold;
            background-color: #333;
            padding: 4px 10px;
            border-radius: 5px;
        }
        &Detail{
            font-size: 1.3rem;
            font-weight: bold;
            padding-left: 8px;
        }
        &SpTtl{
            font-size: 2.4rem;
            font-weight: bold;
        }
    }
    &__body{}
    &__toc{}
    .toc{
        background-color: #f9f9f9;
        border-radius: 5px;
        display: flex;
        &__fig{
            width: 407px;
            max-width: 407px;
            flex-basis: 407px;
            @include tab{
                width: 40%;
                max-width: 40%;
                flex-basis: 40%;
            }
            @include sp{
                width: 100%;
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        &__img{
            border-radius: 5px 0 0 5px;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            height: 100%;
            @include sp {
                width: 100%;
                max-height: 180px;
                object-fit: cover;
                border-radius: 0;
            }
        }
        &__list{
            padding: 24px 30px;
            width: calc(100% - 407px);
            max-width: calc(100% - 407px);
            flex-basis: calc(100% - 407px);
            @include tab{
                width: calc(100% - 40%);
                max-width: calc(100% - 40%);
                flex-basis: calc(100% - 40%);
            }
            @include sp {
                display: none;
            }
        }
        &__item{
            margin-bottom: 8px;
            display: flex;
            align-items: flex-start;
            list-style: none;
            &::before{
                content: "・";
                margin-top: -2px;
            }
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        &__link{
            font-size: 1.4rem;
            &:hover{
                text-decoration: none;
            }
        }
    }
    &__intro{
        margin-top: 2em;
    }
    &__section{
        margin: 0;
        padding-top: 36px;
        &:last-of-type{
            margin-bottom: 0;
        }
        @include sp {
            margin: 18px 0 18px 0;
            padding-top: 0;
        }
    }
    &__ttl{
        font-size: 2.2rem;
        font-weight: bold;
        padding-bottom: 12px;
        border-bottom: 2px solid #333333;
        margin-bottom: 30px;
        @include sp {
            font-size: 1.8rem;
            padding-bottom: 7px;
            margin-bottom: 15px;
        }
    }
    &__item{
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        background-color: #f9f9f9;
        padding: 25px 30px;
        border-radius: 5px;
        &:last-of-type{
            // margin-bottom: 0;
        }
        @include sp {
            flex-wrap: wrap;
            padding: 12px 15px;
            margin-bottom: 10px;
        }
    }
    &__name{
        max-width: 90px;
        flex-basis: 90px;
        text-align: center;
        justify-content: center;
        height: 40px;
        align-items: center;
        display: flex;
        background-color: #333333;
        color: #fff;
        font-weight: bold;
        border-radius: 40px;
        font-size: 1.4rem;
        @include sp {
            font-size: 1.1rem;
            max-width: auto;
            flex-basis: auto;
            height: 20px;
            padding: 0 9px;
            margin-bottom: 9px;
        }
        &_type_cli{
            font-size: 1.6rem;
            background-color: #d80c17;
            color: #fff;
            @include sp {
                font-size: 1.1rem;
            }
        }
    }
    &__message{
        max-width: calc(100% - 90px);
        flex-basis: calc(100% - 90px);
        padding-left: 30px;
        line-height: 1.625;
        @include sp {
            max-width: 100%;
            flex-basis: 100%;
            padding-left: 0;
            line-height: 1.4;
        }
        &Para{
            margin-bottom: 1.5em;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        &Link{
            &:hover{
                text-decoration: none;
            }
        }

        &St{
            color: #ff9c00;
            font-weight: bold;
        }
    }
    &__fig{
        margin-bottom: 20px;
        @include sp {
            margin-bottom: 10px;
        }
    }
    &__img{
        width: 100%;
        height: auto;
    }
    &__footer{
        background-color: #f9f9f9;
        padding: 25px 30px;
        border-radius: 5px;
        margin-top: 20px;
        @include sp {
            margin-top: 10px;
        }
        &Ttl{
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 12px;
            @include sp {
                font-size: 1.6rem;
                margin-bottom: 8px;
            }
        }
        &List{}
        &Item{
            line-height: 1.625;
        }
        &Link{
            &:hover{
                text-decoration: none;
            }
        }
    }
}