@charset "utf-8";

////////////////////
//oem
////////////////////
.oem{
	padding-bottom: 105px;
	@include media(){
		padding-bottom: 30px;
	}
	&__container{
		padding-top: 65px;
		margin-bottom: -40px;
		@include media(){
			padding-top: 0;
			margin-bottom:  -20px;
		}
	}
	&__ttl{
		margin-bottom: 45px;
		@include media(){
			margin-bottom: 20px;
		}
	}

	&__section{
		margin-bottom: 40px;
		@include media(){
			margin-bottom: 20px;
		}
		&_col_02{
			width: 50%;
			flex-basis: 50%;
			max-width: 50%;
			display: flex;
			flex-direction: column;
			@include media(){
				width: 100%;
				flex-basis: 100%;
				max-width: 100%;
			}
		}
		
	}
	&__subTtl{
		font-size: 2.4rem;
		font-weight: 700;
		text-align: center;
		background: url(../img/bg_pt_or03.png);
		line-height: 1;
		padding: 27px 0;
		@include media(){
			font-size: 1.6rem;
			padding: 10px 15px;
		}
	}
	&__list{
		display: flex;
		padding: 40px 16px 43px 16px;
		background-color: #fff;
		flex-wrap: wrap;
		@include media(){
			padding: 30px 20px;
		}
	}
	&__item{
		width: 33.333%;
		flex-basis: 33.333%;
		max-width: 33.333%;
		padding:0 24px;
		position: relative;
		display: flex;
		flex-direction: column;
		@include media(){
			width: 100%;
			flex-basis: 100%;
			max-width: 100%;
			padding: 0;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&:last-of-type{
			&:after{
				display: none;
			}
		}
		&:after{
			content: "";
			width: 1px;
			height: 100%;
			background-color: #efefef;
			display: block;
			position: absolute;
			right: 0px;
			top: 0;
			@include media(){
				width: 100%;
				height: 1px;
				position: static;
				margin: 20px 0;
			}
		}
		&Inner{
			height: 100%;
		}
	}
	&__fig{
		margin-bottom: 16px;
		@include media(){
			margin-bottom: 13px;
		}
		a{
			&:hover{
				opacity: .75;
			}
		}
		img{
			width: 100%;
		}
	}
	&__name{
		font-size: 2.0rem;
		font-weight: 600;
		text-align: center;
		margin-bottom: 8px;
		@include media(){
			font-size: 1.6rem;
			margin-bottom: 10px;
		}
	}
	&__desc{
		font-size: 1.4rem;
		@include media(){
			font-size: 1.1rem;
			line-height: 1.63;
		}
	}
	&__bt{
		width: 100%;
		margin-top: 16px;
		@include media(){
			margin-top: 13px;
			max-width: 100%;
		}
	}
	&__wrap{
		display: flex;
		flex-wrap: wrap;
		margin-left: -18px;
		margin-right: -18px;
		@include media(){
			margin-left: 0;
			margin-right: 0;
		}
		.oem__section{
			padding-left: 18px;
			padding-right: 18px;
			@include media(){
				padding: 0;
			}
		}
		.oem__itemInner{
			padding: 40px 40px 0 40px;
			background-color: #ffffff;
			@include media(){
				padding: 20px 20px 0 20px;
			}
		}
		.oem__bt{
			max-width: 100%;
			margin-top: 0;
			&Inner{
				background-color: #fff;
				padding:21px 40px 40px 40px;
				@include media(){
					padding: 13px 20px 20px 20px;
				}
			}

		}
	}
}
