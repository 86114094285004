@charset "utf-8";


////////////////////
//hardCandy
////////////////////
.hardCandy {
	&__container {
		padding: 70px 0 96px;
		@include media() {
			padding: 12px 0 38px;
		}
	}
	&__headCopy {
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 1.75;
		margin-bottom: 32px;
		text-align: center;
		@include media() {
			font-size: 1.6rem;
			margin-bottom: 14px;
		}
	}
	&__headList {
		flex-wrap: wrap;
		margin: 0 auto 42px;
		max-width: 800px;
		@include media() {
			margin-bottom: 20px;
			background-color: #fff;
			padding: 17px 20px;
		}
	}
	&__headItem {
		margin-bottom: 14px;
		padding: 0 20px;
		position: relative;
		@include media() {
			font-size: 1.2rem;
			list-style: disc inside;
			padding:0;
			margin: 0;
			line-height: 2;
			width: 100%;
		}
	}
	&__headItem:nth-of-type(5):after,
	&__headItem:last-of-type:after {
		content: none;
	}
	&__headItem:after {
		background: #9fa0a0;
		content: "";
		display: block;
		position: absolute;
		top: 7px;
		right: 0;
		width: 1px;
		height: 13px;
		@include media() {
			display: none;
		}
	}
	&__headLink {
		color: inherit;
		@include media() {
			text-decoration: none;
		}
	}
	&__headLink:hover {
		text-decoration: none;
	}
}
.lot__ttl,
.flow__ttl,
.shape__ttl,
.package__ttl,
.trial__ttl,
.testing__ttl,
.price__ttl,
.material__ttl,
.recipe__ttl {
	background: url(../img/bg_orange.png) repeat;
	font-size: 2.4rem;
	font-weight: 700;
	height: 78px;
	@include media() {
		font-size: 1.6rem;
		height: auto;
		padding: 8px 15px;
	}
}
.lot__contents,
.flow__contents,
.shape__contents,
.package__contents,
.trial__contents,
.testing__contents,
.price__contents,
.material__contents,
.recipe__contents {
	background: #fff;
	margin-bottom: 32px;
	padding: 36px 42px;
	@include media() {
		margin-bottom: 18px;
		padding: 12px 15px;
	}
}
.lot {
	&__desc {
		font-size: 1.8rem;
		font-weight: 700;
		position: relative;
		text-align: center;
		width: 50%;
		@include media() {
			font-size: 1.3rem;
		}
	}
	&__desc:first-child:after {
		background: #dcdddd;
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 20px;
	}
}
.flow {
	&__desc {
		font-size: 1.8rem;
		font-weight: 700;
		margin-bottom: 16px;
		@include media() {
			font-size: 1.3rem;
			margin-bottom: 8px;
		}
	}
	&__list {
		counter-reset: num;
		flex-wrap: wrap;
		margin-bottom: 6px;
	}
	&__item {
		font-weight: 600;
		align-items: center;
		background: #f5f5f5;
		counter-increment: num;
		display: flex;
		height: 58px;
		margin: 0 5px 5px 0;
		position: relative;
		max-width: 195px;
		width: 19.5%;
		@include media('tab') {
			height: auto;
			padding: 6px 0;
			width: 19%;
		}
		@include media() {
			font-size: 1.3rem;
			max-width: none;
			width: calc(33.3333% - 5px);
		}
		@include media('spm') {
			margin-right: 0;
			width: 100%;
		}
	}
	&__item:nth-child(5n) {
		margin-right: 0;
		&:after {
			content: none;
		}
		@include media() {
			margin-right: 5px;
			&:after {
				content: "";
			}
		}
	}
	&__item:nth-child(3n),
	&__item:last-child {
		@include media() {
			margin-right: 0;
			&:after {
				content: none;
			}	
		}
	}
	&__item:before {
		align-items: center;
		background: #f39f62;
		border-radius: 5px;
		color: #fff;
		content: counter(num);
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		font-size: 1.2rem;
		height: 30px;
		margin: 0 10px 0 13px;
		width: 30px;
	}
	&__item:after {
		background: #dcdddd;
		content: "";
		display: block;
		position: absolute;
		height: 2px;
		top: 50%;
		right: -5px;
		width: 5px;
		@include media('spm') {
			content: none;
		}
	}
	&__notice {
		display: block;
		font-size: 1.4rem;
		margin-bottom: 32px;
		@include media() {
			font-size: 1.2rem;
		}
	}
	&__btn {
		justify-content: center;
		margin-bottom: 16px;
		@include media() {
			margin-bottom: 8px;
		}
	}
	&__link {
		height: 66px;
		width: 340px;
		@include media() {
			height: auto;
			width: 200px;
		}
		@include media('spm') {
			width: calc(50% - 14px);
		}
	}
	&__link:first-child {
		margin-right: 22px;
		@include media('spm') {
			margin-right: 14px;
		}
	}
}
.shape {
	&__intro{
		font-size: 1.6rem;
		line-height: 1.75;
		margin-bottom: 23px;
		@include media() {
			font-size: 1.2rem;
			line-height: 1.6;
			margin-bottom: 12px;
		}
	}
	&__spScroll {
		@include media() {
			overflow-x: scroll;
		}
	}
	&__table {
		width: 100%;
		margin-bottom: 8px;
		@include media() {
			min-width: 640px;
		}
		&_col_01{
			@include media() {
				min-width: auto;
			}
			.shape__th{
				width:192px;
				&_w{
					&_none{
						width: auto;
						@include media() {
							min-width: auto;
						}
					}
				}
				&_col_2{
					width: 96px;
					@include media() {
						width: 84px;
						min-width: auto;
					}
				}
				&_text_ctr{
					width: 96px;
					@include media() {
						width: 53px;
					}
				}
			}
			.shape__td{
				width: calc(100% - 192px);
				@include media() {
					width: auto;
				}
			}
			img{
				max-width: 57px;
			}
		}
	}
	&__thead &__th:first-child,
	&__tbody &__th {
		background: #f0f0f0;
		padding: 22px 0 22px 20px;
		text-align: left;
		@include media() {
			padding: 8px 15px;
		}
	}
	&__th{
		
	}
	&__tbody &__th_col_2 {
		@include media('tab') {
			min-width: 84px;
		}
		@include media() {
			min-width: auto;
		}
	}
	&__tbody &__th_text_ctr {
		text-align: center;
		padding: 0;
		@include media('tab') {
			min-width: 70px;
			padding-top: 8px;
			padding-bottom: 8px;
		}
		@include media() {
			min-width: auto;
		}
	}
	&__th {
		border: 1px solid #d7d7d7;
		font-size: 1.4rem;
		font-weight: 700;
		vertical-align: middle;
		// width: 192px;
		@include media() {
			font-size: 1.2rem;
		}
	}
	&__td {
		border: 1px solid #d7d7d7;
		font-size: 1.4rem;
		padding: 20px 0;
		text-align: center;
		vertical-align: middle;
		width: calc((100% - 192px) / 5);
		width: 160px;
		@include media() {
			font-size: 1.2rem;
			padding: 8px 15px;
			width: 100px;
		}
	}
	&__notice {
		display: block;
		font-size: 1.4rem;
		margin-bottom: 6px;
		@include media() {
			font-size: 1.2rem;
		}
	}
}
.package {
	&__flex {
		margin-bottom: 26px;
		@include media() {
			flex-wrap: wrap;
		}
	}
	&__fig {
		align-items: flex-start;
		border-bottom: 1px solid #d7d7d7;
		border-left: 1px solid #d7d7d7;
		border-top: 1px solid #d7d7d7;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		padding: 26px 0;
		width: 226px;
		@include media('tab') {
			padding: 26px 10px;
			width: 20%;
		}
		@include media() {
			border-right: 1px solid #d7d7d7;
			border-bottom: none;
			width: 100%;
		}
	}
	&__table {
		width: 770px;
		@include media('tab') {
			width: 80%;
		}
		@include media() {
			width: 100%;
		}
	}
	&__spScroll {
		@include media('tab') {
			width: 100%;
		}
		@include media() {
			overflow-x: scroll;
			width: 100%;
		}
	}
	&__spScroll &__table {
		@include media('tab') {
			width: 100%;
		}
		@include media() {
			min-width: 540px;
		}
	}
	&__th {
		background: #f0f0f0;
		border: 1px solid #d7d7d7;
		font-size: 2rem;
		font-weight: 700;
		padding: 16px 0;
		@include media() {
			font-size: 1.5rem;
			padding: 8px 0;
		}
	}
	&__td {
		border: 1px solid #d7d7d7;
		font-size: 1.4rem;
		padding: 12px 0;
		text-align: center;
		vertical-align: middle;
		@include media() {
			font-size: 1.2rem;
			padding: 8px 0;
		}
	}
	&__td_font_l {
		font-size: 1.6rem;
		padding: 30px 0;
		@include media() {
			font-size: 1.3rem;
			padding: 8px 10px;
		}
	}
	&__td_type_img {
		padding: 34px 0;
		@include media() {
			padding: 12px 0;
		}
	}
	&__notice {
		font-size: 1.4rem;
		line-height: 1.8;
		@include media() {
			font-size: 1.2rem;
		}
	}
}
.trial {
	&__contents {
		margin-bottom: 42px;
		@include media() {
			margin-bottom: 18px;
		}
	}
	&__dl {
		@include media('spm') {
			flex-wrap: wrap;
		}
	}
	&__dt {
		background: #f0f0f0;
		border-left: 1px solid #d7d7d7;
		border-top: 1px solid #d7d7d7;
		font-size: 1.6rem;
		font-weight: 700;
		padding: 20px 0 20px 20px;
		width: 224px;
		@include media() {
			font-size: 1.3rem;
			width: 30%;
		}
		@include media('spm') {
			border-right: 1px solid #d7d7d7;
			padding: 8px 15px;
			width: 100%;
		}
	}
	&__dd {
		border-left: 1px solid #d7d7d7;
		border-right: 1px solid #d7d7d7;
		border-top: 1px solid #d7d7d7;
		font-size: 1.6rem;
		padding: 20px 24px 20px 24px;
		width: calc(100% - 224px);
		@include media() {
			font-size: 1.3rem;
			width: 70%;
		}
		@include media('spm') {
			padding: 8px 15px;
			width: 100%;
		}
	}
	&__dl:last-child &__dt,
	&__dl:last-child &__dd {
		border-bottom: 1px solid #d7d7d7;
	}
	&__notice {
		font-size: 1.4rem;
		@include media() {
			font-size: 1.2rem;
		}
	}
}
.testing {
	&__desc {
		font-size: 1.6rem;
		margin-bottom: 22px;
		@include media() {
			font-size: 1.3rem;
			margin-bottom: 10px;
		}
	}
	&__dl {
		@include media('spm') {
			flex-wrap: wrap;
		}
	}
	&__dt {
		align-items: center;
		background: #f0f0f0;
		border-left: 1px solid #d7d7d7;
		border-top: 1px solid #d7d7d7;
		font-size: 1.6rem;
		font-weight: 700;
		display: flex;
		padding: 20px 0 20px 20px;
		width: 224px;
		@include media() {
			font-size: 1.3rem;
			width: 30%;
		}
		@include media('spm') {
			border-right: 1px solid #d7d7d7;
			padding: 8px 15px;
			width: 100%;
		}
	}
	&__dd {
		border-left: 1px solid #d7d7d7;
		border-right: 1px solid #d7d7d7;
		border-top: 1px solid #d7d7d7;
		font-size: 1.6rem;
		padding: 20px 24px 20px 24px;
		width: calc(100% - 224px);
		@include media() {
			font-size: 1.3rem;
			width: 70%;
		}
		@include media('spm') {
			padding: 8px 15px;
			width: 100%;
		}
	}
	&__dl:last-child &__dt {
		border-bottom: 1px solid #d7d7d7;
		@include media('spm') {
			border-bottom: none;
		}
	}
	&__dl:last-child &__dd {
		border-bottom: 1px solid #d7d7d7;
	}
	&__notice {
		font-size: 1.4rem;
		display: block;
		margin-top: .5em;
		@include media() {
			font-size: 1.2rem;
		}
	}
}
.price {
	&__desc {
		font-size: 1.6rem;
		line-height: 1.75;
		margin-bottom: 14px;
		@include media() {
			font-size: 1.3rem;
			margin-bottom: 8px;
		}
	}
	&__notice {
		font-size: 1.4rem;
		display: block;
		margin-bottom: 12px;
		@include media() {
			font-size: 1.2rem;
			margin-bottom: 0;
		}
	}
}
.material {
	&__desc {
		font-size: 1.6rem;
		line-height: 1.75;
		margin-bottom: 14px;
		@include media() {
			font-size: 1.3rem;
			margin-bottom: 8px;
		}
	}
	&__dl {
		background: #f0f0f0;
		padding: 0 22px;
		@include media() {
			padding: 0 12px 8px;
		}
	}
	&__dl:first-of-type {
		padding-top: 22px;
		@include media() {
			padding-top: 10px;
		}
	}
	&__dl:last-of-type {
		padding-bottom: 22px;
		margin-bottom: 12px;
		@include media() {
			padding-bottom: 10px;
		}
	}
	&__dt {
		align-items: center;
		font-size: 1.6rem;
		display: flex;
		flex-shrink: 0;
		height: 30px;
		@include media() {
			align-items: flex-start;
			font-size: 1.3rem;
			height: auto;
		}
	}
	&__dd {
		align-items: center;
		font-size: 1.6rem;
		display: flex;
		@include media() {
			align-items: flex-start;
			font-size: 1.3rem;
		}
	}
	&__notice {
		font-size: 1.4rem;
		display: block;
		margin-bottom: 12px;
		@include media() {
			font-size: 1.2rem;
			margin-bottom: 0;
		}
	}
}
.recipe {
	&__contents {
		margin-bottom: 0;
	}
	&__dl {
		@include media('spm') {
			flex-wrap: wrap;
		}
	}
	&__desc {
		font-size: 1.6rem;
		margin-bottom: 12px;
		@include media() {
			font-size: 1.3rem;
			margin-bottom: 8px;
		}
	}
	&__notice {
		font-size: 1.4rem;
		display: block;
		margin-bottom: 22px;
		@include media() {
			font-size: 1.2rem;
			margin-bottom: 8px;
		}
	}
	&__dt {
		align-items: center;
		background: #f0f0f0;
		border-left: 1px solid #d7d7d7;
		border-top: 1px solid #d7d7d7;
		font-size: 1.6rem;
		font-weight: 700;
		display: flex;
		padding: 18px 0 22px 20px;
		width: 224px;
		@include media() {
			font-size: 1.3rem;
			width: 30%;
		}
		@include media('spm') {
			border-right: 1px solid #d7d7d7;
			padding: 8px 15px;
			width: 100%;
		}
	}
	&__dd {
		border-left: 1px solid #d7d7d7;
		border-right: 1px solid #d7d7d7;
		border-top: 1px solid #d7d7d7;
		font-size: 1.6rem;
		padding: 18px 24px 22px 24px;
		width: calc(100% - 224px);
		@include media() {
			font-size: 1.3rem;
			width: 70%;
		}
		@include media('spm') {
			padding: 8px 15px;
			width: 100%;
		}
	}
	&__dl:last-child &__dt,
	&__dl:last-child &__dd {
		border-bottom: 1px solid #d7d7d7;
	}
	&__ddNotice {
		font-size: 1.4rem;
		display: block;
		@include media() {
			font-size: 1.2rem;
		}
	}
	&__combineDd {
		font-size: 1.4rem;
		line-height: 1.857;
		margin-bottom: 15px;
		@include media() {
			font-size: 1.1rem;
			margin-bottom: 8px;
		}
	}
	&__combineDd:last-of-type {
		margin-bottom: 0;
	}
	&__ddItem {
		margin-bottom: 2px;
	}
	&__red {
		color: #e83828;
		font-weight: 600;
	}
}