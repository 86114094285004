@charset "utf-8";

////////////////////
//process
////////////////////
.process{
	padding-bottom: 100px;
	@include media() {
		padding-bottom: 60px;
	}
	&Section{
		padding-top: 70px;
		@include media() {
			padding-top: 0;
		}
	}
	&__ttl{
		margin-bottom: 47px;
		@include media() {
			margin-bottom: 20px;
		}
	}
}


.tabSection{
	margin-bottom: 35px;
	@include media() {
		margin-bottom: 20px;
	}
	&:last-of-type{
		margin-bottom: 0;
	}
	&__ttl{
		font-size: 2.4rem;
		font-weight: 700;
		text-align: center;
		background: url(../img/bg_pt_or03.png);
		line-height: 1;
		padding: 25px 0;
		@include media() {
			font-size: 1.6rem;
			padding: 10px 15px;
		}
	}
	&__body{
		background-color: #fff;
		padding: 33px 40px;
		display: flex;
		justify-content: space-between;
		@include media() {
			padding: 17px 20px;
			display: block;
		}
	}
}

.processContent{
	&__para{
		font-size: 1.6rem;
		line-height: 1.65;
		margin-bottom: 1.5em;
		@include media() {
			font-size: 1.3rem;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__fig{
		display: flex;
		align-items: flex-start;
		margin-left: 20px;

		@include media('tab'){
			flex-direction: column;
			min-width: 260px;
		}
		@include media() {
			flex-direction: row;
			display: block;
			margin-left: 0;
			margin-top: 22px;
		}
	}
	&__img{
		min-width: 260px;
		width: 260px;
		flex-basis: 260px;
		height: auto;
		&:nth-of-type(n+2){
			margin-left: 10px;
			@include media('tab'){
				margin-left: 0;
				margin-top: 10px;
			}
		}
		@include media('tab'){
			flex-basis: auto;
		}
		@include media() {
			width: 100%;
			max-width: 100%;
			flex-basis: 100%;
			min-width: 100%;
			display: block;
		}
	}
}

.tabContents{
	display: none;
	&_current{
		display: block;
	}
	.page-section{
		margin-bottom: 36px;
		@include media() {
			margin-bottom: 25px;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
	}
}

.tabList{
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 60px;
	justify-content: center;
	@include media() {
		margin-left: -5px;
		margin-right: -5px;
		margin-bottom: 30px;
	}
	&__item{
		flex-basis: 353px;
		max-width: 353px;
		padding:0 10px;
		position: relative;
		&--col-2{
			flex: 0 0 50%;
			max-width: 50%;
		}
		@include media() {
			padding:0 5px;
			flex: 0 0 50%;
			max-width: 50%;
		}
		&_current{
			&:after{
				content:"";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 14px 17.5px 0 17.5px;
				border-color: #ee7c28 transparent transparent transparent;
				position: absolute;
				bottom:-14px;
				left: calc(50% - 17.5px);
				
				@include media() {
					border-width: 13px 10px 0 10px;
					left: calc(50% - 10px);
					bottom:-11px;
				}
			}
			.tabList__link{
				background: #ee7c28;
				color: #fff;
			}
		}
	}
	&__link{
		font-size: 1.8rem;
		font-weight: 700;
		color:#212121;
		text-decoration: none;
		display: block;
		text-align: center;
		padding:13px 0;
		border-radius: 4px;
		background: url(../img/bg_bl_pt01.png);
		@include media() {
			font-size: 1.4rem;
			padding:10px 0;
			font-weight: 700;
			letter-spacing: -.085em;
		}
		&:hover{
			background: #ee7c28;
			color: #fff;
		}
	}
}