////////////////////////////////////////
//title
////////////////////////////////////////
.sectionTtl{
	&_ly{
		&_ct{
			text-align: center;
		}
		&_lt{
			text-align: left;
		}
	}
	&__en{
		font-size: 2.0rem;
		font-weight: 700;
		color: #ec6c0e;
		display: block;
		line-height: 1;
		margin-bottom: 2px;
		font-family: Futura,'Noto Sans JP',Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", meiryo, sans-serif;
		@include media(){
			font-size: 1.5rem;
		}
	}
	&__jp{
		font-size: 4.4rem;
		font-weight: 800;
		color: #212121;
		display: block;
		letter-spacing: -.05em;
		@include media(){
			font-size: 2.6rem;
		}
		&_page_l{
			font-size: 4.2rem;
			@include media(){
				font-size: 2.6rem;
			}
		}
	}
}

.bdTtl{
	font-size: 2.4rem;
	font-weight: 700;
	text-align: center;
	@include media() {
		font-size: 1.8rem;
	}
}

////////////////////////////////////////
//para
////////////////////////////////////////
.sectionIntro{
	font-size: 1.6rem;
	@include media(){
		font-size: 1.2rem;
	}
	&_ly{
		&_ct{
			text-align: center;
		}
	}
	
}

////////////////////////////////////////
//bt
////////////////////////////////////////
.sqBt{
	background-color: #ec6c0e;
    color: #fff;
    font-weight: 700;
    display: block;
    padding: 20px 0;
    text-align: center;
    text-decoration: none;
    position: relative;
	font-size: 1.6rem;
	max-width: 345px;
	@include media(){
		font-size: 1.2rem;
		line-height: 1;
		padding:14px 10px;
		width: 200px;
	}
	&:hover{
		opacity: .75;
	}
	&:after{
		content: "";
		display: block;
		background: url(../img/arrow_icon_wh.svg) no-repeat center center/7px 14px;
		width: 7px;
		height: 14px;
		position: absolute;
		top:calc(50% - 7px);
		right: 16px;
		@include media(){
			width: 6px;
			height: 12px;
			right: 8px;
			top:calc(50% - 6px);
			background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px;
		}
	}
}

.btRd{
	background-color: #ec6c0e;
    color: #fff;
    font-weight: 700;
    display: block;
    padding: 20px 0;
    text-align: center;
    text-decoration: none;
    position: relative;
	font-size: 1.6rem;
	max-width: 354px;
	border-radius: 50px;
	
	&:hover{
		opacity: .75;
	}
	&:before{
		content:"";
		display: block;
		width: 25.4px;
		height: 16.1px;
		background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px;
		
	}
	&:after{
		content: "";
		display: block;
		background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px;
		width: 6px;
		height: 12px;
		position: absolute;
		top:calc(50% - 6px);
		right: 16px;
	}
}

.blogBt{
	width: 190px;
	font-size: 1.4rem;
	color: #fff;
	text-decoration: none;
	line-height: 1;
	padding:14px;
	border-radius: 40px;
	background:url(../img/bg_pt.png);
	background-color:rgba(255,255,255,0.35);
	background-blend-mode:lighten;
	display: block;
	text-align: center;
	&:hover{
		background-image: none;
		background-color: #c9caca;
	}
}

.btContact{
	background-color: #ec6c0e;
    color: #fff;
    font-weight: 700;
	display: flex;
	justify-content: center;
    padding: 26px 0;
    text-align: center;
    text-decoration: none;
    position: relative;
	font-size: 1.6rem;
	max-width: 354px;
	border-radius: 50px;
	align-items: center;
	@include media(){
		font-size: 1.2rem;
		line-height: 1;
		padding: 17.5px 0;
	}
	&:hover{
		opacity: .75;
	}
	&:before{
		content:"";
		display: block;
		width: 29px;
		height: 21.75px;
		background: url(../img/mail_icon.svg) no-repeat center center / cover;
		margin-right: 10px;
		@include media(){
			width: 20px;
			height: 15px;
			position: relative;
			margin-right: 6px;
			margin-left: -26px;
		}
		
	}
	&:after{
		content: "";
		display: block;
		background: url(../img/arrow_icon_wh.svg) no-repeat center center / 6px 12px;
		width: 6px;
		height: 12px;
		position: absolute;
		top:calc(50% - 6px);
		right: 25px;
	}
}

////////////////////////////////////////
//table
////////////////////////////////////////
.bdbTable{
	width: 100%;
	tr{
		border-bottom: 1px solid #d3d9dd;
		&:last-of-type{
			border-bottom: 0;
		}
	}
	th{
		width: 160px;
		min-width: 160px;
		font-size: 1.6rem;
		font-weight: 600;
		padding-left: 10px;
		text-align: left;
		line-height: 2;
		@include media() {
			font-size: 1.4rem;
			min-width: 90px;
		}
	}
	td{
		font-size: 1.4rem;
		text-align: left;
		line-height: 2.14;
		padding: 18px 0;
		@include media() {
			font-size: 1.2rem;
			padding:12px 0;
		}
	}
}

////////////////////////////////////////
//breadcrumb
////////////////////////////////////////
.breadcrumb{
	position: relative;
	z-index: 2;
	padding-top:14px;

	@include media(){
		display: none;
	}
	&__list{
		overflow-x: auto;
		white-space: nowrap;
		@include media(){
			flex-wrap: nowrap;
		}
	}
	&__item{
		&:last-of-type{
			.breadcrumb__link{
				&:after{
					display: none;
				}
			}
		}
	}
	&__link{
		color:#464646;
		font-size: 1.2rem;
		text-decoration: none;
		
		&:after{
			content:">";
			padding:0 1em;
		}
		@include media(){
			font-size: 1.0rem;
		}
	}
	a.breadcrumb__link{
		&:hover{
			text-decoration: underline;
		}
	}
}

////////////////////////////////////////
//pagenation
////////////////////////////////////////
.pagenation{
	margin-top:53px;
	@include media(){
		margin-top:30px;
	}
	&__list{}
	&__item{
		padding:0 8px;
	}
	&__link{
		font-size: 1.6rem;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		width:41px;
		height: 41px;
		background-color: #ffffff;
		color:#212121;
		text-decoration: none;
		@include media(){
			font-size: 1.4rem;
			width:36px;
			height: 36px;
			shape-outside: 36px;
		}
		&_current{
			background-color: #00a199;
			color: #fff;
		}
		&:hover{
			background-color: #00a199;
			color: #fff;
		}
	}
}