@charset "utf-8";

////////////////////
//rule
////////////////////
.rule{
	padding-top: 70px;
    padding-bottom: 100px;
    @include media(){
        padding-top: 0;
        padding-bottom: 30px;
    }
    &__inner{
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
}