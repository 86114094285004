@charset "utf-8";

////////////////////
//project
////////////////////
.project{
	padding-top: 65px;
	padding-bottom: 100px;
	position: relative;
	@include media() {
		padding-bottom: 30px;
		padding-top: 0;
	}
	&__ttl{
		margin-bottom: 50px;
		@include media() {
			margin-bottom: 25px;
		}
	}
}

.rowSection{
	margin-bottom: 30px;
	@include media() {
		margin-bottom: 20px;
	}
	&:last-of-type{
		margin-bottom: 0;
	}
	&:nth-of-type(odd){
		.rowSection__texts{
			margin-left: -110px;
			order:2;
			@include media() {
				margin-left: 0;
			}
		}
		.rowSection__image{
			order:1;
		}
	}
	&:nth-of-type(even){
		.rowSection__texts{
			margin-right: -110px;
			order:1;
			@include media() {
				margin-right: 0;
				order:2;
			}
		}
		.rowSection__image{
			order:2;
			@include media() {
				order: 1;
			}
		}
	}
	&__container{
		display: flex;
		flex-wrap: wrap;
		@include media('tab') {
			justify-content: space-between;
		}
	}
	&__texts{
		width: calc(100% - 450px);
		background-color: #fff;
		padding:43px 50px 45px 50px;
		position: relative;
		margin-top:35px;
		z-index:2;
		@include media('tab') {
			width: 60vw;
			padding: 30px;
		}
		@include media() {
			width: 100%;
			margin-top: 0;
			padding: 20px;
		}
	}

	&__subTtl{
		font-size: 2.2rem;
		font-weight: 600;
		line-height: 1.54;
		margin-bottom: 23px;
		@include media() {
			font-size: 1.8rem;
			margin-bottom: 13px;
		}
		
	}

	&__para{
		font-size: 1.6rem;
		line-height: 1.75;
		@include media() {
			font-size: 1.3rem;
			line-height: 1.6;
		}
	}

	&__image{
		flex: 0 0 560px;
		max-width: 560px;
		@include media('tab') {
			flex: 0 0 40vw;
			max-width: 40vw;
		}
		@include media() {
			flex: 0 0 100%;
			max-width: 100%;
			text-align: center;
		}
	}
}

.iso{
	margin-top: 140px;
	@include media() {
		margin-top: 45px;
	}
	&__ttl{
		margin-bottom: 50px;
		@include media() {
			margin-bottom: 25px;
		}
	}
	&__container{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@include media() {
			flex-wrap: wrap;
		}
	}
	&__texts{
		width: calc(100% - 430px);
		background-color: #fff;
		padding:43px 50px 60px 50px;
		position: relative;
		margin-top:35px;
		z-index:2;
		margin-right: -110px;
		@include media('tab') {
			width: 60vw;
			padding: 30px;
		}
		@include media() {
			width: 100%;
			margin-right: 0;
			margin-top: 0;
			padding: 20px;
			order: 2;
		}
	}

	&__subTtl{
		font-size: 2.2rem;
		font-weight: 600;
		line-height: 1.54;
		margin-bottom: 23px;
		@include media() {
			font-size: 1.8rem;
			margin-bottom: 13px;
		}
	}

	&__para{
		font-size: 1.6rem;
		line-height: 1.75;
		margin-bottom: 2em;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media() {
			font-size: 1.3rem;
			line-height: 1.6;
		}
	}

	&__fig{
		flex: 0 0 560px;
		max-width: 560px;
		@include media('tab') {
			flex: 0 0 40vw;
			max-width: 40vw;
		}
		@include media() {
			flex: 0 0 100%;
			max-width: 100%;
			text-align: center;
			order: 1;
		}
	}
}