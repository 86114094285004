@charset "utf-8";

////////////////////
//company
////////////////////
.companyPage{
	padding-top: 68px;
	padding-bottom: 100px;
	@include media() {
		padding-top: 0;
		padding-bottom: 30px;
	}
	&__ttl{
		margin-bottom: 47px;
		@include media() {
			margin-bottom: 17px;
		}
	}
	&__section{
		margin-bottom: 68px;
		@include media() {
			margin-bottom: 30px;
		}
		&:last-of-type{
			margin-bottom: 0;
			
		}
	}
	&__subTtl{
		font-size: 2.4rem;
		font-weight: 700;
		background: url(../img/bg_bl_pt01.png) repeat center center / 2px 2px;
		line-height: 1;
		padding: 18px;
		@include media() {
			font-size: 1.6rem;
			padding: 10px 15px;
		}
	}
	&__map{
		iframe{
			width: 100%;
			height: 362px;
			@include media() {
				height: 200px;
			}
		}
		
	}
}