@charset "utf-8";

.col-2-lists{
	padding-left: 15px;
	@include media(){
		padding-left: 0;
	}
	&__list{
		display: flex;
		margin-bottom: 4px;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__term{
		font-size: 1.6rem;
		font-weight: 600;
		text-align: center;
		width: 146px;
		background: url(../img/bg_pt_or03.png);
		padding: 34px 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		@include media(){
			width: 90px;
			font-size: 1.3rem;
			padding: 15px 20px;
		}
		span{
			font-size: 1.2rem;
			display: block;
		}
	}
	&__detail{
		background-color: #fff;
		padding: 34px 30px;
		width:calc(100% - 146px);
		font-size: 1.6rem;
		@include media(){
			width: calc(100% - 90px);
			font-size: 1.3rem;
			padding: 15px 20px;
		}
	}
}

.tagList{
	flex-wrap: wrap;
	margin-right: -8px;
	margin-bottom: -8px;
	@include media(){
		margin-right: -5px;
		margin-bottom: -5px;
	}
	&__item{
		font-size: 1.4rem;
		padding:8px 12px;
		background-color: #EFEFEF;
		border-radius: 4px;
		margin-right: 8px;
		margin-bottom: 8px;
		font-weight: 600;
		@include media(){
			font-size: 1.0rem;
			padding:5px 8px;
			margin-right: 5px;
			margin-bottom: 5px;
		}
		&_current{
			background: url(../img/bg_pt_or03.png);
		}
	}
}


.borderList{
	&__list{
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid #d7d7d7;
		padding: 38px 0;
		position: relative;
		align-items: center;
		@include media(){
			padding: 20px 0;
		}
		&:after{
			content: "";
			width: 223px;
			height: 1px;
			background-color: #fad5ba;
			position: absolute;
			left: 0;
			bottom: -1px;
			@include media(){
				width: 100px;
			}
		}
		&:last-of-type{
			border:none;
			&:after{
				display: none;
			}
		}
	}
	&__term{
		width: 230px;
		flex-basis: 230px;
		max-width: 230px;
		font-size: 1.6rem;
		font-weight: 600;
		@include media(){
			width: 100%;
			flex-basis: 100%;
			max-width: 100%;
			font-size: 1.4rem;
			margin-bottom: 9px;
			
		}
	}
	&__detail{
		width: calc(100% - 230px);
		flex-basis: calc(100% - 230px);
		max-width: calc(100% - 230px);
		@include media(){
			width: 100%;
			flex-basis: 100%;
			max-width: 100%;
		}
	}
	&__para{
		font-size: 1.6rem;
		@include media(){
			font-size: 1.2rem;
		}
	}
}

.karteDetail{
	padding-top: 65px;
	padding-bottom: 100px;
	@include media(){
		padding-top: 0;
		padding-bottom: 30px;
	}
}


////////////////////
//karte
////////////////////
.karte{
	overflow: hidden;
	padding-top:70px;
	padding-bottom: 70px;
	@include media(){
		padding-top:28px;
		padding-bottom: 30px;
	}
	&__wrap{
		position: relative;
	}
	&__details{
		width:284px;
		background-color: #ffffff;
		padding:30px 40px 40px 40px;
		position: absolute;
		right:0;
		top:38px;
		box-shadow:0px 0px 5px 0px rgba(0,0,0,.2);
		z-index: 3;

		@include media('tab'){
			right: 30px;
		}

		@include media(){
			position: static;
			width:100%;
			box-shadow: none;
			padding:0;
			background-color: transparent;
		}
	}
	&__ttl{
		margin-bottom: 45px;
		@include media(){
			margin-bottom: 20px;
		}
	}
	&__ttl-en{
		display: block;
	}
	&__ttl-jp{
		display: block;
	}
	&__para{
		margin-bottom: 22px;
	}
	&__bt{
		background-color: #FFE106;
		color: #212121;
		font-weight: 700;
		display: block;
		padding:15px 0;
		text-align: center;
		text-decoration: none;
		position: relative;
		&:after{
			content:"";
			width:4.8px;
			height: 8px;
			background: url(../img/arrow_icon_bl.svg) no-repeat center center / cover;
			position: absolute;
			right:15px;
			top:calc(50% - 4px);
		}
		&:hover{
			opacity: .75;
		}
		@include media(){
			display: none;
			
		}
		&-sp{
			margin: 20px auto 0 auto;
			display: none;
			@include media(){
				display: block;
			}
		}
	}
	&__list-inner{
		@include media(){
			display: flex;
			justify-content: center;
			padding: 0 15px;
		}
	}
	&__link{
		display: block;
		text-decoration: none;
	}
	&__list{
		margin:0 -5px;
		@include media('tab'){
			flex-wrap: nowrap;
		}
		@include media(){
			flex-wrap: wrap;
			flex: 0 0 50%;
			max-width: 50%;
		}
		&:first-of-type{
			margin-bottom: 10px;
			margin-left: -90px;
			@include media(){
				padding:0;
				margin: -5px 0;
			}
		}
		&:last-of-type{
			margin-left: 12px;
			@include media(){
				margin:0;
				margin: -5px 0;
			}
		}
		&.internal{
			flex-wrap: wrap;
			margin-left: -17.5px;
			margin-right: -17.5px;
			margin-bottom: -56px;

			@include media(){
				flex-basis: auto;
				max-width: fit-content;
				margin-right: -5px;
				margin-left: -5px;
				margin-bottom: -21px;
			}
			.karte__item{
				flex: 0 0 25%;
				max-width: 25%;
				padding-left: 17.5px;
				padding-right: 17.5px;
				margin-bottom: 56px;

				@include media(){
					margin-bottom: 21px;
				}
				&:nth-of-type(n+4){
					display: block;
				}
				@include media('tab'){
					flex: 0 0 33.333%;
					max-width: 33.333%;
				}
				@include media(){
					flex: 0 0 50%;
					max-width: 50%;
					padding-right: 5px;
					padding-left: 5px;
				}
				&-inner{
					height: 234px;
					margin-bottom: 21px;

					@include media(){
						height: 135px;
						margin-bottom: 10px;
					}
				}
				&-detail{
					align-content: center;
				}
			}
			.karte__maker{
				margin-bottom: 7px;
			}
		}
	}
	&__item{
		flex: 0 0 204px;
		max-width: 204px;
		padding:0 5px;

		@include media(){
			flex: 0 0 100%;
			max-width: 100%;
			padding:5px;

			&:nth-of-type(n+4){
				display: none;
			}
		}
	}
	&__itemInner{
		background-color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		// padding:50px 20px;
		margin-bottom: 17px;
		@include media(){
			// min-height: 162.5px;
			// height: 162.5px;
			margin-bottom: 9px;
		}

		&:hover{
			cursor: pointer;
			&:before{
				content:"";
				display: block;
				width:100%;
				height: 100%;
				background-color: rgba(20,20,20,.75);
				position: absolute;
				top:0;
				left: 0;
			}
			.karte__itemDetail{
				position: absolute;
				left: 0;
				top:0;
				z-index: 2;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
		}
	}
	&__itemThumb{
		@include media(){
			// flex: 0 0 70px;
			// max-width: 70px;
		}
		img{
			// max-width: 140px;
			@include media(){
				max-width: 100%;
			}
		}
	}
	&__itemDetail{
		display: none;
		height: 100%;
		padding:45px 20px;
		align-content: center;

		@include media(){
			padding:10px;
		}
	}
	&__name{
		flex: 0 0 100%;
		max-width: 100%;
		border-bottom: 1px solid #919191;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
	&__nameTerm{
		color: #ec6c0e;
		margin-bottom: 5px;
	}
	&__nameDesc{
		color: #ffffff;
		letter-spacing: -.02em;
		font-weight: 700;
	}
	&__maker{
		flex: 0 0 100%;
		max-width: 100%;
	}
	&__maker-term{
		color: #ec6c0e;
		min-width:47px;
	}
	&__maker-desc{
		color: #ffffff;
	}&__name
	&__taste{
		flex: 0 0 100%;
		max-width: 100%;
	}
	&__taste-term{
		color: #ec6c0e;
		min-width:47px;
	}
	&__taste-desc{
		color: #ffffff;
	}
	&__headline{
		font-size: 1.8rem;
		font-weight: 700;
		color:#212121;
		margin-bottom: 7px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		@include media(){
			font-size: 1.2rem;
			margin-bottom: 5px;
		}
	}
	&__detailList{
		font-size: 1.4rem;
		@include media(){
			font-size: 1.0rem;
		}
	}
	&__detailItem{
		color:#212121;
	}
}



////////////////////
//karte-detail-section
////////////////////
.karte-detail-section{
	padding-bottom: 120px;
}

.itemDetails{
	display: flex;
	margin-bottom: 70px;
	align-items: flex-start;
	@include media(){
		margin-bottom: 45px;
		flex-wrap: wrap;
	}
	&__thumb{
		flex: 0 0 506px;
		max-width: 506px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		// height: 452px;
		@include media('tab'){
			flex: 0 0 42%;
			max-width: 42%;
		}
		@include media(){
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 15px;
			height: auto;
			width:100%;
			// padding: 30px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}
	&__image{
		width: 100%;
		padding-top: 88%;
		margin-bottom: -4%;
		position: relative;
		overflow: hidden;
		font-size: 0;
		line-height: 0;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		// height: 100%;
		@include media(){
			// padding:0 80px;
			// max-width:280px;
			// margin-left: auto;
			// margin-right: auto;
			// text-align: center;
		}
		// &:after{
		// 	content: "";
		// 	background: url(../img/img_performance_shadow.png) no-repeat bottom center/100% auto;
		// 	width: 220px;
		// 	height: 32px;
		// 	display: block;
		// 	position: relative;
		// 	margin: 30px auto 0 auto;
		// 	@include media(){
		// 		margin-top: 0;
		// 		margin-right: auto;
		// 		width: 100px;
		// 	}
		// }
		img{
			// margin:auto;
			// max-width:213px;
			// @include media(){
			// 	max-width: 100%;
			// }
		}
	}
	.slick-list{
		width: 100%;
		// height: 100% !important;
		// max-height: 452px;
	}
	.slick-dots{
		flex: 0 0 100%;
		max-width: 100%;
		position: absolute;
		left: 20px;
		bottom: 23px;
		text-align: left;
		@include media(){
			margin-top:10px;
			height: 10px;
		}
		li{
			width: 9px;
			height: 9px;
			&.slick-active{
				button{
					&:before{
						color: #ec6c0e;
						opacity: 1;
					}
				}
			}
			button{
				width: 9px;
				height: 9px;
				padding:0;
				@include media(){
					width:20px;
					height: 10px;
				}
				&:hover{
					&:before{
						color: #ec6c0e;
					}
				}
				&:before{
					width: 9px;
					height: 9px;
					font-size: 9px;
					line-height: 9px;
					text-align: center;
					margin:0;
					padding:0;
					color:#DCDDDD;
					opacity: 1;
					@include media(){
						width:9px;
						height: 9px;
						font-size: 9px;
						line-height: 9px;
						position: static;
					}
				}
			}
		}
	}
}

.karte-content{
	margin-bottom: 95px;
	box-shadow:0px 3px 6px 0px rgba(0,0,0,.08);
	@include media(){
		margin-bottom: 45px;
	}
	&__ttl{
		font-size: 2.8rem;
		font-weight: 700;;
		display: flex;
		justify-content: center;
		align-items: center;
		padding:19.5px 0;
		background: url(../img/bg_pt_or03.png);
		@include media(){
			font-size: 1.8rem;
			padding:13px 0;
		}

		&:before{
			content:"";
			background: url(../img/icon_karte.svg) no-repeat center center / cover;
			width:30px;
			height: 37px;
			margin-right: 13px;
			@include media(){
				margin-right: 8px;
				width:20px;
				height: 25px;
			}
		}
		&--iagnosis{
			&:before{
				background: url(../img/icon_comment.svg) no-repeat center center / cover;
				width:41px;
				height: 37px;
				@include media(){
					margin-right: 8px;
					width:25px;
					height: 28px;
				}
			}
		}
	}
	&__inner{
		background-color: #ffffff;
		padding:0 40px;
		@include media(){
			padding:0 20px 0px 20px;
		}
	}
}

.karte__impression{
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	height: 373px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-bottom: 160px;
	@include media(){
		height: 180px;
		margin-bottom: 140px;
	}
	&:before{
		content:"";
		background: url(../img/karte_impression.png) no-repeat center center / cover;
		width:100vw;
		height: 373px;
		display: block;
		position: absolute;
		z-index: 0;
		left: 0;
		top:0;
		@include media(){
			height: 180px;
		}
	}
	&-inner{
		position: relative;
		z-index: 2;
		.karte-content{
			position: relative;
			top:70px;
			z-index: 2;
			margin-bottom: 0;
			@include media(){
				top:100px;
			}
			&__inner{
				padding:35px 45px;
				font-size: 1.6rem;
				@include media(){
					font-size: 1.2rem;
					padding:20px;
				}
			}
		}
	}
}

////////////////////
//karte__others
////////////////////
.karte__others{
	&Ttl{
		margin-bottom: 35px;
		@include media() {
			margin-bottom: 15px;
		}
	}
	.karte__list{

	}
}
