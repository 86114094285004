@charset "utf-8";
.privacy{
    padding-top: 70px;
    padding-bottom: 100px;
    @include media(){
        padding-top: 0;
        padding-bottom: 30px;
    }
    &__inner{
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
}

.text-body{
    &__section{
        margin-top: 1.5em;
    }
    
    &__para{
        @include media(){
            font-size: 1.2rem;
            line-height: 1.6;
        }
    }
    &__ttl{
        font-weight: 600;
    }
}