@charset "utf-8";


////////////////////
//about
////////////////////
.about{
	position: relative;
	padding-top:60px;
	padding-top:0;
	@include media('tab') {
		overflow: hidden;
	}
	@include media() {
		padding-top: 0;
		padding-bottom: 0;
	}
	&__container{
		padding-top: 70px;
		@include media() {
			padding-top: 0;
		}
	}
	&__message{
		padding-bottom: 105px;
		@include media('sp') {
			flex-wrap: wrap;
			padding-bottom: 30px;
		}
		&Txt{
			flex-basis: 545px;
			width: 545px;
			max-width: 545px;
			display: flex;
			// align-content: flex-start;
			flex-wrap: wrap;
			@include media('tab') {
				width: 55%;
				max-width: 55%;
				flex-basis: 55%;
			}

			@include media('sp') {
				width: 100%;
				max-width: 100%;
				flex-basis: 100%;
				order: 2;
				
			}
		}
		&Para{
			font-size: 1.6rem;
			line-height: 1.625;
			margin-bottom: 1.5em;
			@include media('sp') {
				font-size: 1.2rem;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&Ttl{
			font-size: 3.2rem;
			font-weight: 800;
			margin-bottom: 36px;
			@include media('sp') {
				font-size: 2.2rem;
				margin-bottom: 20px;
			}
		}
		&SubTtl{
			font-size: 2.6rem;
			font-weight: 600;
			margin-bottom: 1em;
			@include media('sp') {
				font-size: 1.8rem;
				margin-bottom: 13px;
			}
		}
		&Fig{
			flex-basis: calc(100% - 545px);
			width: calc(100% - 545px);
			max-width: calc(100% - 545px);
			padding-left: 60px;
			@include media('tab') {
				width: 45%;
				max-width: 45%;
				flex-basis: 45%;
			}
			@include media('sp') {
				width: 100%;
				max-width: 100%;
				flex-basis: 100%;
				padding-left: 0;
				order:1;
				text-align: center;
				margin-bottom: 30px;

				img{
					max-width: 320px;
				}
			}
		}
		&Bt{
			width: 345px;
			flex-basis: 345px;
			max-width: 345px;
			margin-top: 58px;
			@include media('sp') {
				width: 200px;
				flex-basis: 200px;
				max-width: 200px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 25px;
			}
		}
	}
}

.concept{
	padding-top: 93px;
	position: relative;
	z-index: 2;
	padding-bottom: 135px;
	@include media() {
		padding:25px 0 30px 0;
		flex-wrap: wrap;
	}
	&__ttl{
		margin-bottom: 35px;
		@include media() {
			margin-bottom: 15px;
		}
	}
	&__inner{
		position: relative;
	}
	&__contents{
		position: relative;
		width: 100%;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 80px;
		@include media('tab') {
			padding-left: 45px;
			padding-right: 45px;
		}
		@include media() {
			flex-wrap: wrap;
			padding-left: 20px;
			padding-right: 20px;
			margin-bottom: 30px;
		}
		&:first-of-type{
			.concept{
				&__texts{
					@include media('tab') {
						padding:45px 45px 45px 80px;
					}
					@include media() {
						padding: 20px;
					}
				}
			}
		}
		&:last-of-type{
			margin-bottom: 0;
			.concept{
				&__image{
					order:2;
					left: -50px;
					margin-right: -50px;
					@include media() {
						order:1;
					}
				}
				&__texts{
					order:1;
					left:0;
					margin-right: 0;
					padding:43px 100px 53px 80px;
					@include media('tab') {
						padding:40px 80px 45px 45px;
					}
					@include media() {
						order:2;
						padding: 20px;
					}
				}
			}
		}
	}
	&__texts{
		flex-basis: 62.1%;
		max-width: 62.1%;
		background-color: #ffffff;
		padding:43px 80px 53px 100px;
		order:2;
		position: relative;
		top: 35px;
		left: -50px;
		margin-right: -50px;

		@include media('tab') {
			flex-basis: 68%;
			max-width: 68%;
			margin-right: -30px;
		}
		
		@include media() {
			flex-basis: 100%;
			max-width: 100%;
			order:2;
			margin-right: 0;
			padding:20px;
			position: static;
		}
	}
	&__subTtl{
		font-size: 3.2rem;
		font-weight: 800;
		margin-bottom: 28px;
		@include media() {
			font-size: 1.8rem;
			margin-bottom: 13px;
		}
	}
	&__para{
		font-size: 1.6rem;
		line-height: 1.6;
		margin-bottom: 1.5em;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media() {
			font-size: 1.2rem;
		}
	}
	&__contents{
		align-items: flex-start;
	}
	&__image{
		width: 45.25%;
		max-width: 45.25%;
		flex-basis: 45.25%;
		position: relative;
		z-index:2;
		img{
			width: 100%;
		}
		@include media('tab') {
			max-width: 35%;
			flex-basis: 35%;
		}
		@include media() {
			width: 100%;
			flex-basis: 100%;
			max-width: 100%;
			position: static;
			order:1;
			margin-bottom: 0;
			text-align: center;
		}
	}
}