@charset "utf-8";

////////////////////
//sitemap
////////////////////
.sitemap{
	padding-top: 70px;
	padding-bottom: 100px;
	@include media(){
		padding-bottom: 30px;
		padding-top: 0;
	}
	&__ttl{
		font-size: 2.4rem;
		font-weight: 700;
		text-align: center;
		margin-bottom: 43px;
		@include media(){
			font-size: 1.8rem;
			margin-bottom: 25px;
		}
	}
	&__inner{
		border-top: 1px solid #d3d9dd;
	}
	&__list{
		padding:19px 0;
		border-bottom: 1px solid #d3d9dd;
		@include media(){
			padding:10px 0;
		}
		&:last-of-type{
			border:none;
		}
	}
	&__item{
	}
	&__link{
		font-size: 1.6rem;
		color:#212121;
		text-decoration: none;
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		line-height: 2.5;
		&:hover{
			text-decoration: underline;
		}
		@include media(){
			font-size: 1.3rem;
		}
		&:before{
			content:"";
			background: url(../img/icon_bg_arrow.svg) no-repeat center center / cover;
			width:18px;
			height: 18px;
			display: block;
			margin-right: 10px;
			@include media(){
				width:14px;
				height: 14px;
				margin-right: 7px;
			}
		}
	}
	&__child{
		&List{
			padding-left: 28px;
			@include media(){
				padding-left: 21px;
			}
		}
		&Item{}
		&Link{
			color: #212121;
			font-size: 1.6rem;
			text-decoration: none;
			display: inline-flex;
			align-items: center;
			line-height: 2.5;
			&:before{
				content:"";
				width: 5px;
				height: 2px;
				background-color: #212121;
				display: block;
				margin-right: .5em;
			}
			&:hover{
				text-decoration: underline;
				&:before{
					text-decoration: none;
				}
			}
		}
	}
}

