@charset "utf-8";



////////////////////
//newsTop
////////////////////
.newsTop{
	position: relative;
	align-items: center;
	z-index: 2;
	height: 70px;
	overflow: hidden;

	@include media('tab'){
		overflow: visible;
		height: auto;
		align-items: unset;
	}

	@include media(){
		overflow: visible;
		height: auto;
	}

	&__head{
		background-color: #234156;
		color: #ffffff;
		font-size: 2.2rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding:25px;
		line-height: 1;
		@include media(){
			font-size: 1.0rem;
			width: 45px;
			height: auto;
			padding:0 10px;
		}
	}

	&__list{
		width: calc(100% - 257px);
		flex-basis: calc(100% - 257px);
		max-width: calc(100% - 257px);
		background-color: #ffffff;
		@include media('tab'){
			flex-basis: 100%;
		}
		@include media(){
			width: calc(100% - 45px);
			max-width: calc(100% - 45px);
			flex-basis: calc(100% - 45px);
		}
	}
	
	&__inner{
		
	}
	&__link{
		background-color: #ffffff;
		text-decoration: none;
		color: #212121;
		padding:21px 40px;
		flex-wrap: nowrap;
		align-items: center;
		@include media('tab'){
			padding:15px;
		}

		@include media(){
			padding:12px 15px;
			flex-wrap: wrap;
		}
	}
	&__date{
		font-size: 1.4rem;
		margin-right: 20px;
		@include media(){
			font-size: 1.0rem;
			margin-right: 0;
			color: #b3b3b3;
			line-height: 1;
			margin-bottom: 5px;
		}
	}
	&__ttl{
		font-size: 1.6rem;
		@include media(){
			font-size: 1.1rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	&__ctr{
		flex: 0 0 146px;
		max-width: 146px;
		height: 70px;
		min-height: 70px;
		@include media('tab'){
			height: auto;
			min-height: auto;
			display: none;
		}
		@include media(){
			display: none;
		}
		li{
			height: 100%;
			a{
				border-left: 1px solid #ffffff;
				background-color: #898989;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding:0 15px;
			}

			&:last-of-type{
				a{
					background-color: #234156;
				}
			}
		}
	}
	&__prev{
		a{
			width:42px;
			img{
				width:7.2px;
			}
		}
	}
	&__next{
		a{
			width:42px;
			img{
				width:7.2px;
			}
		}
	}
	&__menu{
		a{
			padding: 0 20px;
			width:62px;
			position: relative;
			span{
				background-color: #ffffff;
				height: 1px;
				display: block;
				position: absolute;
				width:18px;
				left: 20px;

				&:nth-of-type(1){
					top:calc(50% - 6.5px);
				}
				&:nth-of-type(2){
					top:calc(50% - 1px);
				}
				&:nth-of-type(3){
					bottom:calc(50% - 6.5px);
				}
			}
		}
	}
}

////////////////////
//oemTop
////////////////////
.oemTop{
	padding-top: 95px;
	padding-bottom: 90px;
	background: url(../img/bg_index_section.png) #f7f8f8 no-repeat center -.75vw / 100% auto;
	@include media(){
		background: url(../img/bg_index_section.png) #f7f8f8 no-repeat center -1.5vw / 100% auto;
		padding-top: 42px;
		padding-bottom: 30px;
	}
	&__ttl{
		@include media(){
			margin-bottom: 6px;
		}
	}

	&__bt{
		margin: 30px auto 37px auto;
		@include media(){
			margin:17px auto 20px auto;
		}
	}
}

////////////////////
//caseTop
////////////////////

.caseTop{
	padding-top: 90px;
	padding-bottom: 100px;
	@include media(){
		padding-top: 26px;
		padding-bottom: 30px;
	}
	
	&__inner{
		justify-content: space-between;
		@include media(){
			flex-wrap: wrap;
		}
	}
	&__head{
		flex-basis: 276px;
		width: 276px;
		max-width: 276px;
		display: flex;
		flex-wrap: wrap;
		align-content: space-between;
		@include media(){
			flex-basis: 100%;
			width: 100%;
			max-width: 100%;
			margin-bottom: 20px;
		}
	}
	&__texts{
		@include media(){
			flex-basis: 100%;
			width: 100%;
			max-width: 100%;
		}
	}
	&__ttl{
		@include media(){
			text-align: center;
		}
	}
	&__intro{
		text-align: left;
		margin-top: 6px;
		@include media(){
			margin-bottom: 17px;
			text-align: center;
		}
	}
	&__bt{
		width: 100%;
		@include media(){
			width: 200px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&List{
		flex-basis: 760px;
		width: 760px;
		max-width: 760px;
		margin-left: -2px;
		margin-right: -2px;
		@include media(){
			margin-left: -1px;
			margin-right: -1px;
		}
		flex-wrap: wrap;
		&__item{
			flex-basis: 33.333%;
			max-width: 33.333%;
			width: 33.333%;
			height: 320px;
			padding: 0 2px;
			@include media(){
				height: auto;
				padding:0 1px;
			}
		}
		&__img{
			width: 154px;
		}
		&__link{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
			@include media(){
				padding: 20px 19px;
				box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
			}
			&:hover{
				box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
			}
		}
	}
}

// .case{
// 	padding-bottom: 75px;
// 	@include media('tab'){
// 		padding-left: 30px;
// 		padding-right: 30px;
// 	}
// 	@include media(){
// 		padding-bottom: 30px;
// 	}
// 	&__texts{
// 		margin-bottom: 42px;
// 		@include media(){
// 			margin-bottom: 17px;
// 			padding:0 0 0 0;
// 		}
// 	}
// 	&__ttl{
// 		margin-right: 25px;
// 		@include media(){
// 			flex: 0 0 100%;
// 			margin-right: 0;
// 			flex-wrap: wrap;
// 		}
// 	}
// 	&__ttl-jp{
// 		margin-right: 20px;
// 		@include media(){
// 			order:2;
// 			display: block;
// 			flex: 0 0 100%;
// 			margin-right: 0;
// 		}
// 	}
// 	&__ttl-en{
// 		@include media(){
// 			display: block;
// 			margin-left: 20px;
// 			order:1;
// 			flex: 0 0 100%;
// 			margin-left: 0;
// 		}
// 	}
// 	&__bt{
// 		margin-left: auto;
// 		@include media('tab'){
// 			display: none;
// 		}
// 	}
// 	&__bt-sp{
// 		display: none;
// 		@include media('tab'){
// 			display: block;
// 			margin-top:18px;
// 			margin-left: auto;
// 			margin-right: auto;
// 		}
// 	}
// 	&__desc{
// 		@include media(){
// 		}
// 	}
// 	&__list{
// 		margin:0 -15px;
// 		justify-content: unset;
// 		align-items: unset;

// 		@include media(){
// 			flex-wrap: nowrap;
// 			justify-content: center;
// 			align-items: unset;
// 		}
// 	}
// 	&__item{
// 		flex: 0 0 33.333%;
// 		max-width: 33.333%;
// 		padding:0 15px;
// 		@include media(){
// 			flex: 0 0 215px;
// 			max-width: 215px;
// 			padding: 0 5px;
// 		}
// 		a{
// 			background-color: #ffffff;
// 			display: block;
// 			text-decoration: none;
// 			box-shadow:0px 0px 3px 0px rgba(0,0,0,.15);
// 			height: 100%;
// 			&:hover{
// 				opacity: .75;
// 			}
// 		}
// 	}
// 	&__thumb{
// 	}
// 	&__itemDetail{
// 		padding: 15px 20px 20px;
// 		@include media(){
// 			padding:5px 10px 12px 10px;
// 		}
// 	}
// 	&__item-ttl{
// 		font-size: 1.6rem;
// 		font-weight: 700;
// 		color: #212121;
// 		margin-bottom: 10px;

// 		@include media(){
// 			font-size: 1.0rem;
// 			margin-bottom: 5px;
// 		}
// 	}
// 	&__item-cat{
// 		font-size: 1.2rem;
// 		padding:5px 10px;
// 		background-color: #EDEDED;
// 		line-height: 1;
// 		display: inline-block;
// 		color:#212121;

// 		@include media(){
// 			font-size: 1.0rem;
// 			padding:3.5px 5px;
// 		}
// 	}
// }



////////////////////
//about
////////////////////
.aboutTop{
	height: 625px;
	background:url(../img/bg_about_top.png) no-repeat top center / cover;
	@include media(){
		background: none;
		height: auto;
	}
	&__inner{
		display: flex;
		height: 100%;
		align-items: center;
		@include media(){
			flex-wrap: wrap;
		}
	}
	&__fig{
		display: none;
		@include media(){
			display: block;
			flex-basis: auto;
			width:  auto;
			max-width: auto;
			margin-left: -20px;
			margin-right: -20px;
		}
	}
	&__text{
		width: 560px;
		max-width: 560px;
		flex-basis: 560px;
		margin-left: auto;
		@include media(){
			display: block;
			flex-basis: auto;
			width:  auto;
			max-width: none;
			margin-left: -20px;
			margin-right: -20px;
			background: url(../img/img_about_top_bg.png) no-repeat center center / cover;
			padding: 30px 20px;
		}
	}
	&__ttl{
		text-align: left;
		margin-bottom: 8px;
		@include media(){
			text-align: center;
			margin-bottom: 3px;
		}
		.sectionTtl__jp{
			color: #fff;
			font-size: 4.0rem;
			@include media(){
				font-size: 2.6rem;
			}
		}
	}
	&__intro{
		color: #fff;
		text-align: left;
		margin-bottom: 20px;
		@include media(){
			text-align: center;
			margin-bottom: 13px;
		}
	}
	&__list{
		flex-wrap: wrap;
		margin: -3px;
		@include media(){
			padding: -4.5px;		
		}
	}
	&__item{
		flex-basis: 50%;
		width: 50%;
		max-width: 50%;
		padding:3px;
		@include media(){
			flex-basis: 100%;
			width: 100%;
			max-width: 100%;
			padding: 4.5px;
		}
	}
	&__link{
		color: #fff;
		text-decoration: none;
		border:1px solid #fff;
		display: block;
		position: relative;
		padding:20px 20px;
		@include media(){
			font-size: 1.2rem;
			line-height: 1;
			padding:15px;
		}
		&:hover{
			background-color: #fff;
			color: #212121;
			&:after{
				background-image:url(../img/arrow_right_thin_bl.svg);
			}
		}
		&:after{
			content: "";
			display: block;
			background: url(../img/arrow_right_thin.svg) no-repeat center center/6px 12px;
			width: 6px;
			height: 12px;
			position: absolute;
			top: calc(50% - 3px);
			right: 12px;
		}
	}
}

////////////////////
//karteTop
////////////////////
.karteTop{
	overflow: hidden;
	padding-top:90px;
	padding-bottom: 90px;
	position: relative;
	@include media(){
		padding-top:28px;
		padding-bottom: 30px;
	}
	&__head{
		width:300px;
		background-color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
		text-align: center;
		border-radius: 20px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
		padding:36px;

		@include media('tab'){
			right: 30px;
		}

		@include media(){
			position: static;
			width:100%;
			box-shadow: none;
			padding:0;
			background-color: transparent;
			transform: none;
		}
	}

	&__ttl{
		&Jp{
			line-height: 1.26;
			font-size: 3.8rem;
			margin-bottom: 11px;
			@include media(){
				font-size: 2.6rem;
				br{
					display: none;
				}
			}
		}
	}

	&__intro{
		line-height: 1.625;
		margin-bottom: 17px;
	}
	
	&__bt{
		background-color: #ec6c0e;
		color: #fff;
		font-weight: 700;
		display: block;
		padding:15px 0;
		text-align: center;
		text-decoration: none;
		position: relative;
		font-size: 1.6rem;
		@include media(){
			display: none;
		}
		&_ly_sp{
			display: none;
			@include media(){
				margin: 20px auto 0 auto;
				display: block;
				width: 200px;
				font-size: 1.2rem;
				line-height: 1;
				padding: 14px 10px;
				
				&:after{
					@include media(){
						background: url(../img/arrow_icon_wh.svg) no-repeat center center/cover;
						width: 6px;
						height: 12px;
						right: 8px;
						top:calc(50% - 6px);
					}
				}
			}
		}
		&:after{
			content:"";
			display: block;
			background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px;
			width: 6px;
			height: 12px;
			position: absolute;
			top: calc(50% - 4px);
			right: 16px;
		}
		&:hover{
			opacity: .75;
		}
	}
	&__inner{
		@include media(){
			display: flex;
			justify-content: center;
			padding: 0 15px;
		}
	}
	&__list{
		margin:0 -5px;
		@include media('tab'){
			flex-wrap: nowrap;
		}
		@include media(){
			flex-wrap: wrap;
		}
		&:first-of-type{
			margin-bottom: 20px;
			@include media(){
				padding-left: 15px;
				padding-right: 15px;
				margin: -5px 0;
			}
		}
		&:last-of-type{
			@include media(){
				margin:0;
				margin: -5px 0;
				display: none;
			}
		}
		&.internal{
			flex-wrap: wrap;
			margin-left: -17.5px;
			margin-right: -17.5px;
			margin-bottom: -56px;

			@include media(){
				flex-basis: auto;
				max-width: fit-content;
				margin-right: -5px;
				margin-left: -5px;
				margin-bottom: -21px;
			}
			.karte__item{
				flex: 0 0 25%;
				max-width: 25%;
				padding-left: 17.5px;
				padding-right: 17.5px;
				margin-bottom: 56px;

				@include media(){
					margin-bottom: 21px;
				}
				&:nth-of-type(n+4){
					display: block;
				}
				@include media('tab'){
					flex: 0 0 33.333%;
					max-width: 33.333%;
				}
				@include media(){
					flex: 0 0 50%;
					max-width: 50%;
					padding-right: 5px;
					padding-left: 5px;
				}
				&-inner{
					height: 234px;
					margin-bottom: 21px;

					@include media(){
						height: 135px;
						margin-bottom: 10px;
					}
				}
				&-detail{
					align-content: center;
				}
			}
			.karte__maker{
				margin-bottom: 7px;
			}
		}
	}
	&__item{
		flex: 0 0 270px !important;
		max-width: 270px;
		height: 250px;
		padding:0 10px;

		@include media(){
			flex: 0 0 50% !important;
			max-width: 50%;
			padding:5px;
			height: auto;
			&:nth-of-type(n+7){
				display: none;
			}
		}
	}
	&__link{
		background-color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: relative;
		border-radius: 4px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
		@include media(){
			min-height: 162.5px;
			height: 162.5px;
		}

		&:hover{
			cursor: pointer;
			&:before{
				content:"";
				display: block;
				width:100%;
				height: 100%;
				background-color: rgba(20,20,20,.75);
				position: absolute;
				top:0;
				left: 0;
				border-radius: 4px;
			}
			.karteTop__detail{
				position: absolute;
				left: 0;
				top:0;
				z-index: 2;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
		}
	}
	&__thumb{
		background: no-repeat center center / cover;
		width: 100%;
		height: 100%;
	}
	&__detail{
		display: none;
		height: 100%;
		padding:45px 35px;
		align-content: center;
		@include media(){
			padding:10px;
		}
	}
	&__name{
		flex: 0 0 100%;
		max-width: 100%;
		border-bottom: 1px solid #ffffff;
		padding-bottom: 10px;
		margin-bottom: 10px;
		&Term{
			color: #ec6c0e;
			font-size: 1.0rem;
			margin-bottom: 2px;
		}
		&Desc{
			color: #ffffff;
			letter-spacing: -.02em;
			font-weight: 700;
			font-size: 1.4rem;
			@include media(){
				font-size: 1.0rem;
			}
		}
	}

	&__maker{
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 4px;
		&Term{
			color: #ec6c0e;
			min-width:38px;
			font-size: 1.0rem;
		}
		&Desc{
			color: #ffffff;
			font-size: 1.2rem;
			@include media(){
				font-size: 1.0rem;
			}
		}
	}
	&__taste{
		flex: 0 0 100%;
		max-width: 100%;
		&Term{
			color: #ec6c0e;
			font-size: 1.0rem;
			min-width:38px;
		}
		&Desc{
			color: #ffffff;
			font-size: 1.2rem;
			@include media(){
				font-size: 1.0rem;
			}
		}
	}
	&__headline{
		font-size: 1.6rem;
		font-weight: 700;
		color:#212121;
		margin-bottom: 9px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		@include media(){
			font-size: 1.0rem;
			margin-bottom: 5px;
		}
	}
	&__detail-list{
		font-size: 1.4rem;
		@include media(){
			font-size: 1.0rem;
		}
	}
	&__detail-item{
		color:#212121;
	}
}


////////////////////
//blogTop
////////////////////
.blogTop{
	position: relative;
	z-index: 2;
	padding:5px 0 100px 0;
	@include media('tab') {
		padding-left: 30px;
		padding-right: 30px;
		flex-wrap: wrap;
	}
	@include media() {
		padding:24px 0 30px 0;
	}
	&__head{
		max-width:276px;
		flex: 0 0 276px;
		display: flex;
		flex-wrap: wrap;
		align-content: space-between;

		@include media('tab') {
			flex: 0 0 100%;
			max-width: 100%;
			background-color: transparent;
			padding:0;
		}
		@include media() {
			padding: 0 20px;
		}
		&Text{
			@include media('tab') {
				width: 100%;
			}
		}
	}
	
	&__ttl{
		margin-bottom: 10px;
		@include media('tab') {
			text-align: center;
		}
		@include media() {
			margin-bottom: 6px;
		}
		&En{
			margin-bottom: 4px;
		}
		&Jp{}
	}
	&__intro{
		font-size: 1.6rem;
		line-height: 1.625;

		@include media('tab') {
			margin-bottom: 30px;
			text-align: center;
		}

		@include media() {
			font-size: 1.2rem;
			margin-bottom: 16px;
		}
	}
	&__bt{
		width:100%;
		@include media('tab') {
			display: none;
		}
		&_dev{
			&_sp{
				display: none;
				@include media('tab') {
					width: 276px;
					display: block;
					margin-top:25px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		
	}
	&__list{
		flex: 0 0 750px;
		max-width: 750px;
		width: 750px;
		margin-left: 52px;

		@include media('tab') {
			flex: 0 0 100%;
			max-width: 100%;
			width: 100%;
			position: static;
			margin-left: 0;
		}

		@include media() {
			padding: 0 20px 0 20px;
		}
	}
	&__item{
		margin-bottom: 10px;
		border-radius: 4px;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media() {
			margin-bottom: 10px;
		}
		
	}
	&__link{
		background-color: #ffffff;
		text-decoration: none;
		color: #212121;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
		border-radius: 0 4px 4px 0;
		@include media() {
		}
		&:hover{
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
		}
		
	}
	&__thumb{
		flex: 0 0 138px;
		max-width: 138px;
		border-radius: 40px 0 0 40px;
		@include media() {
			flex: 0 0 100px;
			max-width: 100px;
		}
		img{
			border-radius: 4px 0 0 4px;
			@include media() {
				height: 82px;
				object-fit: cover;
			}
		}
	}
	&__itemTexts{
		background-color: #fff;
		display: flex;
		align-items: center;
		padding:20px 100px 20px 20px;
		flex-basis: calc(100% - 138px);
		width: calc(100% - 138px);
		max-width: calc(100% - 138px);
		position: relative;
		border-radius: 0 4px 4px 0;
		@include media('tab') {

		}
		@include media() {
			flex-wrap: wrap;
			flex-basis: calc(100% - 100px);
			width:calc(100% - 100px);;
			max-width: calc(100% - 100px);;
			padding:10px 30px 10px 13px;
		}
		&:after{
			content: "";
			display: block;
			background: url(../img/icon_arrow_or.svg) no-repeat center center/cover;
			width: 7px;
			height: 14px;
			position: absolute;
			top: calc(50% - 7px);
			right: 20px;
			@include media() {
				right: 11px;
				width: 6px;
				height: 12px;
				top: calc(50% - 6px);
				background: url(../img/icon_arrow_or.svg) no-repeat center center/6px 12px;
			}
		}
	}
	&__date{
		font-size: 1.4rem;
		display: flex;
		@include media() {
			width: 100%;
		}
		&:after{
			content: "";
			width: 1px;
			height: 21px;
			background-color: #dcdddd;
			display: block;
			margin:0 17px;
			@include media() {
				display: none;
			}
		}

		@include media() {
			font-size: 1.1rem;
			margin-bottom: 5px;
		}
	}
	&__postHead{
		font-size: 1.6rem;
		line-height: 1.625;
		@include media() {
			font-size: 1.2rem;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
	}
	&__cat{
		font-size: 1.2rem;
		background-color: #EDEDED;
		display: inline-block;
		padding:5px 20px;
		@include media() {
			font-size: 1.0rem;
			padding:1px 15px;
		}
	}
}

////////////////////
//news
////////////////////
.newsSecTop{
	padding-top: 90px;
	padding-bottom: 105px;
	@include media('tab') {
		padding-left: 30px;
		padding-right: 30px;
	}
	@include media() {
		padding:25px 20px 30px 20px;
	}
	&__ttl{
		text-align: center;
		margin-bottom: 14px;
		@include media() {
			margin-bottom: 7px;
		}
	}
	&__list{
		margin-bottom: 50px;
		@include media() {
			margin-bottom: 13px;
		}
	}
	&__item{
		border-bottom: 1px solid #f7f8f8;
		&:last-of-type{
			border:none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		@include media() {
		}
	}
	&__link{
		text-decoration: none;
		color:#212121;
		flex-wrap: nowrap;
		padding:20px 0;
		@include media('tab') {
			flex-wrap: nowrap;
		}
		@include media() {
			flex-wrap: wrap;
			padding: 10px 0 7px 0;
		}
		&:hover{
			.newsSecTop__headline{
				text-decoration: underline;
			}
		}
	}
	&__cat{
		font-size: 1.2rem;
		background-color: #EDEDED;
		padding:3px 0;
		text-align: center;
		width:92px;
		flex: 0 0 92px;
		margin-right: 22px;
		@include media('tab') {
		}
		@include media() {
			font-size: 1.0rem;
			width:60px;
			flex: 0 0 60px;
			padding:1px 0;
			margin-right: 10px;
		}
	}
	&__date{
		font-size: 1.2rem;
		margin-right: 22px;
		@include media() {
			font-size: 1.1rem;
		}
	}
	&__headline{
		font-size: 1.6rem;
		@include media('tab') {
			margin-top:10px;
		}
		@include media() {
			font-size: 1.3rem;
			margin-top:6px;
		}
	}
	&__bt{
		width:276px;
		margin-left: auto;
		margin-right: auto;
	}
}