@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/  v2.0 | 20110126 License: none (public domain) */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; font-family: 'Noto Sans JP',Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", meiryo, sans-serif; box-sizing: border-box; line-height: 1.5; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after { content: ''; content: none; }

q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-spacing: 0; }

img { max-width: 100%; vertical-align: bottom; }

html { font-size: 62.5%; }

body { color: #212121; font-size: 1.4rem; }

@media screen and (max-width: 1080px) { body.fix { position: fixed; } }

.bg_color_gr { background-color: #f7f8f8; }

.wrap { width: 100%; max-width: 1080px; margin-left: auto; margin-right: auto; box-sizing: border-box; }

@media screen and (max-width: 1080px) { .wrap { max-width: 100%; padding-left: 30px; padding-right: 30px; } }

@media screen and (max-width: 767px) { .wrap { padding: 0 20px; } }

.flb, .flb_type_t, .flb_type_tl, .flb_type_tc, .flb_type_tr, .flb_type_c, .flb_type_cl, .flb_type_cc, .flb_type_cr, .flb_type_cb, .flb_type_b, .flb_type_bl, .flb_type_bc, .flb_type_br { display: flex; }

.flb_type_t, .flb_type_tl, .flb_type_tc, .flb_type_tr { align-items: flex-start; }

.flb_type_tl { justify-content: flex-start; }

.flb_type_tc { justify-content: center; }

.flb_type_tr { justify-content: flex-end; }

.flb_type_c, .flb_type_cl, .flb_type_cc, .flb_type_cr, .flb_type_cb { align-items: center; }

.flb_type_cl { justify-content: flex-start; }

.flb_type_cc { justify-content: center; }

.flb_type_cr { justify-content: flex-end; }

.flb_type_cb { justify-content: space-between; }

.flb_type_b, .flb_type_bl, .flb_type_bc, .flb_type_br { align-items: flex-end; }

.flb_type_bl { justify-content: flex-start; }

.flb_type_bc { justify-content: center; }

.flb_type_br { justify-content: flex-end; }

.lPage__ttl { font-size: 2.4rem; font-weight: 800; line-height: 1.75; text-align: center; }

.spbr { display: none; }

@media screen and (max-width: 1080px) { .spbr { display: block; } }

@media screen and (max-width: 767px) { .pcbr { display: none; } }

.header { position: relative; z-index: 3; width: 100vw; background-color: #fff; display: flex; flex-wrap: wrap; }

@media screen and (max-width: 1080px) { .header_page_lp .header__desc { flex-wrap: wrap; } }

.header.scrolled { position: fixed; top: 0; left: 0; z-index: 10; }

@media screen and (min-width: 1080px) { .header.scrolled.header_page_lp .header__desc { width: 100%; flex-basis: 100%; max-width: 100%; padding-top: 17px; padding-bottom: 17px; flex-wrap: wrap; }
  .header.scrolled.header_page_lp .header__logo { width: 240px; max-width: 240px; flex-basis: 240px; }
  .header.scrolled.header_page_lp .gnavi__link { border: none; }
  .header.scrolled .header__desc { width: 240px; max-width: 240px; flex-basis: 240px; }
  .header.scrolled .header__logo { width: 100%; flex-basis: 100%; max-width: 100%; }
  .header.scrolled .header__cap { display: none; }
  .header.scrolled .header__topList { display: none; }
  .header.scrolled .gnavi { max-width: 1080px; width: calc(100% - 240px); margin-left: auto; }
  .header.scrolled .gnavi_page_lp { width: auto; max-width: auto; }
  .header.scrolled .gnavi__list { height: 100%; }
  .header.scrolled .gnavi__item_type_contact .gnavi__link { background-color: #ec6c0e; }
  .header.scrolled .gnavi__link { font-size: 1.5rem; letter-spacing: -.05em; height: 100%; background-color: transparent; border-color: #f3f3f3; }
  .header.scrolled .mm__inner { top: 51px; } }

.header__desc { flex: 0 0 100%; max-width: 100%; padding: 24px 25px 24px 25px; display: flex; justify-content: flex-start; align-items: center; }

@media screen and (max-width: 1080px) { .header__desc { flex: 0 0 100%; max-width: 100%; display: flex; justify-content: space-between; align-items: center; padding: 0 0 0 20px; } }

@media screen and (max-width: 767px) { .header__desc { padding: 0 0 0 0; } }

.header__logo { flex: 0 0 325px; max-width: 325px; font-size: 0; line-height: 1; margin-top: -8px; margin-right: 20px; }

.header__logo_page_lp { flex: 0 0 252px; max-width: 252px; }

.header__logo a:hover { opacity: .75; }

@media screen and (max-width: 767px) { .header__logo { flex: 0 0 45vw; width: 45vw; max-width: 230px; padding-left: 10px; } }

.header__cap { font-size: 1.2rem; line-height: 1.6; position: relative; top: 2px; }

@media screen and (max-width: 1170px) { .header__cap { display: none; } }

.header__topList { margin-left: auto; display: flex; align-items: center; }

@media screen and (max-width: 1080px) { .header__topList { display: none; } }

.header__topItem { margin-left: 30px; }

.header__topLink { font-size: 1.2rem; text-decoration: none; color: #212121; display: flex; justify-content: flex-start; align-items: center; }

.header__topLink:hover { opacity: .75; }

.header__topLink:before { content: ""; background: url(../img/icon_bg_arrow.svg) no-repeat center center/cover; width: 14px; height: 14px; display: block; margin-right: 5px; }

.header__lang { display: flex; align-items: center; }

.header__lang-term { font-size: 1.2rem; margin-right: 10px; }

.header__lang-label { position: relative; }

.header__lang-label:after { content: ""; display: block; background: url(../img/icon_arrow_wh_bottom.svg) no-repeat center center/10px 6.7px; width: 10px; height: 6.7px; position: absolute; right: 8px; top: calc(50% - 3.35px); }

.header__lang-select { -moz-appearance: none; -webkit-appearance: none; appearance: none; background: none; border: none; box-shadow: none; background-color: #212121; border-radius: 4px; font-size: 1.4rem; color: #ffffff; width: 110px; padding: 5px 8px 5px 10px; }

.header__lang-select::-ms-expand { display: none; }

@media screen and (max-width: 1170px) { .header.active { overflow: scroll; }
  .header.active .gnavi { display: block; position: static; width: 100vw; height: calc(100vh - 80px); z-index: 3; top: 80px; overflow: scroll; background-color: #fff; } }

@media screen and (max-width: 1170px) and (max-width: 767px) { .header.active .gnavi { height: calc(100vh - 55px); padding-bottom: 70px; } }

@media screen and (max-width: 1170px) { .header.active .gnavi__list { position: relative; z-index: 4; padding-left: 45px; padding-right: 45px; flex-wrap: wrap; height: 100%; overflow: scroll; } }

@media screen and (max-width: 1170px) and (max-width: 767px) { .header.active .gnavi__list { padding-left: 31px; padding-right: 31px; } }

@media screen and (max-width: 1170px) { .header.active .gnavi__item { flex: 0 0 100%; max-width: 100%; margin-bottom: 0; order: 2; }
  .header.active .gnavi__item_type_contact { padding: 30px 0 0 0; margin-bottom: 0; order: 1; } }

@media screen and (max-width: 1170px) and (max-width: 767px) { .header.active .gnavi__item_type_contact { padding: 16px 0 0 0; } }

@media screen and (max-width: 1170px) { .header.active .gnavi__item_type_contact .gnavi__link { background-color: #ec6c0e; text-align: center; display: block; color: #ffffff; border-radius: 30px; position: relative; } }

@media screen and (max-width: 1170px) and (max-width: 767px) { .header.active .gnavi__item_type_contact .gnavi__link { padding: 13px 0; } }

@media screen and (max-width: 1170px) { .header.active .gnavi__item_type_contact .gnavi__link:before { display: none; }
  .header.active .gnavi__item_type_contact .gnavi__link:after { content: ""; display: block; background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 6px); right: 11px; }
  .header.active .gnavi__item--tw { display: none; margin-top: 15px; order: 3; width: 35px; height: 35px; text-align: left; } }

@media screen and (max-width: 1170px) and (max-width: 1080px) { .header.active .gnavi__item--tw { display: flex; } }

@media screen and (max-width: 1170px) { .header.active .gnavi__item--tw img { width: 35px; height: 35px; }
  .header.active .gnavi__item.mm .gnavi__link { justify-content: flex-start; position: relative; }
  .header.active .gnavi__item.mm .mm__nav { flex-wrap: wrap; padding: 0; }
  .header.active .gnavi__item.mm .mm__bgInner { flex-wrap: wrap; padding: 0; }
  .header.active .gnavi__item.mm .mm__inner { position: static; margin-left: 0; margin-right: 0; width: 100%; background: none; }
  .header.active .gnavi__item.mm .mm__desc { display: none; }
  .header.active .gnavi__item.mm .mm__arrowList { order: 2; width: 100%; }
  .header.active .gnavi__item.mm .mm__arrowLink { font-weight: 600; padding: 12px; display: block; border-bottom: 1px solid #f3f3f3; position: relative; padding-left: 1em; line-height: 1.5; font-size: 1.1rem; text-align: left; }
  .header.active .gnavi__item.mm .mm__arrowLink:after { content: ""; background: url(../img/icon_arrow_or.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 6px); right: 15px; opacity: 1; left: auto; }
  .header.active .gnavi__item.mm .mm__arrowLink:before { display: none; }
  .header.active .gnavi__item.mm .mm__btDl { padding: 0; background-color: transparent; }
  .header.active .gnavi__item.mm .mm__btList { flex: 0 0 100%; max-width: 100%; padding: 0; margin: 0; padding: 0; order: 1; }
  .header.active .gnavi__item.mm .mm__btList .mm__btItem { margin-bottom: 0px; flex: 0 0 100%; max-width: 100%; padding: 0; }
  .header.active .gnavi__item.mm .mm__btList .mm__btItem a { font-weight: 600; padding: 12px; display: block; border-bottom: 1px solid #f3f3f3; color: #ec6c0e; position: relative; padding-left: 1em; font-size: 1.1rem; }
  .header.active .gnavi__item.mm .mm__btList .mm__btItem a:after { content: ""; background: url(../img/icon_arrow_or.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 6px); right: 15px; opacity: 1; left: auto; }
  .header.active .gnavi__item.mm .mm__btList .mm__btItem:last-of-type { margin-bottom: 0; }
  .header.active .gnavi__item.mm .mm__btList .mm__btLink { font-size: 1.3rem; font-weight: 700; padding: 15px; }
  .header.active .gnavi__item.mm .mm__btTerm { display: none; }
  .header.active .gnavi__item.mm .mm__btLink { display: none; }
  .header.active .gnavi__item.mm .mm__btDetail { flex-basis: 100%; width: 100%; max-width: 100%; padding: 0; }
  .header.active .gnavi__item.mm .mm__btname { font-size: 1.1rem; margin-bottom: 0; }
  .header.active .gnavi__link { background-color: transparent; border: none; border-bottom: 1px solid #f3f3f3; font-size: 1.6rem; padding: 17px 0; display: flex; justify-content: space-between; align-items: center; color: #212121; position: relative; } }

@media screen and (max-width: 1170px) and (max-width: 767px) { .header.active .gnavi__link { font-size: 1.3rem; font-weight: 600; padding: 15px 0; } }

@media screen and (max-width: 1170px) { .header.active .gnavi__link:after { content: ""; background: url(../img/icon_arrow_or.svg) no-repeat center center/cover; width: 7px; height: 14px; position: absolute; top: calc(50% - 7px); right: 0; opacity: 1; left: auto; }
  .header.active .gnavi__link_tri:before { content: ""; width: 2px; height: 12px; right: 5px; top: calc(50% - 6px); position: absolute; background: none; background-color: #ec6c0e; opacity: 1; }
  .header.active .gnavi__link_tri:after { content: ""; width: 12px; height: 2px; background: #ec6c0e; background-image: none; position: absolute; right: 0px; top: calc(50% - 1px); }
  .header.active .gnavi__link_tri.active:before { opacity: 0; }
  .header.active .navBt { background: #00a199; position: relative; z-index: 4; }
  .header.active .navBt span { width: 100%; height: 2px; background: #ffffff; display: block; position: absolute; left: 0; }
  .header.active .navBt span:nth-of-type(1) { top: calc(50% - 1px); transform: rotate(315deg); }
  .header.active .navBt span:nth-of-type(2) { display: none; }
  .header.active .navBt span:nth-of-type(3) { top: calc(50% - 1px); transform: rotate(-315deg); } }

.gnavi { color: #000; width: 100%; }

@media screen and (max-width: 1080px) { .gnavi { border-top: none; display: none; } }

@media screen and (min-width: 1080px) { .gnavi_page_lp { margin-left: auto; width: auto; }
  .gnavi_page_lp .gnavi__list { align-items: center; }
  .gnavi_page_lp .gnavi__item { width: auto; flex-basis: auto; max-width: none; display: flex; }
  .gnavi_page_lp .gnavi__item:before { content: "｜"; padding: 0 1em; display: block; }
  .gnavi_page_lp .gnavi__item:first-of-type:before { display: none; }
  .gnavi_page_lp .gnavi__item_type_sp { display: none; }
  .gnavi_page_lp .gnavi__item_type_sp:before { display: none; }
  .gnavi_page_lp .gnavi__item_type_contact { margin-left: 25px; }
  .gnavi_page_lp .gnavi__item_type_contact:before { display: none; }
  .gnavi_page_lp .gnavi__item_type_contact .gnavi__link { width: 194px; padding: 16px 0; border-radius: 50px; }
  .gnavi_page_lp .gnavi__link { padding: 0; background: none; font-size: 1.4rem; z-index: 5; }
  .gnavi_page_lp .gnavi__link:after { bottom: -10px; }
  .gnavi_page_lp .gnavi__link_tri:before { content: ""; position: absolute; width: 0; height: 0; border-style: solid; border-width: 0 12px 18px 12px; border-color: transparent transparent #00a199 transparent; opacity: 1; display: block; top: 36px; left: calc(50% - 12px); opacity: 0; }
  .gnavi_page_lp .mm:hover .gnavi__link:before, .gnavi_page_lp .mm:hover .gnavi__link:after { opacity: 1; }
  .gnavi_page_lp .mm__inner { top: 60px; padding-top: 32px; background-color: transparent !important; }
  .gnavi_page_lp .mm__contents { background-color: #fff; } }

@media screen and (max-width: 767px) { .gnavi__list { overflow: scroll; height: calc(100vh + -70px); align-content: flex-start; } }

.gnavi__item { flex: 0 0 20%; max-width: 20%; text-align: center; }

.gnavi__item_type_contact .gnavi__link { border-bottom-color: #E30ec6c0e07F; background-color: #ec6c0e; color: #ffffff; display: flex; justify-content: center; }

.gnavi__item_type_contact .gnavi__link:hover { opacity: .75; }

.gnavi__item_type_contact .gnavi__link:hover:after { opacity: 0; }

.gnavi__item_type_sp { display: none; }

@media screen and (max-width: 1080px) { .gnavi__item_type_sp { display: block; } }

.gnavi__link { font-size: 1.6rem; font-weight: 700; color: #212121; background-color: #f3f3f3; text-decoration: none; padding: 26px 0px 25px 0px; display: block; border-left: 1px solid #fff; letter-spacing: -.03em; position: relative; }

.gnavi__link_page_lp { background: none; font-size: 1.4rem; padding: 0; border-left: none; }

.gnavi__link:after { content: ""; width: 100%; height: 4px; position: absolute; left: 0; bottom: 0; display: block; background-color: #00a199; opacity: 0; }

.gnavi__link:hover:after { opacity: 1; }

@media screen and (max-width: 1080px) { .gnavi .mm .gnavi__link:hover { background-color: #ffffff; } }

@media screen and (min-width: 1080px) { .gnavi .mm:hover .mm__inner { display: block; } }

.gnavi .mm__inner { display: none; position: absolute; width: 100vw; left: 50%; right: 50%; margin-left: -50vw; margin-right: -50vw; background-color: #ffffff; z-index: 3; margin-bottom: -5px; }

.gnavi .mm__desc { background-color: #00a199; padding: 15px 0; }

.gnavi .mm__ttl { font-size: 2.6rem; font-weight: 800; margin-right: 30px; color: #fff; }

.gnavi .mm__para { font-size: 1.4rem; color: #fff; }

.gnavi .mm__nav { padding: 36px 0 42px; justify-content: space-between; flex-wrap: nowrap; background-color: #ffffff; }

.gnavi .mm__arrowItem:last-of-type { margin-bottom: 0; }

.gnavi .mm__arrowLink { font-size: 1.4rem; font-weight: 700; color: #212121; text-decoration: none; line-height: 2.25; display: flex; justify-content: flex-start; align-items: center; }

.gnavi .mm__arrowLink:before { content: ""; background: url("../img/icon_arrow_02.svg") no-repeat center center/cover; width: 14px; height: 14px; display: block; margin-right: 10px; }

.gnavi .mm__arrowLink:hover { text-decoration: underline; }

.gnavi .mm__btList { flex: 0 0 855px; max-width: 855px; margin-left: auto; margin-right: -2px; flex-wrap: wrap; }

.gnavi .mm__btItem { flex: 0 0 285px; max-width: 285px; padding: 0 2px; }

.gnavi .mm__btItem a { color: #212121; text-decoration: none; }

.gnavi .mm__btItem a:hover .mm__btLink { opacity: .75; }

.gnavi .mm__btItem_type_sp { display: none; }

@media screen and (max-width: 1080px) { .gnavi .mm__btItem_type_sp { display: block; } }

@media screen and (max-width: 767px) { .gnavi .mm__btItem_type_dev { display: none; } }

.gnavi .mm__btDl { background-color: #f2f2f5; padding: 27px 17px 27px 10px; }

.gnavi .mm__btTerm { flex-basis: 80px; width: 80px; max-width: 80px; }

.gnavi .mm__btDetail { flex-basis: calc(100% - 80px); width: calc(100% - 80px); max-width: calc(100% - 80px); padding-left: 10px; text-align: left; }

.gnavi .mm__btname { font-size: 1.6rem; font-weight: 600; letter-spacing: -.08em; margin-bottom: 7px; }

.gnavi .mm__btLink { background-color: #fff; display: block; font-size: 1.2rem; font-weight: 600; line-height: 1; text-decoration: none; padding: 10px; display: flex; justify-content: space-between; align-items: center; color: inherit; }

.gnavi .mm__btLink--wh { background-color: #ffffff; }

.gnavi .mm__btLink--dev { background-color: #FFE106; }

.gnavi .mm__btLink:after { content: ""; background: url("../img/arrow_icon_bl.svg") no-repeat center center/cover; width: 4.8px; height: 8px; display: block; }

@media screen and (max-width: 1080px) { .gnavi .mm__btItem_type_dev { display: none !important; } }

.navBt { display: none; background: #ec6c0e; }

@media screen and (max-width: 1080px) { .navBt { display: flex; justify-content: center; align-items: center; flex: 0 0 80px; max-width: 80px; height: 80px; } }

@media screen and (max-width: 767px) { .navBt { flex: 0 0 55px; max-width: 55px; height: 55px; } }

.navBt__inner { position: relative; height: 100%; }

@media screen and (max-width: 1170px) { .navBt__inner { width: 40px; height: 30px; } }

@media screen and (max-width: 767px) { .navBt__inner { width: 20px; height: 15px; } }

.navBt__inner span { width: 100%; height: 2px; background: #ffffff; display: block; position: absolute; left: 0; }

.navBt__inner span:nth-of-type(1) { top: 0; }

.navBt__inner span:nth-of-type(2) { top: calc(50% - 1.5px); }

.navBt__inner span:nth-of-type(3) { bottom: 0; }

.gnavi__item_type_tw { display: none; }

@media screen and (max-width: 1080px) { .gnavi__item_type_tw { display: flex; justify-content: space-between; align-items: center; }
  .gnavi__item_type_tw .header__lang-term { color: #ffffff; } }

@media screen and (max-width: 1080px) and (max-width: 767px) { .gnavi__item_type_tw .header__lang-term { font-size: 1.1rem; } }

@media screen and (max-width: 1080px) { .gnavi__item_type_tw .header__lang-select { color: #ffffff; } }

@media screen and (max-width: 1080px) and (max-width: 767px) { .gnavi__item_type_tw .header__lang-select { width: 115px; font-size: 1.1rem; padding: 9px 8px 9px 10px; } }

.main { display: block; }

@media screen and (max-width: 767px) { .main { width: 100vw; overflow: hidden; } }

.scrolled .main { margin-top: 180px; }

@media screen and (max-width: 767px) { .scrolled .main { margin-top: 55px; } }

.mv { position: relative; z-index: 2; overflow: hidden; }

.mv__slider { width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 1; }

.mv__slide { background: no-repeat center center / cover; width: 100%; height: 100%; }

.mv .slick-list { height: 100%; }

.mv .slick-track { height: 100%; }

.mv_page_top { height: 730px; }

@media screen and (max-width: 1080px) { .mv_page_top { height: 70vw; } }

@media screen and (max-width: 767px) { .mv_page_top { height: 400px; } }

.mv_page_top:before { content: ""; display: block; width: 161px; height: 302px; background: url(../img/img_mv_icon01.svg) no-repeat top center/cover; position: absolute; z-index: 2; left: 40px; top: -20px; }

@media screen and (max-width: 767px) { .mv_page_top:before { display: none; } }

.mv_page_top:after { content: ""; display: block; width: 167px; height: 380px; background: url(../img/img_mv_icon02.svg) no-repeat top center/cover; position: absolute; z-index: 2; right: 45px; bottom: -21px; }

@media screen and (max-width: 767px) { .mv_page_top:after { display: none; } }

.mv_page_top .mv__inner { z-index: 2; position: relative; justify-content: flex-start; }

.mv_page_top .mv__catch { width: 228px; position: relative; margin-left: -50px; margin-top: -78px; }

@media screen and (max-width: 767px) { .mv_page_top .mv__catch { position: static; width: 41.4%; margin-left: -20px; margin-bottom: 10px; margin-top: 0; } }

.mv_page_top .mv__ttl { width: 100%; height: auto; margin-left: -20px; margin-bottom: 30px; }

@media screen and (max-width: 767px) { .mv_page_top .mv__ttl { margin-left: -10px; margin-bottom: 20px; } }

.mv_page_top .mv__desc { font-size: 1.6rem; color: #fff; font-weight: 600; line-height: 1.75; position: relative; }

@media screen and (max-width: 767px) { .mv_page_top .mv__desc { font-size: 1.1rem; line-height: 1.63; }
  .mv_page_top .mv__desc br { display: none; } }

.mv_page_lp { height: 260px; }

.mv_page_lp:before { content: ""; background: url(../img/bg_pt.png) repeat top center/auto; width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 1; }

@media screen and (max-width: 1080px) { .mv_page_lp { height: 160px; } }

@media screen and (max-width: 767px) { .mv_page_lp { margin-bottom: 30px; } }

.mv_page_ot { position: relative; background: url(../img/bg_mv02.png) center center/cover; }

.mv_page_news { background: url(../img/bg_mv_news.png) no-repeat center center/cover; }

.mv_page_blog { background: url(../img/blog_mv.png) no-repeat center center/cover; }

.mv_page_about { background: url(../img/bg_mv_about.png) no-repeat center center/cover; }

.mv_page_sitemap { background: url(../img/bg_mv_sitemap.png) no-repeat center center/cover; }

.mv_page_sugarfree { background: url(../img/bg_mv_sugarfree.png) no-repeat center center/cover; }

.mv_page_center { background: url(../img/bg_mv_center.png) no-repeat center center/cover; }

.mv_page_process { background: url(../img/bg_mv_process.png) no-repeat center center/cover; }

.mv_page_project { background: url(../img/bg_mv_project.png) no-repeat center center/cover; }

.mv_page_oem { background: url(../img/bg_mv_oem.png) no-repeat center center/cover; }

.mv_page_performance { background: url(../img/bg_mv_performance.png) no-repeat center center/cover; }

.mv_page_karte { background: url(../img/bg_mv_karte.png) no-repeat center center/cover; }

.mv_page_karteDetail { background: no-repeat center center /  cover; background-size: 230% auto; }

.mv_page_karteDetail01 { background: url(../img/img_case_02.png) no-repeat center center/cover; }

.mv_page_company { background: url(../img/bg_mv_company.png) no-repeat center center/cover; }

.mv__filter { background: url(../img/bg_pt.png) repeat top center/auto; width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 1; }

.mv__error { background: url(../img/experiment_mv.png) no-repeat center center/cover; }

.mv__sitemap { background: url(../img/experiment_mv.png) no-repeat center center/cover; }

.mv__company { background: url(../img/company_mv.png) no-repeat center center/cover; }

.mv__process { background: url(../img/process_mv.png) no-repeat center center/cover; }

.mv__production { background: url(../img/production_mv.png) no-repeat center center/cover; }

.mv__project { background: url(../img/project_mv.png) no-repeat center center/cover; }

.mv__oem { background: url(../img/news_mv.png) no-repeat center center/cover; }

.mv__possibility { background: url(../img/possibility_mv.png) no-repeat center center/cover; }

.mv__flow { background: url(../img/flow_mv.png) no-repeat center center/cover; }

.mv__develop { background: url(../img/technology_mv.png) no-repeat center center/cover; }

.mv__full-order { background: url(../img/full-order_mv.png) no-repeat center center/cover; }

.mv__semi-order { background: url(../img/semi-order_mv.png) no-repeat center center/cover; }

.mv__contact { background: url(../img/contact_mv.png) no-repeat center center/cover; }

.mv__whatsCandy { background: url(../img/whats-candy_mv.png) no-repeat center center/cover; }

.mv__hardCandy { background: url(../img/hard-candy_mv.png) no-repeat center center/cover; }

.mv__schedule { background: url(../img/schedule_mv.png) no-repeat center center/cover; }

.mv__inner { width: 580px; margin-left: auto; margin-right: auto; height: 100%; flex-wrap: wrap; justify-content: center; align-content: center; align-items: center; }

@media screen and (max-width: 1080px) { .mv__inner { padding-left: 40px; padding-right: 40px; } }

.mv__img { position: relative; top: -20px; }

@media screen and (max-width: 1080px) { .mv__img { flex: 0 0 30%; max-width: 30%; } }

.mv__img m { top: -11px; flex: 0 0 45%; max-width: 45%; right: 39px; }

.mv__ttl { text-align: center; position: relative; z-index: 2; font-size: 3.6rem; font-weight: 600; line-height: 1; color: #ffffff; }

@media screen and (max-width: 767px) { .mv__ttl { font-size: 2.1rem; line-height: 1.5; } }

.productList { width: 100%; justify-content: space-between; flex-wrap: wrap; }

.productList__item { flex-basis: calc(33.333% - 11px); max-width: calc(33.333% - 11px); width: calc(33.333% - 11px); display: flex; flex-direction: column; box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15); }

@media screen and (max-width: 767px) { .productList__item { flex-basis: 100%; max-width: 100%; width: 100%; margin-bottom: 12px; }
  .productList__item:last-of-type { margin-bottom: 0; } }

.productList__inner { background-color: #fff; padding: 16px 16px 0 16px; height: 100%; }

@media screen and (max-width: 767px) { .productList__inner { padding: 15px 15px 0 15px; } }

.productList__fig { margin-bottom: 16px; }

@media screen and (max-width: 767px) { .productList__fig { margin-bottom: 13px; } }

.productList__fig a:hover { opacity: .75; }

@media screen and (max-width: 767px) { .productList__fig img { width: 100%; height: 31.25vw; object-fit: cover; } }

.productList__name { font-size: 2.0rem; font-weight: 600; text-align: center; margin-bottom: 8px; }

@media screen and (max-width: 767px) { .productList__name { font-size: 1.6rem; margin-bottom: 10px; } }

.productList__desc { font-size: 1.4rem; }

@media screen and (max-width: 767px) { .productList__desc { font-size: 1.1rem; line-height: 1.63; } }

.productList__btInner { background-color: #fff; padding: 16px; }

@media screen and (max-width: 767px) { .productList__btInner { padding: 13px 15px 15px 15px; } }

.productList__bt { display: block; text-align: center; background-color: #212121; color: #fff; font-size: 1.4rem; text-decoration: none; padding: 16.5px 0; position: relative; }

@media screen and (max-width: 767px) { .productList__bt { font-size: 1.1rem; padding: 14px 0; } }

.productList__bt:hover { opacity: .75; }

.productList__bt:after { content: ""; display: block; background: url(../img/arrow_right_thin.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 6px); right: 11px; }

@media screen and (max-width: 767px) { .productList__bt:after { background: url(../img/arrow_right_thin.svg) no-repeat center center/5px 10px; width: 5px; height: 10px; top: calc(50% - 5px); right: 8px; } }

.articleDetails { max-width: 850px; padding-bottom: 55px; }

@media screen and (max-width: 767px) { .articleDetails { padding-bottom: 45px; } }

.articleDetails__header { margin-bottom: 45px; }

@media screen and (max-width: 767px) { .articleDetails__header { margin-bottom: 30px; } }

.articleDetails__list { margin-bottom: 15px; }

.articleDetails__date { font-size: 1.4rem; margin-left: 1em; }

@media screen and (max-width: 767px) { .articleDetails__date { order: 2; font-size: 1.1rem; } }

.articleDetails__cat { font-size: 1.2rem; background-color: #f3a56c; color: #fff; padding: 0 14px; height: 24px; border-radius: 24px; display: flex; justify-content: center; align-items: center; }

.articleDetails__cat_planning { background-color: #76ccc8; }

.articleDetails__cat_study { background-color: #8e9ea9; }

@media screen and (max-width: 767px) { .articleDetails__cat { font-size: 1.0rem; padding: 1px 15px; height: 20px; } }

.articleDetails__cat_type_blog { font-size: 1.2rem; background-color: #fff; padding: 3px 0; text-align: center; width: 92px; flex: 0 0 92px; margin-right: 22px; border-radius: 0; color: #212121; margin-right: 0; }

@media screen and (max-width: 767px) { .articleDetails__cat_type_blog { order: 1; font-size: 1.0rem; width: 60px; flex: 0 0 60px; padding: 1px 0; } }

.articleDetails__ttl { font-size: 3.0rem; line-height: 1.3; font-weight: 800; margin-bottom: 25px; }

@media screen and (max-width: 767px) { .articleDetails__ttl { font-size: 2.1rem; margin-bottom: 30px; } }

.articleDetails__eyecatch { position: relative; z-index: 0; margin-bottom: -16px; }

@media screen and (max-width: 767px) { .articleDetails__eyecatch { margin-bottom: 10px; } }

.articleDetails__eyecatch img { width: 100%; }

.articleDetails__body { max-width: 650px; margin-left: auto; margin-right: auto; }

.articleDetails__body p { font-size: 1.6rem; line-height: 1.625; margin: 20px 0; }

@media screen and (max-width: 767px) { .articleDetails__body p { font-size: 1.2rem; margin: 15px 0; } }

.articleDetails__body p img { margin: 27px 0; height: auto; }

@media screen and (max-width: 767px) { .articleDetails__body p img { margin-bottom: 3px; } }

.articleDetails__body h2 { font-size: 2.4rem; font-weight: 800; margin: 30px 0; }

@media screen and (max-width: 767px) { .articleDetails__body h2 { font-size: 1.8rem; margin: 15px 0; } }

.articleDetails__body h3 { font-size: 1.8rem; font-weight: 600; margin: 20px 0; }

@media screen and (max-width: 767px) { .articleDetails__body h3 { font-size: 1.6rem; margin: 15px 0; } }

.articleDetails__body img { height: auto; }

.articleDetails__footer { margin-top: 55px; }

@media screen and (max-width: 767px) { .articleDetails__footer { margin-top: 30px; } }

.contactTop { display: block; padding-top: 90px; padding-bottom: 90px; background: url(../img/bg_contact.png) no-repeat center center/cover; position: relative; }

@media screen and (max-width: 1080px) { .contactTop { padding-left: 30px; padding-right: 30px; } }

@media screen and (max-width: 767px) { .contactTop { position: relative; padding: 33px 20px 37px 20px; }
  .contactTop:before { background: url(../img/bg_pt.png) center center/3px 3px; } }

@media screen and (max-width: 767px) { .contactTop__wrap { padding-left: 0; padding-right: 0; } }

.contactTop__ttl { font-size: 3.2rem; font-weight: 700; text-align: center; color: #ffffff; margin-bottom: 20px; position: relative; z-index: 2; }

@media screen and (max-width: 767px) { .contactTop__ttl { position: relative; z-index: 2; font-size: 1.5rem; margin-bottom: 7px; line-height: 1.66; text-align: left; } }

.contactTop__ttl-st { color: #F9C900; font-size: 4.0rem; }

@media screen and (max-width: 767px) { .contactTop__ttl-st { font-size: 2.4rem; line-height: 1.3; } }

.contactTop__para { font-size: 1.5rem; line-height: 2; text-align: center; color: #ffffff; margin-bottom: 1.5em; position: relative; z-index: 2; }

@media screen and (max-width: 767px) { .contactTop__para { position: relative; z-index: 3; font-size: 1.05rem; text-align: left; line-height: 1.5; } }

.contactTop__para:last-of-type { margin-bottom: 0; }

.contactTop__bt { font-size: 1.6rem; font-weight: 700; color: #ffffff; background-color: #E3007F; display: block; width: 324px; margin-left: auto; margin-right: auto; position: relative; text-decoration: none; text-align: center; line-height: 1; padding: 25px 0; margin-top: 43px; position: relative; z-index: 2; }

.contactTop__bt:after { content: ""; display: block; width: 8px; height: 13.4px; background: url(../img/arrow_icon_wh.svg) no-repeat center center/cover; right: 20px; top: calc(50% - 6.7px); position: absolute; }

.contactTop__bt:hover { opacity: .75; }

@media screen and (max-width: 767px) { .contactTop__bt { position: relative; z-index: 3; font-size: 1.2rem; padding: 18.5px 0; margin-top: 21px; width: 100%; } }

.footer { position: relative; z-index: 2; }

.footer__nav { padding: 65px 0; }

@media screen and (max-width: 1080px) { .footer__nav { padding-top: 0; display: none; } }

.footer__navList { border-right: 1px solid #f1f3f3; }

.footer__navList:nth-of-type(1) { width: 21.7%; flex-basis: 21.7%; max-width: 21.7%; padding-right: 45px; }

.footer__navList:nth-of-type(2) { width: 27.2%; flex-basis: 27.2%; max-width: 27.2%; padding-left: 55px; padding-right: 50px; }

.footer__navList:nth-of-type(3) { width: 22.4%; flex-basis: 22.4%; max-width: 22.4%; padding-left: 55px; padding-right: 50px; }

.footer__navList:nth-of-type(4) { width: 28.7%; flex-basis: 28.7%; max-width: 28.7%; border: none; padding-left: 55px; }

.footer__navItem { line-height: 2.14; }

.footer__navLink { text-decoration: none; color: #212121; font-size: 1.4rem; font-weight: 600; display: flex; align-items: center; letter-spacing: -.04em; line-height: 2.14; }

.footer__navLink:before { content: ""; display: block; background: url(../img/icon_bg_arrow.svg) no-repeat center center/14px 14px; width: 14px; height: 14px; display: block; margin-right: 5px; }

.footer__navLink:hover { text-decoration: underline; }

.footer__childTerm { padding-left: 1em; line-height: 2.14; }

.footer__childTermLink { text-decoration: none; color: #212121; font-size: 1.4rem; font-weight: 600; line-height: 2.14; }

.footer__childTermLink:before { content: "-"; padding-right: .5em; }

.footer__childTermLink:hover { text-decoration: underline; }

.footer__childDetail { padding-left: 2em; line-height: 2.14; }

.footer__childLink { text-decoration: none; color: #212121; font-size: 1.4rem; line-height: 2.14; display: block; }

.footer__childLink:hover { text-decoration: underline; }

.footer__bnList { padding: 17px 0; flex-wrap: wrap; }

@media screen and (max-width: 767px) { .footer__bnList { padding: 24px 40px; } }

.footer__bnItem { padding: 0 6px; }

@media screen and (max-width: 767px) { .footer__bnItem { flex: 0 0 100%; max-width: 100%; padding: 0; margin-bottom: 5px; }
  .footer__bnItem:last-of-type { margin-bottom: 0; } }

.footer__bnItem_type_gummi img { width: 150px; }

@media screen and (max-width: 767px) { .footer__bnItem_type_gummi img { width: 96px; } }

.footer__bnItem_type_nisshin img { width: 162px; }

@media screen and (max-width: 767px) { .footer__bnItem_type_nisshin img { width: 112px; } }

.footer__bnItem_type_dolce img { width: 134px; }

@media screen and (max-width: 767px) { .footer__bnItem_type_dolce img { width: 89px; } }

.footer__bnItem a { display: flex; justify-content: center; align-items: center; width: 190px; background: #ffffff; text-align: center; height: 56px; }

.footer__bnItem a:hover { opacity: .75; }

@media screen and (max-width: 767px) { .footer__bnItem a { width: 100%; height: 45px; } }

@media screen and (max-width: 1080px) { .footer__desc { padding: 30px 0  30px 0; } }

@media screen and (max-width: 767px) { .footer__desc { padding: 20px 0  20px 0; } }

@media screen and (max-width: 1080px) { .footer__desc .wrap { flex-wrap: wrap; justify-content: center; } }

.footer__logo { flex: 0 0 266px; max-width: 266px; margin-right: 20px; position: relative; top: -6px; }

@media screen and (max-width: 767px) { .footer__logo { flex: 0 0 60%; max-width: 60%; margin-right: 0; margin-bottom: 0; position: static; } }

.footer__logo a:hover { opacity: .75; }

.footer__copy { background-color: #ec6c0e; }

.footer__copyText { font-size: 1.2rem; color: #fff; }

@media screen and (max-width: 767px) { .footer__copyText { font-size: .9rem; padding: 10px 0; text-align: center; flex: 0 0 100%; } }

.footer__otherList { padding: 28px 0; }

@media screen and (max-width: 767px) { .footer__otherList { display: none; } }

.footer__otherItem:after { content: "｜"; padding: 0 2em; display: inline-block; color: #fff; }

.footer__otherItem:last-of-type:after { display: none; }

.footer__otherItem a { font-size: 1.2rem; color: #fff; text-decoration: none; }

.fn-list__term { margin-bottom: 5px; }

.fn-list__term a { font-size: 1.6rem; font-weight: 700; text-decoration: none; color: #212121; }

.fn-list__term a:hover { text-decoration: underline; }

.fn-child__item--tw { width: 30px; height: 30px; }

.fn-child__item--tw img { width: 30px; height: 30px; }

.fn-child__item--tw a:hover { opacity: .75; }

.fn-child__link { font-size: 1.4rem; text-decoration: none; color: #212121; line-height: 2.2; }

.fn-child__link:hover { text-decoration: underline; }

.fn-child__item--bd { font-size: 1.6rem; margin-bottom: 12px; }

.fn-child__item--bd:last-of-type { margin-bottom: 0; }

.fn-child__item--bd a { font-size: 1.6rem; color: #212121; font-weight: 700; text-decoration: none; }

.fn-child__item--bd a:hover { text-decoration: underline; }

.company-detail { padding: 42px 0; }

@media screen and (max-width: 1080px) { .company-detail { padding: 15px 0 0 0; text-align: center; } }

.company-detail__term { font-size: 1.4rem; font-weight: 700; margin-bottom: 2px; }

@media screen and (max-width: 767px) { .company-detail__term { font-size: 1.0rem; line-height: 1.6; } }

.company-detail__desc { font-size: 1.4rem; }

@media screen and (max-width: 767px) { .company-detail__desc { font-size: 1.0rem; line-height: 1.6; } }

@media screen and (max-width: 767px) { .company-detail__list { flex-wrap: wrap; justify-content: center; } }

.company-detail__ad:after { content: "/"; display: block; padding: 0 1em; }

@media screen and (max-width: 767px) { .company-detail__ad:after { display: none; } }

.sectionTtl_ly_ct { text-align: center; }

.sectionTtl_ly_lt { text-align: left; }

.sectionTtl__en { font-size: 2.0rem; font-weight: 700; color: #ec6c0e; display: block; line-height: 1; margin-bottom: 2px; font-family: Futura,'Noto Sans JP',Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", meiryo, sans-serif; }

@media screen and (max-width: 767px) { .sectionTtl__en { font-size: 1.5rem; } }

.sectionTtl__jp { font-size: 4.4rem; font-weight: 800; color: #212121; display: block; letter-spacing: -.05em; }

@media screen and (max-width: 767px) { .sectionTtl__jp { font-size: 2.6rem; } }

.sectionTtl__jp_page_l { font-size: 4.2rem; }

@media screen and (max-width: 767px) { .sectionTtl__jp_page_l { font-size: 2.6rem; } }

.bdTtl { font-size: 2.4rem; font-weight: 700; text-align: center; }

@media screen and (max-width: 767px) { .bdTtl { font-size: 1.8rem; } }

.sectionIntro { font-size: 1.6rem; }

@media screen and (max-width: 767px) { .sectionIntro { font-size: 1.2rem; } }

.sectionIntro_ly_ct { text-align: center; }

.sqBt { background-color: #ec6c0e; color: #fff; font-weight: 700; display: block; padding: 20px 0; text-align: center; text-decoration: none; position: relative; font-size: 1.6rem; max-width: 345px; }

@media screen and (max-width: 767px) { .sqBt { font-size: 1.2rem; line-height: 1; padding: 14px 10px; width: 200px; } }

.sqBt:hover { opacity: .75; }

.sqBt:after { content: ""; display: block; background: url(../img/arrow_icon_wh.svg) no-repeat center center/7px 14px; width: 7px; height: 14px; position: absolute; top: calc(50% - 7px); right: 16px; }

@media screen and (max-width: 767px) { .sqBt:after { width: 6px; height: 12px; right: 8px; top: calc(50% - 6px); background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px; } }

.btRd { background-color: #ec6c0e; color: #fff; font-weight: 700; display: block; padding: 20px 0; text-align: center; text-decoration: none; position: relative; font-size: 1.6rem; max-width: 354px; border-radius: 50px; }

.btRd:hover { opacity: .75; }

.btRd:before { content: ""; display: block; width: 25.4px; height: 16.1px; background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px; }

.btRd:after { content: ""; display: block; background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 6px); right: 16px; }

.blogBt { width: 190px; font-size: 1.4rem; color: #fff; text-decoration: none; line-height: 1; padding: 14px; border-radius: 40px; background: url(../img/bg_pt.png); background-color: rgba(255, 255, 255, 0.35); background-blend-mode: lighten; display: block; text-align: center; }

.blogBt:hover { background-image: none; background-color: #c9caca; }

.btContact { background-color: #ec6c0e; color: #fff; font-weight: 700; display: flex; justify-content: center; padding: 26px 0; text-align: center; text-decoration: none; position: relative; font-size: 1.6rem; max-width: 354px; border-radius: 50px; align-items: center; }

@media screen and (max-width: 767px) { .btContact { font-size: 1.2rem; line-height: 1; padding: 17.5px 0; } }

.btContact:hover { opacity: .75; }

.btContact:before { content: ""; display: block; width: 29px; height: 21.75px; background: url(../img/mail_icon.svg) no-repeat center center/cover; margin-right: 10px; }

@media screen and (max-width: 767px) { .btContact:before { width: 20px; height: 15px; position: relative; margin-right: 6px; margin-left: -26px; } }

.btContact:after { content: ""; display: block; background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 6px); right: 25px; }

.bdbTable { width: 100%; }

.bdbTable tr { border-bottom: 1px solid #d3d9dd; }

.bdbTable tr:last-of-type { border-bottom: 0; }

.bdbTable th { width: 160px; min-width: 160px; font-size: 1.6rem; font-weight: 600; padding-left: 10px; text-align: left; line-height: 2; }

@media screen and (max-width: 767px) { .bdbTable th { font-size: 1.4rem; min-width: 90px; } }

.bdbTable td { font-size: 1.4rem; text-align: left; line-height: 2.14; padding: 18px 0; }

@media screen and (max-width: 767px) { .bdbTable td { font-size: 1.2rem; padding: 12px 0; } }

.breadcrumb { position: relative; z-index: 2; padding-top: 14px; }

@media screen and (max-width: 767px) { .breadcrumb { display: none; } }

.breadcrumb__list { overflow-x: auto; white-space: nowrap; }

@media screen and (max-width: 767px) { .breadcrumb__list { flex-wrap: nowrap; } }

.breadcrumb__item:last-of-type .breadcrumb__link:after { display: none; }

.breadcrumb__link { color: #464646; font-size: 1.2rem; text-decoration: none; }

.breadcrumb__link:after { content: ">"; padding: 0 1em; }

@media screen and (max-width: 767px) { .breadcrumb__link { font-size: 1.0rem; } }

.breadcrumb a.breadcrumb__link:hover { text-decoration: underline; }

.pagenation { margin-top: 53px; }

@media screen and (max-width: 767px) { .pagenation { margin-top: 30px; } }

.pagenation__item { padding: 0 8px; }

.pagenation__link { font-size: 1.6rem; font-weight: 700; display: flex; justify-content: center; align-items: center; width: 41px; height: 41px; background-color: #ffffff; color: #212121; text-decoration: none; }

@media screen and (max-width: 767px) { .pagenation__link { font-size: 1.4rem; width: 36px; height: 36px; shape-outside: 36px; } }

.pagenation__link_current { background-color: #00a199; color: #fff; }

.pagenation__link:hover { background-color: #00a199; color: #fff; }

.newsTop { position: relative; align-items: center; z-index: 2; height: 70px; overflow: hidden; }

@media screen and (max-width: 1080px) { .newsTop { overflow: visible; height: auto; align-items: unset; } }

@media screen and (max-width: 767px) { .newsTop { overflow: visible; height: auto; } }

.newsTop__head { background-color: #234156; color: #ffffff; font-size: 2.2rem; height: 100%; display: flex; align-items: center; justify-content: center; padding: 25px; line-height: 1; }

@media screen and (max-width: 767px) { .newsTop__head { font-size: 1.0rem; width: 45px; height: auto; padding: 0 10px; } }

.newsTop__list { width: calc(100% - 257px); flex-basis: calc(100% - 257px); max-width: calc(100% - 257px); background-color: #ffffff; }

@media screen and (max-width: 1080px) { .newsTop__list { flex-basis: 100%; } }

@media screen and (max-width: 767px) { .newsTop__list { width: calc(100% - 45px); max-width: calc(100% - 45px); flex-basis: calc(100% - 45px); } }

.newsTop__link { background-color: #ffffff; text-decoration: none; color: #212121; padding: 21px 40px; flex-wrap: nowrap; align-items: center; }

@media screen and (max-width: 1080px) { .newsTop__link { padding: 15px; } }

@media screen and (max-width: 767px) { .newsTop__link { padding: 12px 15px; flex-wrap: wrap; } }

.newsTop__date { font-size: 1.4rem; margin-right: 20px; }

@media screen and (max-width: 767px) { .newsTop__date { font-size: 1.0rem; margin-right: 0; color: #b3b3b3; line-height: 1; margin-bottom: 5px; } }

.newsTop__ttl { font-size: 1.6rem; }

@media screen and (max-width: 767px) { .newsTop__ttl { font-size: 1.1rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; } }

.newsTop__ctr { flex: 0 0 146px; max-width: 146px; height: 70px; min-height: 70px; }

@media screen and (max-width: 1080px) { .newsTop__ctr { height: auto; min-height: auto; display: none; } }

@media screen and (max-width: 767px) { .newsTop__ctr { display: none; } }

.newsTop__ctr li { height: 100%; }

.newsTop__ctr li a { border-left: 1px solid #ffffff; background-color: #898989; height: 100%; display: flex; align-items: center; justify-content: center; padding: 0 15px; }

.newsTop__ctr li:last-of-type a { background-color: #234156; }

.newsTop__prev a { width: 42px; }

.newsTop__prev a img { width: 7.2px; }

.newsTop__next a { width: 42px; }

.newsTop__next a img { width: 7.2px; }

.newsTop__menu a { padding: 0 20px; width: 62px; position: relative; }

.newsTop__menu a span { background-color: #ffffff; height: 1px; display: block; position: absolute; width: 18px; left: 20px; }

.newsTop__menu a span:nth-of-type(1) { top: calc(50% - 6.5px); }

.newsTop__menu a span:nth-of-type(2) { top: calc(50% - 1px); }

.newsTop__menu a span:nth-of-type(3) { bottom: calc(50% - 6.5px); }

.oemTop { padding-top: 95px; padding-bottom: 90px; background: url(../img/bg_index_section.png) #f7f8f8 no-repeat center -0.75vw/100% auto; }

@media screen and (max-width: 767px) { .oemTop { background: url(../img/bg_index_section.png) #f7f8f8 no-repeat center -1.5vw/100% auto; padding-top: 42px; padding-bottom: 30px; } }

@media screen and (max-width: 767px) { .oemTop__ttl { margin-bottom: 6px; } }

.oemTop__bt { margin: 30px auto 37px auto; }

@media screen and (max-width: 767px) { .oemTop__bt { margin: 17px auto 20px auto; } }

.caseTop { padding-top: 90px; padding-bottom: 100px; }

@media screen and (max-width: 767px) { .caseTop { padding-top: 26px; padding-bottom: 30px; } }

.caseTop__inner { justify-content: space-between; }

@media screen and (max-width: 767px) { .caseTop__inner { flex-wrap: wrap; } }

.caseTop__head { flex-basis: 276px; width: 276px; max-width: 276px; display: flex; flex-wrap: wrap; align-content: space-between; }

@media screen and (max-width: 767px) { .caseTop__head { flex-basis: 100%; width: 100%; max-width: 100%; margin-bottom: 20px; } }

@media screen and (max-width: 767px) { .caseTop__texts { flex-basis: 100%; width: 100%; max-width: 100%; } }

@media screen and (max-width: 767px) { .caseTop__ttl { text-align: center; } }

.caseTop__intro { text-align: left; margin-top: 6px; }

@media screen and (max-width: 767px) { .caseTop__intro { margin-bottom: 17px; text-align: center; } }

.caseTop__bt { width: 100%; }

@media screen and (max-width: 767px) { .caseTop__bt { width: 200px; margin-left: auto; margin-right: auto; } }

.caseTopList { flex-basis: 760px; width: 760px; max-width: 760px; margin-left: -2px; margin-right: -2px; flex-wrap: wrap; }

@media screen and (max-width: 767px) { .caseTopList { margin-left: -1px; margin-right: -1px; } }

.caseTopList__item { flex-basis: 33.333%; max-width: 33.333%; width: 33.333%; height: 320px; padding: 0 2px; }

@media screen and (max-width: 767px) { .caseTopList__item { height: auto; padding: 0 1px; } }

.caseTopList__img { width: 154px; }

.caseTopList__link { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08); }

@media screen and (max-width: 767px) { .caseTopList__link { padding: 20px 19px; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08); } }

.caseTopList__link:hover { box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15); }

.aboutTop { height: 625px; background: url(../img/bg_about_top.png) no-repeat top center/cover; }

@media screen and (max-width: 767px) { .aboutTop { background: none; height: auto; } }

.aboutTop__inner { display: flex; height: 100%; align-items: center; }

@media screen and (max-width: 767px) { .aboutTop__inner { flex-wrap: wrap; } }

.aboutTop__fig { display: none; }

@media screen and (max-width: 767px) { .aboutTop__fig { display: block; flex-basis: auto; width: auto; max-width: auto; margin-left: -20px; margin-right: -20px; } }

.aboutTop__text { width: 560px; max-width: 560px; flex-basis: 560px; margin-left: auto; }

@media screen and (max-width: 767px) { .aboutTop__text { display: block; flex-basis: auto; width: auto; max-width: none; margin-left: -20px; margin-right: -20px; background: url(../img/img_about_top_bg.png) no-repeat center center/cover; padding: 30px 20px; } }

.aboutTop__ttl { text-align: left; margin-bottom: 8px; }

@media screen and (max-width: 767px) { .aboutTop__ttl { text-align: center; margin-bottom: 3px; } }

.aboutTop__ttl .sectionTtl__jp { color: #fff; font-size: 4.0rem; }

@media screen and (max-width: 767px) { .aboutTop__ttl .sectionTtl__jp { font-size: 2.6rem; } }

.aboutTop__intro { color: #fff; text-align: left; margin-bottom: 20px; }

@media screen and (max-width: 767px) { .aboutTop__intro { text-align: center; margin-bottom: 13px; } }

.aboutTop__list { flex-wrap: wrap; margin: -3px; }

@media screen and (max-width: 767px) { .aboutTop__list { padding: -4.5px; } }

.aboutTop__item { flex-basis: 50%; width: 50%; max-width: 50%; padding: 3px; }

@media screen and (max-width: 767px) { .aboutTop__item { flex-basis: 100%; width: 100%; max-width: 100%; padding: 4.5px; } }

.aboutTop__link { color: #fff; text-decoration: none; border: 1px solid #fff; display: block; position: relative; padding: 20px 20px; }

@media screen and (max-width: 767px) { .aboutTop__link { font-size: 1.2rem; line-height: 1; padding: 15px; } }

.aboutTop__link:hover { background-color: #fff; color: #212121; }

.aboutTop__link:hover:after { background-image: url(../img/arrow_right_thin_bl.svg); }

.aboutTop__link:after { content: ""; display: block; background: url(../img/arrow_right_thin.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 3px); right: 12px; }

.karteTop { overflow: hidden; padding-top: 90px; padding-bottom: 90px; position: relative; }

@media screen and (max-width: 767px) { .karteTop { padding-top: 28px; padding-bottom: 30px; } }

.karteTop__head { width: 300px; background-color: #fff; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3; text-align: center; border-radius: 20px; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08); padding: 36px; }

@media screen and (max-width: 1080px) { .karteTop__head { right: 30px; } }

@media screen and (max-width: 767px) { .karteTop__head { position: static; width: 100%; box-shadow: none; padding: 0; background-color: transparent; transform: none; } }

.karteTop__ttlJp { line-height: 1.26; font-size: 3.8rem; margin-bottom: 11px; }

@media screen and (max-width: 767px) { .karteTop__ttlJp { font-size: 2.6rem; }
  .karteTop__ttlJp br { display: none; } }

.karteTop__intro { line-height: 1.625; margin-bottom: 17px; }

.karteTop__bt { background-color: #ec6c0e; color: #fff; font-weight: 700; display: block; padding: 15px 0; text-align: center; text-decoration: none; position: relative; font-size: 1.6rem; }

@media screen and (max-width: 767px) { .karteTop__bt { display: none; } }

.karteTop__bt_ly_sp { display: none; }

@media screen and (max-width: 767px) { .karteTop__bt_ly_sp { margin: 20px auto 0 auto; display: block; width: 200px; font-size: 1.2rem; line-height: 1; padding: 14px 10px; } }

@media screen and (max-width: 767px) and (max-width: 767px) { .karteTop__bt_ly_sp:after { background: url(../img/arrow_icon_wh.svg) no-repeat center center/cover; width: 6px; height: 12px; right: 8px; top: calc(50% - 6px); } }

.karteTop__bt:after { content: ""; display: block; background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px; width: 6px; height: 12px; position: absolute; top: calc(50% - 4px); right: 16px; }

.karteTop__bt:hover { opacity: .75; }

@media screen and (max-width: 767px) { .karteTop__inner { display: flex; justify-content: center; padding: 0 15px; } }

.karteTop__list { margin: 0 -5px; }

@media screen and (max-width: 1080px) { .karteTop__list { flex-wrap: nowrap; } }

@media screen and (max-width: 767px) { .karteTop__list { flex-wrap: wrap; } }

.karteTop__list:first-of-type { margin-bottom: 20px; }

@media screen and (max-width: 767px) { .karteTop__list:first-of-type { padding-left: 15px; padding-right: 15px; margin: -5px 0; } }

@media screen and (max-width: 767px) { .karteTop__list:last-of-type { margin: 0; margin: -5px 0; display: none; } }

.karteTop__list.internal { flex-wrap: wrap; margin-left: -17.5px; margin-right: -17.5px; margin-bottom: -56px; }

@media screen and (max-width: 767px) { .karteTop__list.internal { flex-basis: auto; max-width: fit-content; margin-right: -5px; margin-left: -5px; margin-bottom: -21px; } }

.karteTop__list.internal .karte__item { flex: 0 0 25%; max-width: 25%; padding-left: 17.5px; padding-right: 17.5px; margin-bottom: 56px; }

@media screen and (max-width: 767px) { .karteTop__list.internal .karte__item { margin-bottom: 21px; } }

.karteTop__list.internal .karte__item:nth-of-type(n+4) { display: block; }

@media screen and (max-width: 1080px) { .karteTop__list.internal .karte__item { flex: 0 0 33.333%; max-width: 33.333%; } }

@media screen and (max-width: 767px) { .karteTop__list.internal .karte__item { flex: 0 0 50%; max-width: 50%; padding-right: 5px; padding-left: 5px; } }

.karteTop__list.internal .karte__item-inner { height: 234px; margin-bottom: 21px; }

@media screen and (max-width: 767px) { .karteTop__list.internal .karte__item-inner { height: 135px; margin-bottom: 10px; } }

.karteTop__list.internal .karte__item-detail { align-content: center; }

.karteTop__list.internal .karte__maker { margin-bottom: 7px; }

.karteTop__item { flex: 0 0 270px !important; max-width: 270px; height: 250px; padding: 0 10px; }

@media screen and (max-width: 767px) { .karteTop__item { flex: 0 0 50% !important; max-width: 50%; padding: 5px; height: auto; }
  .karteTop__item:nth-of-type(n+7) { display: none; } }

.karteTop__link { background-color: #ffffff; display: flex; justify-content: center; align-items: center; height: 100%; position: relative; border-radius: 4px; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08); }

@media screen and (max-width: 767px) { .karteTop__link { min-height: 162.5px; height: 162.5px; } }

.karteTop__link:hover { cursor: pointer; }

.karteTop__link:hover:before { content: ""; display: block; width: 100%; height: 100%; background-color: rgba(20, 20, 20, 0.75); position: absolute; top: 0; left: 0; border-radius: 4px; }

.karteTop__link:hover .karteTop__detail { position: absolute; left: 0; top: 0; z-index: 2; display: flex; align-items: center; flex-wrap: wrap; }

.karteTop__thumb { background: no-repeat center center / cover; width: 100%; height: 100%; }

.karteTop__detail { display: none; height: 100%; padding: 45px 35px; align-content: center; }

@media screen and (max-width: 767px) { .karteTop__detail { padding: 10px; } }

.karteTop__name { flex: 0 0 100%; max-width: 100%; border-bottom: 1px solid #ffffff; padding-bottom: 10px; margin-bottom: 10px; }

.karteTop__nameTerm { color: #ec6c0e; font-size: 1.0rem; margin-bottom: 2px; }

.karteTop__nameDesc { color: #ffffff; letter-spacing: -.02em; font-weight: 700; font-size: 1.4rem; }

@media screen and (max-width: 767px) { .karteTop__nameDesc { font-size: 1.0rem; } }

.karteTop__maker { flex: 0 0 100%; max-width: 100%; margin-bottom: 4px; }

.karteTop__makerTerm { color: #ec6c0e; min-width: 38px; font-size: 1.0rem; }

.karteTop__makerDesc { color: #ffffff; font-size: 1.2rem; }

@media screen and (max-width: 767px) { .karteTop__makerDesc { font-size: 1.0rem; } }

.karteTop__taste { flex: 0 0 100%; max-width: 100%; }

.karteTop__tasteTerm { color: #ec6c0e; font-size: 1.0rem; min-width: 38px; }

.karteTop__tasteDesc { color: #ffffff; font-size: 1.2rem; }

@media screen and (max-width: 767px) { .karteTop__tasteDesc { font-size: 1.0rem; } }

.karteTop__headline { font-size: 1.6rem; font-weight: 700; color: #212121; margin-bottom: 9px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

@media screen and (max-width: 767px) { .karteTop__headline { font-size: 1.0rem; margin-bottom: 5px; } }

.karteTop__detail-list { font-size: 1.4rem; }

@media screen and (max-width: 767px) { .karteTop__detail-list { font-size: 1.0rem; } }

.karteTop__detail-item { color: #212121; }

.blogTop { position: relative; z-index: 2; padding: 5px 0 100px 0; }

@media screen and (max-width: 1080px) { .blogTop { padding-left: 30px; padding-right: 30px; flex-wrap: wrap; } }

@media screen and (max-width: 767px) { .blogTop { padding: 24px 0 30px 0; } }

.blogTop__head { max-width: 276px; flex: 0 0 276px; display: flex; flex-wrap: wrap; align-content: space-between; }

@media screen and (max-width: 1080px) { .blogTop__head { flex: 0 0 100%; max-width: 100%; background-color: transparent; padding: 0; } }

@media screen and (max-width: 767px) { .blogTop__head { padding: 0 20px; } }

@media screen and (max-width: 1080px) { .blogTop__headText { width: 100%; } }

.blogTop__ttl { margin-bottom: 10px; }

@media screen and (max-width: 1080px) { .blogTop__ttl { text-align: center; } }

@media screen and (max-width: 767px) { .blogTop__ttl { margin-bottom: 6px; } }

.blogTop__ttlEn { margin-bottom: 4px; }

.blogTop__intro { font-size: 1.6rem; line-height: 1.625; }

@media screen and (max-width: 1080px) { .blogTop__intro { margin-bottom: 30px; text-align: center; } }

@media screen and (max-width: 767px) { .blogTop__intro { font-size: 1.2rem; margin-bottom: 16px; } }

.blogTop__bt { width: 100%; }

@media screen and (max-width: 1080px) { .blogTop__bt { display: none; } }

.blogTop__bt_dev_sp { display: none; }

@media screen and (max-width: 1080px) { .blogTop__bt_dev_sp { width: 276px; display: block; margin-top: 25px; margin-left: auto; margin-right: auto; } }

.blogTop__list { flex: 0 0 750px; max-width: 750px; width: 750px; margin-left: 52px; }

@media screen and (max-width: 1080px) { .blogTop__list { flex: 0 0 100%; max-width: 100%; width: 100%; position: static; margin-left: 0; } }

@media screen and (max-width: 767px) { .blogTop__list { padding: 0 20px 0 20px; } }

.blogTop__item { margin-bottom: 10px; border-radius: 4px; }

.blogTop__item:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 767px) { .blogTop__item { margin-bottom: 10px; } }

.blogTop__link { background-color: #ffffff; text-decoration: none; color: #212121; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08); border-radius: 0 4px 4px 0; }

.blogTop__link:hover { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12); }

.blogTop__thumb { flex: 0 0 138px; max-width: 138px; border-radius: 40px 0 0 40px; }

@media screen and (max-width: 767px) { .blogTop__thumb { flex: 0 0 100px; max-width: 100px; } }

.blogTop__thumb img { border-radius: 4px 0 0 4px; }

@media screen and (max-width: 767px) { .blogTop__thumb img { height: 82px; object-fit: cover; } }

.blogTop__itemTexts { background-color: #fff; display: flex; align-items: center; padding: 20px 100px 20px 20px; flex-basis: calc(100% - 138px); width: calc(100% - 138px); max-width: calc(100% - 138px); position: relative; border-radius: 0 4px 4px 0; }

@media screen and (max-width: 767px) { .blogTop__itemTexts { flex-wrap: wrap; flex-basis: calc(100% - 100px); width: calc(100% - 100px); max-width: calc(100% - 100px); padding: 10px 30px 10px 13px; } }

.blogTop__itemTexts:after { content: ""; display: block; background: url(../img/icon_arrow_or.svg) no-repeat center center/cover; width: 7px; height: 14px; position: absolute; top: calc(50% - 7px); right: 20px; }

@media screen and (max-width: 767px) { .blogTop__itemTexts:after { right: 11px; width: 6px; height: 12px; top: calc(50% - 6px); background: url(../img/icon_arrow_or.svg) no-repeat center center/6px 12px; } }

.blogTop__date { font-size: 1.4rem; display: flex; }

@media screen and (max-width: 767px) { .blogTop__date { width: 100%; } }

.blogTop__date:after { content: ""; width: 1px; height: 21px; background-color: #dcdddd; display: block; margin: 0 17px; }

@media screen and (max-width: 767px) { .blogTop__date:after { display: none; } }

@media screen and (max-width: 767px) { .blogTop__date { font-size: 1.1rem; margin-bottom: 5px; } }

.blogTop__postHead { font-size: 1.6rem; line-height: 1.625; }

@media screen and (max-width: 767px) { .blogTop__postHead { font-size: 1.2rem; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; } }

.blogTop__cat { font-size: 1.2rem; background-color: #EDEDED; display: inline-block; padding: 5px 20px; }

@media screen and (max-width: 767px) { .blogTop__cat { font-size: 1.0rem; padding: 1px 15px; } }

.newsSecTop { padding-top: 90px; padding-bottom: 105px; }

@media screen and (max-width: 1080px) { .newsSecTop { padding-left: 30px; padding-right: 30px; } }

@media screen and (max-width: 767px) { .newsSecTop { padding: 25px 20px 30px 20px; } }

.newsSecTop__ttl { text-align: center; margin-bottom: 14px; }

@media screen and (max-width: 767px) { .newsSecTop__ttl { margin-bottom: 7px; } }

.newsSecTop__list { margin-bottom: 50px; }

@media screen and (max-width: 767px) { .newsSecTop__list { margin-bottom: 13px; } }

.newsSecTop__item { border-bottom: 1px solid #f7f8f8; }

.newsSecTop__item:last-of-type { border: none; margin-bottom: 0; padding-bottom: 0; }

.newsSecTop__link { text-decoration: none; color: #212121; flex-wrap: nowrap; padding: 20px 0; }

@media screen and (max-width: 1080px) { .newsSecTop__link { flex-wrap: nowrap; } }

@media screen and (max-width: 767px) { .newsSecTop__link { flex-wrap: wrap; padding: 10px 0 7px 0; } }

.newsSecTop__link:hover .newsSecTop__headline { text-decoration: underline; }

.newsSecTop__cat { font-size: 1.2rem; background-color: #EDEDED; padding: 3px 0; text-align: center; width: 92px; flex: 0 0 92px; margin-right: 22px; }

@media screen and (max-width: 767px) { .newsSecTop__cat { font-size: 1.0rem; width: 60px; flex: 0 0 60px; padding: 1px 0; margin-right: 10px; } }

.newsSecTop__date { font-size: 1.2rem; margin-right: 22px; }

@media screen and (max-width: 767px) { .newsSecTop__date { font-size: 1.1rem; } }

.newsSecTop__headline { font-size: 1.6rem; }

@media screen and (max-width: 1080px) { .newsSecTop__headline { margin-top: 10px; } }

@media screen and (max-width: 767px) { .newsSecTop__headline { font-size: 1.3rem; margin-top: 6px; } }

.newsSecTop__bt { width: 276px; margin-left: auto; margin-right: auto; }

.companyPage { padding-top: 68px; padding-bottom: 100px; }

@media screen and (max-width: 767px) { .companyPage { padding-top: 0; padding-bottom: 30px; } }

.companyPage__ttl { margin-bottom: 47px; }

@media screen and (max-width: 767px) { .companyPage__ttl { margin-bottom: 17px; } }

.companyPage__section { margin-bottom: 68px; }

@media screen and (max-width: 767px) { .companyPage__section { margin-bottom: 30px; } }

.companyPage__section:last-of-type { margin-bottom: 0; }

.companyPage__subTtl { font-size: 2.4rem; font-weight: 700; background: url(../img/bg_bl_pt01.png) repeat center center/2px 2px; line-height: 1; padding: 18px; }

@media screen and (max-width: 767px) { .companyPage__subTtl { font-size: 1.6rem; padding: 10px 15px; } }

.companyPage__map iframe { width: 100%; height: 362px; }

@media screen and (max-width: 767px) { .companyPage__map iframe { height: 200px; } }

.errorPage { padding-bottom: 105px; }

@media screen and (max-width: 767px) { .errorPage { padding-bottom: 60px; } }

.errorPage__container { padding-top: 70px; }

@media screen and (max-width: 767px) { .errorPage__container { padding-top: 0; } }

.errorPage__ttl { font-size: 3.6rem; font-weight: 600; margin-bottom: 36px; }

@media screen and (max-width: 767px) { .errorPage__ttl { font-size: 2.0rem; margin-bottom: 30px; margin-bottom: 20px; } }

.errorPage__desc { font-size: 1.6rem; text-align: center; line-height: 1.75; margin-bottom: 50px; }

@media screen and (max-width: 767px) { .errorPage__desc { font-size: 1.3rem; margin-bottom: 25px; } }

.errorPage__bt { max-width: 324px; margin-right: auto; margin-left: auto; }

@media screen and (max-width: 767px) { .errorPage__bt { width: 100%; } }

.about { position: relative; padding-top: 60px; padding-top: 0; }

@media screen and (max-width: 1080px) { .about { overflow: hidden; } }

@media screen and (max-width: 767px) { .about { padding-top: 0; padding-bottom: 0; } }

.about__container { padding-top: 70px; }

@media screen and (max-width: 767px) { .about__container { padding-top: 0; } }

.about__message { padding-bottom: 105px; }

@media screen and (max-width: 767px) { .about__message { flex-wrap: wrap; padding-bottom: 30px; } }

.about__messageTxt { flex-basis: 545px; width: 545px; max-width: 545px; display: flex; flex-wrap: wrap; }

@media screen and (max-width: 1080px) { .about__messageTxt { width: 55%; max-width: 55%; flex-basis: 55%; } }

@media screen and (max-width: 767px) { .about__messageTxt { width: 100%; max-width: 100%; flex-basis: 100%; order: 2; } }

.about__messagePara { font-size: 1.6rem; line-height: 1.625; margin-bottom: 1.5em; }

@media screen and (max-width: 767px) { .about__messagePara { font-size: 1.2rem; } }

.about__messagePara:last-of-type { margin-bottom: 0; }

.about__messageTtl { font-size: 3.2rem; font-weight: 800; margin-bottom: 36px; }

@media screen and (max-width: 767px) { .about__messageTtl { font-size: 2.2rem; margin-bottom: 20px; } }

.about__messageSubTtl { font-size: 2.6rem; font-weight: 600; margin-bottom: 1em; }

@media screen and (max-width: 767px) { .about__messageSubTtl { font-size: 1.8rem; margin-bottom: 13px; } }

.about__messageFig { flex-basis: calc(100% - 545px); width: calc(100% - 545px); max-width: calc(100% - 545px); padding-left: 60px; }

@media screen and (max-width: 1080px) { .about__messageFig { width: 45%; max-width: 45%; flex-basis: 45%; } }

@media screen and (max-width: 767px) { .about__messageFig { width: 100%; max-width: 100%; flex-basis: 100%; padding-left: 0; order: 1; text-align: center; margin-bottom: 30px; }
  .about__messageFig img { max-width: 320px; } }

.about__messageBt { width: 345px; flex-basis: 345px; max-width: 345px; margin-top: 58px; }

@media screen and (max-width: 767px) { .about__messageBt { width: 200px; flex-basis: 200px; max-width: 200px; margin-left: auto; margin-right: auto; margin-top: 25px; } }

.concept { padding-top: 93px; position: relative; z-index: 2; padding-bottom: 135px; }

@media screen and (max-width: 767px) { .concept { padding: 25px 0 30px 0; flex-wrap: wrap; } }

.concept__ttl { margin-bottom: 35px; }

@media screen and (max-width: 767px) { .concept__ttl { margin-bottom: 15px; } }

.concept__inner { position: relative; }

.concept__contents { position: relative; width: 100%; max-width: 1200px; margin-left: auto; margin-right: auto; margin-bottom: 80px; }

@media screen and (max-width: 1080px) { .concept__contents { padding-left: 45px; padding-right: 45px; } }

@media screen and (max-width: 767px) { .concept__contents { flex-wrap: wrap; padding-left: 20px; padding-right: 20px; margin-bottom: 30px; } }

@media screen and (max-width: 1080px) { .concept__contents:first-of-type .concept__texts { padding: 45px 45px 45px 80px; } }

@media screen and (max-width: 767px) { .concept__contents:first-of-type .concept__texts { padding: 20px; } }

.concept__contents:last-of-type { margin-bottom: 0; }

.concept__contents:last-of-type .concept__image { order: 2; left: -50px; margin-right: -50px; }

@media screen and (max-width: 767px) { .concept__contents:last-of-type .concept__image { order: 1; } }

.concept__contents:last-of-type .concept__texts { order: 1; left: 0; margin-right: 0; padding: 43px 100px 53px 80px; }

@media screen and (max-width: 1080px) { .concept__contents:last-of-type .concept__texts { padding: 40px 80px 45px 45px; } }

@media screen and (max-width: 767px) { .concept__contents:last-of-type .concept__texts { order: 2; padding: 20px; } }

.concept__texts { flex-basis: 62.1%; max-width: 62.1%; background-color: #ffffff; padding: 43px 80px 53px 100px; order: 2; position: relative; top: 35px; left: -50px; margin-right: -50px; }

@media screen and (max-width: 1080px) { .concept__texts { flex-basis: 68%; max-width: 68%; margin-right: -30px; } }

@media screen and (max-width: 767px) { .concept__texts { flex-basis: 100%; max-width: 100%; order: 2; margin-right: 0; padding: 20px; position: static; } }

.concept__subTtl { font-size: 3.2rem; font-weight: 800; margin-bottom: 28px; }

@media screen and (max-width: 767px) { .concept__subTtl { font-size: 1.8rem; margin-bottom: 13px; } }

.concept__para { font-size: 1.6rem; line-height: 1.6; margin-bottom: 1.5em; }

.concept__para:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 767px) { .concept__para { font-size: 1.2rem; } }

.concept__contents { align-items: flex-start; }

.concept__image { width: 45.25%; max-width: 45.25%; flex-basis: 45.25%; position: relative; z-index: 2; }

.concept__image img { width: 100%; }

@media screen and (max-width: 1080px) { .concept__image { max-width: 35%; flex-basis: 35%; } }

@media screen and (max-width: 767px) { .concept__image { width: 100%; flex-basis: 100%; max-width: 100%; position: static; order: 1; margin-bottom: 0; text-align: center; } }

.blog { padding-bottom: 105px; }

@media screen and (max-width: 767px) { .blog { padding-bottom: 60px; } }

.blog__container { padding-top: 65px; }

@media screen and (max-width: 767px) { .blog__container { padding-top: 15px; } }

.blog__ttl { margin-bottom: 40px; }

@media screen and (max-width: 767px) { .blog__ttl { margin-bottom: 30px; font-size: 1.6rem; } }

.blogList { max-width: 850px; margin-left: auto; margin-right: auto; }

.blogList__item { margin-bottom: 16px; }

@media screen and (max-width: 767px) { .blogList__item { margin-bottom: 10px; } }

.blogList__item:last-of-type { margin-bottom: 0; }

.blogList__link { background-color: #ffffff; text-decoration: none; color: #212121; box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05); align-items: unset; border-radius: 4px; }

.blogList__link:hover { box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3); }

.blogList__thumb { flex: 0 0 247px; max-width: 247px; }

@media screen and (max-width: 767px) { .blogList__thumb { flex: 0 0 100px; max-width: 100px; } }

.blogList__thumb img { border-radius: 4px 0 0 4px; }

@media screen and (max-width: 767px) { .blogList__thumb img { border-radius: 4px 0 0 4px; width: 100%; height: 82px; object-fit: cover; } }

.blogList__texts { padding: 26px 68px 26px 27px; width: 100%; display: flex; flex-wrap: wrap; align-content: flex-start; position: relative; }

.blogList__texts:after { content: ""; display: block; background: url(../img/icon_arrow_or.svg) no-repeat center center/cover; width: 7px; height: 14px; position: absolute; top: calc(50% - 7px); right: 20px; }

@media screen and (max-width: 767px) { .blogList__texts:after { width: 6px; height: 12px; top: calc(50% - 6px); right: 13px; } }

@media screen and (max-width: 1080px) { .blogList__texts { flex-basis: calc(100% - 284px); } }

@media screen and (max-width: 767px) { .blogList__texts { flex-basis: calc(100% - 100px); padding: 10px 30px 10px 13px; } }

.blogList__status { width: 100%; display: flex; align-items: center; padding-bottom: 18px; border-bottom: 1px solid #f7f8f8; margin-bottom: 10px; }

@media screen and (max-width: 767px) { .blogList__status { padding-bottom: 5px; margin-bottom: 0; border-bottom: none; } }

.blogList__date { font-size: 1.4rem; display: block; padding-left: 1em; }

@media screen and (max-width: 767px) { .blogList__date { font-size: 1.1rem; } }

.blogList__head { font-size: 1.6rem; }

@media screen and (max-width: 767px) { .blogList__head { font-size: 1.2rem; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; } }

.blogList__cat { font-size: 1.2rem; background-color: #f3a56c; color: #fff; padding: 0 14px; height: 24px; border-radius: 24px; display: flex; justify-content: center; align-items: center; }

.blogList__cat_planning { background-color: #76ccc8; }

.blogList__cat_study { background-color: #8e9ea9; }

@media screen and (max-width: 767px) { .blogList__cat { font-size: 1.0rem; padding: 1px 15px; height: 20px; } }

.blog__article { background-color: #fff; padding: 47px 50px; margin-top: 70px; }

@media screen and (max-width: 767px) { .blog__article { margin-top: 0; padding: 30px 15px 45px 15px; width: calc(100% - 30px); } }

.blog__bt { margin: 45px auto -10px auto; }

@media screen and (max-width: 767px) { .blog__bt { margin-top: 30px; } }

.blog-section { padding-bottom: 100px; }

@media screen and (max-width: 767px) { .blog-section { padding-bottom: 60px; } }

@keyframes icon01 { 0% { transform: translate(0, 0); }
  50% { transform: translate(0, 10px); }
  100% { transform: translate(0, 0); } }

.whatsCandy__container { padding-top: 70px; }

@media screen and (max-width: 767px) { .whatsCandy__container { padding-top: 38px; } }

.whatsCandy__history { padding-bottom: 105px; }

@media screen and (max-width: 767px) { .whatsCandy__history { padding-bottom: 42px; } }

.whatsCandy__historyInner { background: url(../img/bg_orange.png) repeat; border-radius: 40px; padding: 72px 0; position: relative; }

@media screen and (max-width: 480px) { .whatsCandy__historyInner { padding: 38px 0; } }

.whatsCandy__historyInner:before { animation: icon01 1s infinite; background: url(../img/img_whats-candy03.svg) no-repeat center center/86px 128px; content: ""; display: block; height: 128px; position: absolute; top: 72px; left: 85px; width: 86px; }

@media screen and (max-width: 1080px) { .whatsCandy__historyInner:before { background-size: 72px auto; left: 1%; } }

@media screen and (max-width: 767px) { .whatsCandy__historyInner:before { background-size: 40px auto; top: 20px; right: -10px; top: 0; } }

@media screen and (max-width: 480px) { .whatsCandy__historyInner:before { left: -20px; } }

.whatsCandy__historyInner:after { animation: icon01 1s 0.5s infinite; background: url(../img/img_whats-candy04.svg) no-repeat center center/108px 152px; content: ""; display: block; height: 152px; position: absolute; top: 66px; right: 95px; width: 108px; }

@media screen and (max-width: 1080px) { .whatsCandy__historyInner:after { background-size: 96px auto; right: 1%; } }

@media screen and (max-width: 767px) { .whatsCandy__historyInner:after { background-size: 48px auto; top: -9px; right: -10px; } }

@media screen and (max-width: 480px) { .whatsCandy__historyInner:after { right: -20px; } }

.whatsCandy__historyTxt { flex-wrap: wrap; justify-content: center; text-align: center; }

.whatsCandy__historyPara { font-size: 1.6rem; line-height: 1.625; margin-bottom: 1.5em; }

.whatsCandy__historyPara:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 767px) { .whatsCandy__historyPara { font-size: 1.3rem; padding: 0 15px; } }

@media screen and (max-width: 767px) { .whatsCandy__historyPara br { display: none; } }

.whatsCandy__historyTtl { font-size: 3.6rem; font-weight: 700; margin-bottom: 36px; }

@media screen and (max-width: 767px) { .whatsCandy__historyTtl { font-size: 2rem; } }

.whatsCandy__historyTtl br { display: none; }

@media screen and (max-width: 480px) { .whatsCandy__historyTtl br { display: block; } }

.whatsCandy__historyFig { max-width: 928px; margin: 26px 0 48px; width: 100%; }

@media screen and (max-width: 1080px) { .whatsCandy__historyFig { padding: 0 20px; } }

@media screen and (max-width: 767px) { .whatsCandy__historyFig { margin: 0 0 20px; max-width: 100%; padding: 0 15px; width: 100%; } }

.thing { padding: 105px 0 78px; }

@media screen and (max-width: 767px) { .thing { padding: 62px 0 32px; } }

.thing__inner { position: relative; }

.thing__iconleft:before, .thing__iconleft:after, .thing__iconRight:before, .thing__iconRight:after { content: ""; display: block; position: absolute; }

.thing__iconleft:before { animation: icon01 1s infinite; background: url(../img/img_whats-candy05.png) no-repeat center center/132px 152px; height: 152px; top: -95px; left: 0px; width: 132px; }

@media screen and (max-width: 1080px) { .thing__iconleft:before { background-size: 100px auto; left: 0; } }

@media screen and (max-width: 767px) { .thing__iconleft:before { background-size: 60px auto; left: -40px; } }

.thing__iconleft:after { animation: icon01 1s .5s infinite; background: url(../img/img_whats-candy06.png) no-repeat center center/142px 152px; height: 152px; top: -127px; left: 129px; width: 142px; }

@media screen and (max-width: 1080px) { .thing__iconleft:after { background-size: 100px auto; left: 100px; } }

@media screen and (max-width: 767px) { .thing__iconleft:after { background-size: 60px auto; left: 20px; } }

.thing__iconRight:before { animation: icon01 1s .5s infinite; background: url(../img/img_whats-candy07.png) no-repeat center center/149px 149px; height: 149px; top: -110px; right: 94px; width: 149px; }

@media screen and (max-width: 1080px) { .thing__iconRight:before { background-size: 100px auto; right: 70px; } }

@media screen and (max-width: 767px) { .thing__iconRight:before { background-size: 60px auto; right: 10px; top: -130px; } }

.thing__iconRight:after { animation: icon01 1s 1s infinite; background: url(../img/img_whats-candy08.png) no-repeat center center/149px 149px; height: 149px; top: -52px; right: -52px; width: 149px; }

@media screen and (max-width: 1080px) { .thing__iconRight:after { background-size: 100px auto; right: -20px; } }

@media screen and (max-width: 767px) { .thing__iconRight:after { background-size: 60px auto; right: -40px; top: -80px; } }

.thing__ttl { font-size: 3.4rem; font-weight: 700; margin-bottom: 36px; }

@media screen and (max-width: 767px) { .thing__ttl { font-size: 2rem; margin-bottom: 16px; } }

.thing__ttl_color_or { color: #ec6c0e; }

.thing__para { font-size: 1.6rem; line-height: 1.625; margin-bottom: 1.5em; text-align: center; }

.thing__para:last-of-type { margin-bottom: 32px; }

@media screen and (max-width: 767px) { .thing__para { font-size: 1.3rem; } }

.thing__contents { justify-content: space-between; margin: 0 auto 105px; max-width: 1080px; }

.thing__contents:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 767px) { .thing__contents { flex-wrap: wrap; margin: 0 auto 28px; } }

.thing__list { background: #fff; max-width: 522px; }

@media screen and (max-width: 1080px) { .thing__list { width: calc(50% - 15px); } }

@media screen and (max-width: 767px) { .thing__list { flex-wrap: wrap; max-width: none; width: 100%; } }

.thing__itemTtl { align-items: center; background: url(../img/bg_orange.png) repeat; justify-content: center; min-height: 78px; height: 78px; }

@media screen and (max-width: 767px) { .thing__itemTtl { height: auto; min-height: 42px; } }

.thing__subTtl { font-size: 2.4rem; font-weight: 700; }

@media screen and (max-width: 767px) { .thing__subTtl { font-size: 1.6rem; } }

.thing__itemTxt { background: #fff; padding: 32px 40px; }

@media screen and (max-width: 767px) { .thing__itemTxt { padding: 12px 20px 16px; } }

@media screen and (max-width: 480px) { .thing__itemTxt { padding: 8px 14px 16px; } }

.thing__itemPara { font-size: 1.4rem; line-height: 1.857; margin-bottom: 28px; }

.thing__itemPara:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 767px) { .thing__itemPara { font-size: 1.2rem; margin-bottom: 12px; } }

.thing__ttl_color_bl { color: #00a199; }

.thing__itemTtl_bg_bl { background: url(../img/bg_blue.png) repeat; }

.thing__headList { background: #fff; border-radius: 80px; height: 55px; margin-bottom: 42px; }

@media screen and (max-width: 767px) { .thing__headList { flex-wrap: wrap; height: auto; } }

.thing__headItem { align-items: center; display: flex; font-size: 1.4rem; justify-content: center; position: relative; width: 16.6666%; }

.thing__headItem a { text-decoration: none; color: #212121; }

.thing__headItem a:hover { text-decoration: underline; }

@media screen and (max-width: 767px) { .thing__headItem { font-size: 1.2rem; padding: 10px 0; width: 33.3333%; } }

@media screen and (max-width: 321px) { .thing__headItem { font-size: 1.1rem; } }

.thing__headItem:last-of-type:after { content: none; }

@media screen and (max-width: 767px) { .thing__headItem:nth-of-type(3):after { content: none; } }

.thing__headItem:after { content: ""; background: #dddddd; height: 33px; position: absolute; top: 10px; right: 0; width: 1px; }

@media screen and (max-width: 767px) { .thing__headItem:after { height: 20px; } }

.thing__contents_type_represent .thing__list { display: flex; margin-bottom: 26px; max-width: 100%; }

.thing__contents_type_represent .thing__list:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 1080px) { .thing__contents_type_represent .thing__list { width: 100%; } }

.thing__contents_type_represent .thing__itemTtl { background: url(../img/bg_blue.png) repeat; flex-basis: 223px; height: auto; width: 223px; }

@media screen and (max-width: 767px) { .thing__contents_type_represent .thing__itemTtl { flex-basis: 100%; width: 100%; } }

.thing__contents_type_represent .thing__subTtl { font-size: 2.2rem; }

@media screen and (max-width: 767px) { .thing__contents_type_represent .thing__subTtl { font-size: 1.8rem; } }

.thing__contents_type_represent .thing__itemTxt { max-width: 857px; width: 79%; }

@media screen and (max-width: 767px) { .thing__contents_type_represent .thing__itemTxt { width: 100%; } }

.canDo { position: relative; z-index: 2; }

@media screen and (max-width: 767px) { .canDo { padding: 30px 0 35px 0; } }

.canDo__inner { position: relative; }

.canDo__contents { position: relative; width: 100%; max-width: 1200px; margin-left: auto; margin-right: auto; margin-bottom: 80px; }

@media screen and (max-width: 480px) { .canDo__contents { flex-wrap: wrap; } }

.canDo__contents:last-of-type { margin-bottom: 0; }

.canDo__ttl { font-size: 3.2rem; font-weight: 700; margin-bottom: 28px; }

@media screen and (max-width: 767px) { .canDo__ttl { font-size: 2rem; margin-bottom: 15px; } }

.canDo__para { font-size: 1.6rem; line-height: 1.6; margin-bottom: 1.5em; }

.canDo__para:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 767px) { .canDo__para { font-size: 1.3rem; } }

.canDo__texts { flex-basis: 62.1%; max-width: 62.1%; background-color: #ffffff; padding: 43px 52px 26px 112px; position: relative; left: -50px; margin-right: -50px; }

@media screen and (max-width: 1080px) { .canDo__texts { flex-basis: 68%; left: 0; max-width: 68%; margin-right: -30px; padding: 30px 50px 30px 30px; } }

@media screen and (max-width: 767px) { .canDo__texts { flex-basis: 100%; max-width: 100%; margin-right: 0; padding: 20px; } }

.canDo__contents { align-items: center; }

.canDo__image { width: 47.5%; position: relative; z-index: 2; }

.canDo__image img { width: 100%; }

@media screen and (max-width: 1080px) { .canDo__image { max-width: 42%; } }

@media screen and (max-width: 767px) { .canDo__image { width: 100%; flex-basis: 100%; max-width: 100%; position: static; margin-bottom: 15px; text-align: center; } }

@media screen and (max-width: 480px) { .canDo__image { order: -1; } }

.hardCandy__container { padding: 70px 0 96px; }

@media screen and (max-width: 767px) { .hardCandy__container { padding: 12px 0 38px; } }

.hardCandy__headCopy { font-size: 2.4rem; font-weight: 700; line-height: 1.75; margin-bottom: 32px; text-align: center; }

@media screen and (max-width: 767px) { .hardCandy__headCopy { font-size: 1.6rem; margin-bottom: 14px; } }

.hardCandy__headList { flex-wrap: wrap; margin: 0 auto 42px; max-width: 800px; }

@media screen and (max-width: 767px) { .hardCandy__headList { margin-bottom: 20px; background-color: #fff; padding: 17px 20px; } }

.hardCandy__headItem { margin-bottom: 14px; padding: 0 20px; position: relative; }

@media screen and (max-width: 767px) { .hardCandy__headItem { font-size: 1.2rem; list-style: disc inside; padding: 0; margin: 0; line-height: 2; width: 100%; } }

.hardCandy__headItem:nth-of-type(5):after, .hardCandy__headItem:last-of-type:after { content: none; }

.hardCandy__headItem:after { background: #9fa0a0; content: ""; display: block; position: absolute; top: 7px; right: 0; width: 1px; height: 13px; }

@media screen and (max-width: 767px) { .hardCandy__headItem:after { display: none; } }

.hardCandy__headLink { color: inherit; }

@media screen and (max-width: 767px) { .hardCandy__headLink { text-decoration: none; } }

.hardCandy__headLink:hover { text-decoration: none; }

.lot__ttl, .flow__ttl, .shape__ttl, .package__ttl, .trial__ttl, .testing__ttl, .price__ttl, .material__ttl, .recipe__ttl { background: url(../img/bg_orange.png) repeat; font-size: 2.4rem; font-weight: 700; height: 78px; }

@media screen and (max-width: 767px) { .lot__ttl, .flow__ttl, .shape__ttl, .package__ttl, .trial__ttl, .testing__ttl, .price__ttl, .material__ttl, .recipe__ttl { font-size: 1.6rem; height: auto; padding: 8px 15px; } }

.lot__contents, .flow__contents, .shape__contents, .package__contents, .trial__contents, .testing__contents, .price__contents, .material__contents, .recipe__contents { background: #fff; margin-bottom: 32px; padding: 36px 42px; }

@media screen and (max-width: 767px) { .lot__contents, .flow__contents, .shape__contents, .package__contents, .trial__contents, .testing__contents, .price__contents, .material__contents, .recipe__contents { margin-bottom: 18px; padding: 12px 15px; } }

.lot__desc { font-size: 1.8rem; font-weight: 700; position: relative; text-align: center; width: 50%; }

@media screen and (max-width: 767px) { .lot__desc { font-size: 1.3rem; } }

.lot__desc:first-child:after { background: #dcdddd; content: ""; display: block; position: absolute; top: 0; right: 0; width: 1px; height: 20px; }

.flow__desc { font-size: 1.8rem; font-weight: 700; margin-bottom: 16px; }

@media screen and (max-width: 767px) { .flow__desc { font-size: 1.3rem; margin-bottom: 8px; } }

.flow__list { counter-reset: num; flex-wrap: wrap; margin-bottom: 6px; }

.flow__item { font-weight: 600; align-items: center; background: #f5f5f5; counter-increment: num; display: flex; height: 58px; margin: 0 5px 5px 0; position: relative; max-width: 195px; width: 19.5%; }

@media screen and (max-width: 1080px) { .flow__item { height: auto; padding: 6px 0; width: 19%; } }

@media screen and (max-width: 767px) { .flow__item { font-size: 1.3rem; max-width: none; width: calc(33.3333% - 5px); } }

@media screen and (max-width: 480px) { .flow__item { margin-right: 0; width: 100%; } }

.flow__item:nth-child(5n) { margin-right: 0; }

.flow__item:nth-child(5n):after { content: none; }

@media screen and (max-width: 767px) { .flow__item:nth-child(5n) { margin-right: 5px; }
  .flow__item:nth-child(5n):after { content: ""; } }

@media screen and (max-width: 767px) { .flow__item:nth-child(3n), .flow__item:last-child { margin-right: 0; }
  .flow__item:nth-child(3n):after, .flow__item:last-child:after { content: none; } }

.flow__item:before { align-items: center; background: #f39f62; border-radius: 5px; color: #fff; content: counter(num); flex-shrink: 0; display: flex; justify-content: center; font-size: 1.2rem; height: 30px; margin: 0 10px 0 13px; width: 30px; }

.flow__item:after { background: #dcdddd; content: ""; display: block; position: absolute; height: 2px; top: 50%; right: -5px; width: 5px; }

@media screen and (max-width: 480px) { .flow__item:after { content: none; } }

.flow__notice { display: block; font-size: 1.4rem; margin-bottom: 32px; }

@media screen and (max-width: 767px) { .flow__notice { font-size: 1.2rem; } }

.flow__btn { justify-content: center; margin-bottom: 16px; }

@media screen and (max-width: 767px) { .flow__btn { margin-bottom: 8px; } }

.flow__link { height: 66px; width: 340px; }

@media screen and (max-width: 767px) { .flow__link { height: auto; width: 200px; } }

@media screen and (max-width: 480px) { .flow__link { width: calc(50% - 14px); } }

.flow__link:first-child { margin-right: 22px; }

@media screen and (max-width: 480px) { .flow__link:first-child { margin-right: 14px; } }

.shape__intro { font-size: 1.6rem; line-height: 1.75; margin-bottom: 23px; }

@media screen and (max-width: 767px) { .shape__intro { font-size: 1.2rem; line-height: 1.6; margin-bottom: 12px; } }

@media screen and (max-width: 767px) { .shape__spScroll { overflow-x: scroll; } }

.shape__table { width: 100%; margin-bottom: 8px; }

@media screen and (max-width: 767px) { .shape__table { min-width: 640px; } }

@media screen and (max-width: 767px) { .shape__table_col_01 { min-width: auto; } }

.shape__table_col_01 .shape__th { width: 192px; }

.shape__table_col_01 .shape__th_w_none { width: auto; }

@media screen and (max-width: 767px) { .shape__table_col_01 .shape__th_w_none { min-width: auto; } }

.shape__table_col_01 .shape__th_col_2 { width: 96px; }

@media screen and (max-width: 767px) { .shape__table_col_01 .shape__th_col_2 { width: 84px; min-width: auto; } }

.shape__table_col_01 .shape__th_text_ctr { width: 96px; }

@media screen and (max-width: 767px) { .shape__table_col_01 .shape__th_text_ctr { width: 53px; } }

.shape__table_col_01 .shape__td { width: calc(100% - 192px); }

@media screen and (max-width: 767px) { .shape__table_col_01 .shape__td { width: auto; } }

.shape__table_col_01 img { max-width: 57px; }

.shape__thead .shape__th:first-child, .shape__tbody .shape__th { background: #f0f0f0; padding: 22px 0 22px 20px; text-align: left; }

@media screen and (max-width: 767px) { .shape__thead .shape__th:first-child, .shape__tbody .shape__th { padding: 8px 15px; } }

@media screen and (max-width: 1080px) { .shape__tbody .shape__th_col_2 { min-width: 84px; } }

@media screen and (max-width: 767px) { .shape__tbody .shape__th_col_2 { min-width: auto; } }

.shape__tbody .shape__th_text_ctr { text-align: center; padding: 0; }

@media screen and (max-width: 1080px) { .shape__tbody .shape__th_text_ctr { min-width: 70px; padding-top: 8px; padding-bottom: 8px; } }

@media screen and (max-width: 767px) { .shape__tbody .shape__th_text_ctr { min-width: auto; } }

.shape__th { border: 1px solid #d7d7d7; font-size: 1.4rem; font-weight: 700; vertical-align: middle; }

@media screen and (max-width: 767px) { .shape__th { font-size: 1.2rem; } }

.shape__td { border: 1px solid #d7d7d7; font-size: 1.4rem; padding: 20px 0; text-align: center; vertical-align: middle; width: calc((100% - 192px) / 5); width: 160px; }

@media screen and (max-width: 767px) { .shape__td { font-size: 1.2rem; padding: 8px 15px; width: 100px; } }

.shape__notice { display: block; font-size: 1.4rem; margin-bottom: 6px; }

@media screen and (max-width: 767px) { .shape__notice { font-size: 1.2rem; } }

.package__flex { margin-bottom: 26px; }

@media screen and (max-width: 767px) { .package__flex { flex-wrap: wrap; } }

.package__fig { align-items: flex-start; border-bottom: 1px solid #d7d7d7; border-left: 1px solid #d7d7d7; border-top: 1px solid #d7d7d7; flex-shrink: 0; display: flex; justify-content: center; padding: 26px 0; width: 226px; }

@media screen and (max-width: 1080px) { .package__fig { padding: 26px 10px; width: 20%; } }

@media screen and (max-width: 767px) { .package__fig { border-right: 1px solid #d7d7d7; border-bottom: none; width: 100%; } }

.package__table { width: 770px; }

@media screen and (max-width: 1080px) { .package__table { width: 80%; } }

@media screen and (max-width: 767px) { .package__table { width: 100%; } }

@media screen and (max-width: 1080px) { .package__spScroll { width: 100%; } }

@media screen and (max-width: 767px) { .package__spScroll { overflow-x: scroll; width: 100%; } }

@media screen and (max-width: 1080px) { .package__spScroll .package__table { width: 100%; } }

@media screen and (max-width: 767px) { .package__spScroll .package__table { min-width: 540px; } }

.package__th { background: #f0f0f0; border: 1px solid #d7d7d7; font-size: 2rem; font-weight: 700; padding: 16px 0; }

@media screen and (max-width: 767px) { .package__th { font-size: 1.5rem; padding: 8px 0; } }

.package__td { border: 1px solid #d7d7d7; font-size: 1.4rem; padding: 12px 0; text-align: center; vertical-align: middle; }

@media screen and (max-width: 767px) { .package__td { font-size: 1.2rem; padding: 8px 0; } }

.package__td_font_l { font-size: 1.6rem; padding: 30px 0; }

@media screen and (max-width: 767px) { .package__td_font_l { font-size: 1.3rem; padding: 8px 10px; } }

.package__td_type_img { padding: 34px 0; }

@media screen and (max-width: 767px) { .package__td_type_img { padding: 12px 0; } }

.package__notice { font-size: 1.4rem; line-height: 1.8; }

@media screen and (max-width: 767px) { .package__notice { font-size: 1.2rem; } }

.trial__contents { margin-bottom: 42px; }

@media screen and (max-width: 767px) { .trial__contents { margin-bottom: 18px; } }

@media screen and (max-width: 480px) { .trial__dl { flex-wrap: wrap; } }

.trial__dt { background: #f0f0f0; border-left: 1px solid #d7d7d7; border-top: 1px solid #d7d7d7; font-size: 1.6rem; font-weight: 700; padding: 20px 0 20px 20px; width: 224px; }

@media screen and (max-width: 767px) { .trial__dt { font-size: 1.3rem; width: 30%; } }

@media screen and (max-width: 480px) { .trial__dt { border-right: 1px solid #d7d7d7; padding: 8px 15px; width: 100%; } }

.trial__dd { border-left: 1px solid #d7d7d7; border-right: 1px solid #d7d7d7; border-top: 1px solid #d7d7d7; font-size: 1.6rem; padding: 20px 24px 20px 24px; width: calc(100% - 224px); }

@media screen and (max-width: 767px) { .trial__dd { font-size: 1.3rem; width: 70%; } }

@media screen and (max-width: 480px) { .trial__dd { padding: 8px 15px; width: 100%; } }

.trial__dl:last-child .trial__dt, .trial__dl:last-child .trial__dd { border-bottom: 1px solid #d7d7d7; }

.trial__notice { font-size: 1.4rem; }

@media screen and (max-width: 767px) { .trial__notice { font-size: 1.2rem; } }

.testing__desc { font-size: 1.6rem; margin-bottom: 22px; }

@media screen and (max-width: 767px) { .testing__desc { font-size: 1.3rem; margin-bottom: 10px; } }

@media screen and (max-width: 480px) { .testing__dl { flex-wrap: wrap; } }

.testing__dt { align-items: center; background: #f0f0f0; border-left: 1px solid #d7d7d7; border-top: 1px solid #d7d7d7; font-size: 1.6rem; font-weight: 700; display: flex; padding: 20px 0 20px 20px; width: 224px; }

@media screen and (max-width: 767px) { .testing__dt { font-size: 1.3rem; width: 30%; } }

@media screen and (max-width: 480px) { .testing__dt { border-right: 1px solid #d7d7d7; padding: 8px 15px; width: 100%; } }

.testing__dd { border-left: 1px solid #d7d7d7; border-right: 1px solid #d7d7d7; border-top: 1px solid #d7d7d7; font-size: 1.6rem; padding: 20px 24px 20px 24px; width: calc(100% - 224px); }

@media screen and (max-width: 767px) { .testing__dd { font-size: 1.3rem; width: 70%; } }

@media screen and (max-width: 480px) { .testing__dd { padding: 8px 15px; width: 100%; } }

.testing__dl:last-child .testing__dt { border-bottom: 1px solid #d7d7d7; }

@media screen and (max-width: 480px) { .testing__dl:last-child .testing__dt { border-bottom: none; } }

.testing__dl:last-child .testing__dd { border-bottom: 1px solid #d7d7d7; }

.testing__notice { font-size: 1.4rem; display: block; margin-top: .5em; }

@media screen and (max-width: 767px) { .testing__notice { font-size: 1.2rem; } }

.price__desc { font-size: 1.6rem; line-height: 1.75; margin-bottom: 14px; }

@media screen and (max-width: 767px) { .price__desc { font-size: 1.3rem; margin-bottom: 8px; } }

.price__notice { font-size: 1.4rem; display: block; margin-bottom: 12px; }

@media screen and (max-width: 767px) { .price__notice { font-size: 1.2rem; margin-bottom: 0; } }

.material__desc { font-size: 1.6rem; line-height: 1.75; margin-bottom: 14px; }

@media screen and (max-width: 767px) { .material__desc { font-size: 1.3rem; margin-bottom: 8px; } }

.material__dl { background: #f0f0f0; padding: 0 22px; }

@media screen and (max-width: 767px) { .material__dl { padding: 0 12px 8px; } }

.material__dl:first-of-type { padding-top: 22px; }

@media screen and (max-width: 767px) { .material__dl:first-of-type { padding-top: 10px; } }

.material__dl:last-of-type { padding-bottom: 22px; margin-bottom: 12px; }

@media screen and (max-width: 767px) { .material__dl:last-of-type { padding-bottom: 10px; } }

.material__dt { align-items: center; font-size: 1.6rem; display: flex; flex-shrink: 0; height: 30px; }

@media screen and (max-width: 767px) { .material__dt { align-items: flex-start; font-size: 1.3rem; height: auto; } }

.material__dd { align-items: center; font-size: 1.6rem; display: flex; }

@media screen and (max-width: 767px) { .material__dd { align-items: flex-start; font-size: 1.3rem; } }

.material__notice { font-size: 1.4rem; display: block; margin-bottom: 12px; }

@media screen and (max-width: 767px) { .material__notice { font-size: 1.2rem; margin-bottom: 0; } }

.recipe__contents { margin-bottom: 0; }

@media screen and (max-width: 480px) { .recipe__dl { flex-wrap: wrap; } }

.recipe__desc { font-size: 1.6rem; margin-bottom: 12px; }

@media screen and (max-width: 767px) { .recipe__desc { font-size: 1.3rem; margin-bottom: 8px; } }

.recipe__notice { font-size: 1.4rem; display: block; margin-bottom: 22px; }

@media screen and (max-width: 767px) { .recipe__notice { font-size: 1.2rem; margin-bottom: 8px; } }

.recipe__dt { align-items: center; background: #f0f0f0; border-left: 1px solid #d7d7d7; border-top: 1px solid #d7d7d7; font-size: 1.6rem; font-weight: 700; display: flex; padding: 18px 0 22px 20px; width: 224px; }

@media screen and (max-width: 767px) { .recipe__dt { font-size: 1.3rem; width: 30%; } }

@media screen and (max-width: 480px) { .recipe__dt { border-right: 1px solid #d7d7d7; padding: 8px 15px; width: 100%; } }

.recipe__dd { border-left: 1px solid #d7d7d7; border-right: 1px solid #d7d7d7; border-top: 1px solid #d7d7d7; font-size: 1.6rem; padding: 18px 24px 22px 24px; width: calc(100% - 224px); }

@media screen and (max-width: 767px) { .recipe__dd { font-size: 1.3rem; width: 70%; } }

@media screen and (max-width: 480px) { .recipe__dd { padding: 8px 15px; width: 100%; } }

.recipe__dl:last-child .recipe__dt, .recipe__dl:last-child .recipe__dd { border-bottom: 1px solid #d7d7d7; }

.recipe__ddNotice { font-size: 1.4rem; display: block; }

@media screen and (max-width: 767px) { .recipe__ddNotice { font-size: 1.2rem; } }

.recipe__combineDd { font-size: 1.4rem; line-height: 1.857; margin-bottom: 15px; }

@media screen and (max-width: 767px) { .recipe__combineDd { font-size: 1.1rem; margin-bottom: 8px; } }

.recipe__combineDd:last-of-type { margin-bottom: 0; }

.recipe__ddItem { margin-bottom: 2px; }

.recipe__red { color: #e83828; font-weight: 600; }

.contact { max-width: 900px; margin-left: auto; margin-right: auto; padding-top: 64px; padding-bottom: 100px; }

@media screen and (max-width: 767px) { .contact { padding-top: 0; padding-bottom: 30px; } }

.contact__headline { font-size: 2.4rem; font-weight: 600; text-align: center; line-height: 1.75; margin-bottom: 42px; }

@media screen and (max-width: 767px) { .contact__headline { font-size: 1.8rem; line-height: 1.6; margin-bottom: 25px; } }

.contact__form_page_confirm .contact__term:after { display: none; }

.contact__form_page_confirm .contact__caution-item { display: none; }

.contact__form_page_confirm .contact__caution { display: none; }

.contact__ttl { margin-bottom: 52px; }

@media screen and (max-width: 767px) { .contact__ttl { margin-bottom: 30px; } }

.contact__scTtl { font-size: 2.2rem; font-weight: 700; text-align: center; display: flex; justify-content: center; align-items: center; margin-bottom: 23px; }

.contact__scTtl:before { display: block; margin-right: 10px; }

@media screen and (max-width: 767px) { .contact__scTtl:before { margin-right: 5px; } }

@media screen and (max-width: 767px) { .contact__scTtl { font-size: 1.6rem; margin-bottom: 15px; } }

.contact__scTtl_type_tel:before { content: ""; background: url(../img/icon_tel.svg) no-repeat center center/43.5px 43.5px; width: 43.5px; height: 43.5px; }

@media screen and (max-width: 767px) { .contact__scTtl_type_tel:before { background: url(../img/icon_tel.svg) no-repeat center center/30px 30px; width: 30px; height: 30px; } }

.contact__scTtl_type_mail { margin-bottom: 17px; }

.contact__scTtl_type_mail:before { content: ""; background: url(../img/icon_mail.svg) no-repeat center center/43.5px 43.5px; width: 43.5px; height: 43.5px; }

@media screen and (max-width: 767px) { .contact__scTtl_type_mail:before { background: url(../img/icon_mail.svg) no-repeat center center/30px 30px; width: 30px; height: 30px; } }

.contact__telNum { font-size: 4.4rem; font-weight: 700; text-align: center; margin-bottom: 20px; line-height: 1; }

@media screen and (max-width: 767px) { .contact__telNum { font-size: 3.2rem; margin-bottom: 15px; } }

.contact__telNum a { color: #212121; text-decoration: none; line-height: 1; }

.contact__inner { background-color: #fff; }

.contact__inner_type_tel { padding: 45px 40px 37px 40px; margin-bottom: 35px; }

@media screen and (max-width: 767px) { .contact__inner_type_tel { padding: 20px; } }

.contact__inner_type_form { padding: 45px 50px 80px 45px; }

@media screen and (max-width: 767px) { .contact__inner_type_form { padding: 20px; } }

.contact__innerPara { font-size: 1.4rem; text-align: center; }

@media screen and (max-width: 767px) { .contact__innerPara { font-size: 1.2rem; } }

.contact__stList { margin-top: 32px; margin-bottom: 50px; }

@media screen and (max-width: 1080px) { .contact__stList { margin-right: -10px; margin-left: -10px; } }

@media screen and (max-width: 767px) { .contact__stList { margin-bottom: 30px; margin-top: 15px; margin-left: -5px; margin-right: -5px; } }

.contact__stItem { font-size: 1.4rem; font-weight: 700; text-align: center; width: 240px; padding: 10px 0; background-color: #D7D7D7; margin: 0 5px; }

@media screen and (max-width: 1080px) { .contact__stItem { width: calc(33.3333% - 10px); } }

@media screen and (max-width: 767px) { .contact__stItem { font-size: 1.2rem; width: calc(33.3333% - 10px); } }

.contact__stItem_current { background-color: #ee7c28; color: #fff; }

.contact__section_type_body { border-top: 1px solid #d7d7d7; margin-bottom: 43px; }

@media screen and (max-width: 767px) { .contact__section_type_body { margin-bottom: 30px; } }

.contact__section_type_info { padding-bottom: 80px; }

@media screen and (max-width: 767px) { .contact__section_type_info { padding-bottom: 30px; } }

.contact__subttl { font-size: 2.0rem; font-weight: 700; background: url(../img/bg_bl_pt01.png) repeat center center/2px 2px; line-height: 1; padding: 15px; text-align: center; }

@media screen and (max-width: 767px) { .contact__subttl { font-size: 1.6rem; padding: 12px; } }

.contact__form-tab { display: none; }

.contact__list { border-bottom: 1px solid #D7D7D7; padding: 20px 0; display: flex; align-items: center; width: 100%; }

@media screen and (max-width: 767px) { .contact__list { padding: 15px 0; flex-wrap: wrap; } }

.contact__term { width: 260px; font-size: 1.6rem; font-weight: 700; margin-right: 35px; display: flex; justify-content: space-between; align-items: center; }

@media screen and (max-width: 1080px) { .contact__term { width: 35%; } }

@media screen and (max-width: 767px) { .contact__term { font-size: 1.3rem; width: 100%; justify-content: flex-start; margin-bottom: 10px; } }

.contact__term:after { font-size: 1.2rem; width: 43px; height: 24px; text-align: center; color: #ffffff; display: block; display: flex; justify-content: center; align-items: center; }

@media screen and (max-width: 767px) { .contact__term:after { font-size: 1.0rem; width: 32px; height: 16px; margin-left: 10px; } }

.contact__term_type_required:after { content: "必須"; background-color: #ea5514; }

.contact__term_type_any:after { content: "任意"; background-color: #898989; }

.contact__detail { width: calc(100% - 295px); }

@media screen and (max-width: 767px) { .contact__detail { width: 100%; } }

.contact__detail input { background-color: #FAFAFA; font-size: 1.4rem; padding: 11.5px 15px; border: 1px solid #D7D7D7; max-width: 100%; box-sizing: border-box; }

@media screen and (max-width: 767px) { .contact__detail input { font-size: 1.2rem; padding: 10px; } }

.contact__detail input.roText { border: none; outline: none; padding: 0; background: none; }

.contact__detail textarea { width: 100%; background-color: #FAFAFA; font-size: 1.4rem; padding: 11.5px 15px; border: 1px solid #D7D7D7; box-sizing: border-box; height: 190px; }

@media screen and (max-width: 767px) { .contact__detail textarea { font-size: 1.2rem; padding: 10px; height: 120px; } }

.contact__detail select { -moz-appearance: none; -webkit-appearance: none; appearance: none; background: transparent; border-radius: 0; padding: 11.5px 15px; background-color: #FAFAFA; font-size: 1.4rem; padding: 8.5px 15px; border: 1px solid #D7D7D7; line-height: 1.5; box-sizing: border-box; width: 100%; color: #212121; }

@media screen and (max-width: 767px) { .contact__detail select { font-size: 1.2rem; padding: 10px; } }

.contact__detail select::-ms-expand { display: none; }

.contact__detail input::placeholder, .contact__detail select::placeholder { color: #A0A0A0; }

.contact__detail_type_code .flb, .contact__detail_type_code .flb_type_t, .contact__detail_type_code .flb_type_tl, .contact__detail_type_code .flb_type_tc, .contact__detail_type_code .flb_type_tr, .contact__detail_type_code .flb_type_c, .contact__detail_type_code .flb_type_cl, .contact__detail_type_code .flb_type_cc, .contact__detail_type_code .flb_type_cr, .contact__detail_type_code .flb_type_cb, .contact__detail_type_code .flb_type_b, .contact__detail_type_code .flb_type_bl, .contact__detail_type_code .flb_type_bc, .contact__detail_type_code .flb_type_br { align-items: center; }

.contact__detail_type_code .contact__input_type_code { width: 286px; }

@media screen and (max-width: 767px) { .contact__detail_type_code .contact__input_type_code { width: 200px; } }

.contact__detail_type_tel input { width: 371px; }

@media screen and (max-width: 767px) { .contact__detail_type_tel input { width: 100%; } }

.contact__detail-sp { display: inline-block; font-size: 1.4rem; padding-left: 10px; padding-right: 13px; }

@media screen and (max-width: 767px) { .contact__detail-sp { font-size: 1.2rem; padding-left: 5px; padding-right: 8px; } }

.contact__input { width: 100%; }

.contact__input_type_year { width: 100px; }

@media screen and (max-width: 767px) { .contact__input_type_year { width: 70px; } }

.contact__input_type_month { width: 60px; }

@media screen and (max-width: 767px) { .contact__input_type_month { width: 45px; } }

.contact__input_type_day { width: 60px; }

@media screen and (max-width: 767px) { .contact__input_type_day { width: 45px; } }

.contact__labels { width: 286px; display: block; position: relative; }

.contact__labels br { display: none; }

@media screen and (max-width: 767px) { .contact__labels { width: 200px; } }

.contact__labels:after { content: ""; display: block; background: url(../img/icon_tb_arrow.svg) no-repeat center center/6px 20px; width: 6px; height: 20px; background-size: cover; position: absolute; right: 12px; top: calc(50% - 10px); }

.contact__caution-item { font-size: 1.4rem; margin-top: 7px; }

@media screen and (max-width: 767px) { .contact__caution-item { font-size: 1.2rem; margin-top: 5px; } }

.contact__caution { font-size: 1.4rem; margin-top: 47px; text-align: center; }

@media screen and (max-width: 767px) { .contact__caution { font-size: 1.2rem; margin-top: 25px; text-align: left; } }

.contact__caution a { text-decoration: underline; color: #212121; }

.contact__caution a:hover { text-decoration: none; }

.contact__button { width: 376px; background-color: #ee7c28; font-size: 1.6rem; text-align: center; border: 1px solid #ee7c28; color: #ffffff; padding: 22px 0; font-weight: 700; margin-left: auto; margin-right: auto; position: relative; margin-left: auto; margin-right: auto; display: block; margin-top: 21px; cursor: pointer; }

@media screen and (max-width: 767px) { .contact__button { width: 100%; font-size: 1.3rem; padding: 18.5px 0; } }

.contact__button:hover { opacity: .75; }

.contact__button:after { content: ""; width: 8px; height: 14px; background: url(../img/arrow_icon_wh.svg) no-repeat center center/8px 14px; display: block; position: absolute; right: 25px; top: calc(50% - 7px); }

@media screen and (max-width: 767px) { .contact__button:after { content: ""; display: block; width: 8px; height: 13.4px; background: url(../img/arrow_icon_wh.svg) no-repeat center center/cover; right: 20px; top: calc(50% - 6.7px); position: absolute; } }

.contact__button_type_buck { background-color: #1e1e1e; border-color: #1e1e1e; }

.contact__button_type_buck:after { background: url(../img/arrow_icon_wh_left.svg) no-repeat center center/8px 14px; right: auto; left: 25px; }

@media screen and (max-width: 767px) { .contact__button_type_buck:after { background: url(../img/arrow_icon_wh_left.svg) no-repeat center center/cover; } }

.contact__btList { display: none; justify-content: center; flex-wrap: wrap; margin-top: 50px; }

@media screen and (max-width: 767px) { .contact__btList { margin-top: 20px; } }

.contact__btItem { padding: 0 7.5px; }

@media screen and (max-width: 767px) { .contact__btItem { width: 100%; padding: 0; }
  .contact__btItem:first-of-type { margin-bottom: 10px; } }

.contact__btItem .contact__button { width: 280px; margin: 0; }

@media screen and (max-width: 767px) { .contact__btItem .contact__button { margin: 0; width: 100%; } }

.radios__item { margin-bottom: 10px; }

.radios__item:last-of-type { margin-bottom: 0; }

.radios .mwform-radio-field { margin-bottom: 10px; }

.radios .mwform-radio-field:last-of-type { margin-bottom: 0; }

.radios__lab { position: relative; }

.radios label { position: relative; }

.radios label br { display: none; }

.radios__inp { display: none; }

.radios__inp:checked + .radios__texts::after { content: ""; display: block; position: absolute; top: calc(50% - 7px); left: 6px; width: 14px; height: 14px; background: #323232; border-radius: 50%; }

@media screen and (max-width: 767px) { .radios__inp:checked + .radios__texts::after { width: 8px; height: 8px; top: calc(50% - 4px); left: 5px; } }

.radios__inp:checked + .mwform-radio-field-text::after { content: ""; display: block; position: absolute; top: calc(50% - 7px); left: 6px; width: 14px; height: 14px; background: #323232; border-radius: 50%; }

@media screen and (max-width: 767px) { .radios__inp:checked + .mwform-radio-field-text::after { width: 8px; height: 8px; top: calc(50% - 4px); left: 5px; } }

.radios__texts { font-size: 1.6rem; display: flex; align-items: center; position: relative; }

@media screen and (max-width: 767px) { .radios__texts { font-size: 1.3rem; } }

.radios__texts:before { content: ""; display: block; width: 24px; height: 24px; border: 1px solid #323232; border-radius: 50%; margin-right: 10px; }

@media screen and (max-width: 767px) { .radios__texts:before { width: 16px; height: 16px; } }

.radios .mwform-radio-field-text { font-size: 1.6rem; display: flex; align-items: center; position: relative; }

@media screen and (max-width: 767px) { .radios .mwform-radio-field-text { font-size: 1.3rem; } }

.radios .mwform-radio-field-text:before { content: ""; display: block; width: 24px; height: 24px; border: 1px solid #323232; border-radius: 50%; margin-right: 10px; }

@media screen and (max-width: 767px) { .radios .mwform-radio-field-text:before { width: 16px; height: 16px; } }

.radios_type_col { display: flex; }

.radios_type_col .radios__item { padding-right: 40px; margin-bottom: 0; }

@media screen and (max-width: 767px) { .radios_type_col .radios__item { padding-right: 20px; } }

.radios_type_col .mwform-radio-field { margin-top: 0 !important; margin-bottom: 0 !important; padding-right: 40px; margin-bottom: 0; }

@media screen and (max-width: 767px) { .radios_type_col .mwform-radio-field { padding-right: 20px; } }

.mw_wp_form_confirm .contact__term:after { display: none; }

.mw_wp_form_confirm .contact__caution-item { display: none; }

.mw_wp_form_confirm .contact__caution { display: none; }

.mw_wp_form_confirm .contact__labels:after { display: none; }

.mw_wp_form_confirm .contact__btList { display: flex; }

.thanks__ttl { font-size: 2.0rem; font-weight: 600; text-align: center; margin-bottom: 32px; }

@media screen and (max-width: 767px) { .thanks__ttl { font-size: 1.6rem; margin-bottom: 15px; } }

.thanks__para { font-size: 1.4rem; margin-bottom: 1.5em; }

@media screen and (max-width: 767px) { .thanks__para { font-size: 1.2rem; } }

.thanks__para:last-of-type { margin-bottom: 0; }

.thanks__bt { margin-top: 46px; margin-left: auto; margin-right: auto; }

@media screen and (max-width: 767px) { .thanks__bt { margin-top: 25px; } }

.schedule__container { padding: 70px 0 96px; }

@media screen and (max-width: 767px) { .schedule__container { padding: 12px 0 38px; } }

.schedule__sectionTtl { font-size: 2.4rem; font-weight: 700; margin-bottom: 46px; text-align: center; }

@media screen and (max-width: 767px) { .schedule__sectionTtl { font-size: 1.6rem; margin-bottom: 16px; } }

.schedule__term { align-items: center; background: url(../img/bg_dark-green.png) repeat; color: #fff; flex-shrink: 0; font-size: 1.4rem; display: flex; justify-content: center; letter-spacing: 2px; margin: 0 26px 28px 0; text-orientation: upright; width: 40px; writing-mode: vertical-rl; }

@media screen and (max-width: 767px) { .schedule__term { font-size: 1.2rem; letter-spacing: 0.5px; margin: 0 12px 14px 0; width: 30px; } }

.schedule__flow { width: calc(100% - 76px); }

@media screen and (max-width: 767px) { .schedule__flow { width: calc(100% - 42px); } }

.schedule__item { margin-bottom: 28px; position: relative; }

@media screen and (max-width: 767px) { .schedule__item { margin-bottom: 16px; } }

.schedule__item:after { background: #f5ae7a; content: ""; display: block; height: 50px; left: 38px; position: absolute; top: 100%; width: 10px; }

@media screen and (max-width: 767px) { .schedule__item:after { left: 18px; height: 20px; width: 8px; } }

.schedule__item_type_last:after { content: none; }

.schedule__ttl { background: #fff url(../img/bg_orange.png) repeat; font-size: 2.4rem; font-weight: 700; padding: 15px 0 15px 26px; position: relative; z-index: 1; }

@media screen and (max-width: 767px) { .schedule__ttl { display: flex; font-size: 1.4rem; padding: 8px 0 8px 20px; } }

.schedule__num { color: #ec6c0e; margin-right: 10px; }

.schedule__text { background: #fff; font-size: 1.6rem; line-height: 1.75; padding: 22px 26px; position: relative; z-index: 1; }

@media screen and (max-width: 767px) { .schedule__text { font-size: 1.3rem; padding: 8px 20px; } }

.schedule__notice { padding-left: 70px; }

@media screen and (max-width: 767px) { .schedule__notice { font-size: 1.2rem; padding-left: 42px; } }

.col-2-lists { padding-left: 15px; }

@media screen and (max-width: 767px) { .col-2-lists { padding-left: 0; } }

.col-2-lists__list { display: flex; margin-bottom: 4px; }

.col-2-lists__list:last-of-type { margin-bottom: 0; }

.col-2-lists__term { font-size: 1.6rem; font-weight: 600; text-align: center; width: 146px; background: url(../img/bg_pt_or03.png); padding: 34px 10px; display: flex; align-items: center; justify-content: center; flex-wrap: wrap; }

@media screen and (max-width: 767px) { .col-2-lists__term { width: 90px; font-size: 1.3rem; padding: 15px 20px; } }

.col-2-lists__term span { font-size: 1.2rem; display: block; }

.col-2-lists__detail { background-color: #fff; padding: 34px 30px; width: calc(100% - 146px); font-size: 1.6rem; }

@media screen and (max-width: 767px) { .col-2-lists__detail { width: calc(100% - 90px); font-size: 1.3rem; padding: 15px 20px; } }

.tagList { flex-wrap: wrap; margin-right: -8px; margin-bottom: -8px; }

@media screen and (max-width: 767px) { .tagList { margin-right: -5px; margin-bottom: -5px; } }

.tagList__item { font-size: 1.4rem; padding: 8px 12px; background-color: #EFEFEF; border-radius: 4px; margin-right: 8px; margin-bottom: 8px; font-weight: 600; }

@media screen and (max-width: 767px) { .tagList__item { font-size: 1.0rem; padding: 5px 8px; margin-right: 5px; margin-bottom: 5px; } }

.tagList__item_current { background: url(../img/bg_pt_or03.png); }

.borderList__list { display: flex; flex-wrap: wrap; border-bottom: 1px solid #d7d7d7; padding: 38px 0; position: relative; align-items: center; }

@media screen and (max-width: 767px) { .borderList__list { padding: 20px 0; } }

.borderList__list:after { content: ""; width: 223px; height: 1px; background-color: #fad5ba; position: absolute; left: 0; bottom: -1px; }

@media screen and (max-width: 767px) { .borderList__list:after { width: 100px; } }

.borderList__list:last-of-type { border: none; }

.borderList__list:last-of-type:after { display: none; }

.borderList__term { width: 230px; flex-basis: 230px; max-width: 230px; font-size: 1.6rem; font-weight: 600; }

@media screen and (max-width: 767px) { .borderList__term { width: 100%; flex-basis: 100%; max-width: 100%; font-size: 1.4rem; margin-bottom: 9px; } }

.borderList__detail { width: calc(100% - 230px); flex-basis: calc(100% - 230px); max-width: calc(100% - 230px); }

@media screen and (max-width: 767px) { .borderList__detail { width: 100%; flex-basis: 100%; max-width: 100%; } }

.borderList__para { font-size: 1.6rem; }

@media screen and (max-width: 767px) { .borderList__para { font-size: 1.2rem; } }

.karteDetail { padding-top: 65px; padding-bottom: 100px; }

@media screen and (max-width: 767px) { .karteDetail { padding-top: 0; padding-bottom: 30px; } }

.karte { overflow: hidden; padding-top: 70px; padding-bottom: 70px; }

@media screen and (max-width: 767px) { .karte { padding-top: 28px; padding-bottom: 30px; } }

.karte__wrap { position: relative; }

.karte__details { width: 284px; background-color: #ffffff; padding: 30px 40px 40px 40px; position: absolute; right: 0; top: 38px; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2); z-index: 3; }

@media screen and (max-width: 1080px) { .karte__details { right: 30px; } }

@media screen and (max-width: 767px) { .karte__details { position: static; width: 100%; box-shadow: none; padding: 0; background-color: transparent; } }

.karte__ttl { margin-bottom: 45px; }

@media screen and (max-width: 767px) { .karte__ttl { margin-bottom: 20px; } }

.karte__ttl-en { display: block; }

.karte__ttl-jp { display: block; }

.karte__para { margin-bottom: 22px; }

.karte__bt { background-color: #FFE106; color: #212121; font-weight: 700; display: block; padding: 15px 0; text-align: center; text-decoration: none; position: relative; }

.karte__bt:after { content: ""; width: 4.8px; height: 8px; background: url(../img/arrow_icon_bl.svg) no-repeat center center/cover; position: absolute; right: 15px; top: calc(50% - 4px); }

.karte__bt:hover { opacity: .75; }

@media screen and (max-width: 767px) { .karte__bt { display: none; } }

.karte__bt-sp { margin: 20px auto 0 auto; display: none; }

@media screen and (max-width: 767px) { .karte__bt-sp { display: block; } }

@media screen and (max-width: 767px) { .karte__list-inner { display: flex; justify-content: center; padding: 0 15px; } }

.karte__link { display: block; text-decoration: none; }

.karte__list { margin: 0 -5px; }

@media screen and (max-width: 1080px) { .karte__list { flex-wrap: nowrap; } }

@media screen and (max-width: 767px) { .karte__list { flex-wrap: wrap; flex: 0 0 50%; max-width: 50%; } }

.karte__list:first-of-type { margin-bottom: 10px; margin-left: -90px; }

@media screen and (max-width: 767px) { .karte__list:first-of-type { padding: 0; margin: -5px 0; } }

.karte__list:last-of-type { margin-left: 12px; }

@media screen and (max-width: 767px) { .karte__list:last-of-type { margin: 0; margin: -5px 0; } }

.karte__list.internal { flex-wrap: wrap; margin-left: -17.5px; margin-right: -17.5px; margin-bottom: -56px; }

@media screen and (max-width: 767px) { .karte__list.internal { flex-basis: auto; max-width: fit-content; margin-right: -5px; margin-left: -5px; margin-bottom: -21px; } }

.karte__list.internal .karte__item { flex: 0 0 25%; max-width: 25%; padding-left: 17.5px; padding-right: 17.5px; margin-bottom: 56px; }

@media screen and (max-width: 767px) { .karte__list.internal .karte__item { margin-bottom: 21px; } }

.karte__list.internal .karte__item:nth-of-type(n+4) { display: block; }

@media screen and (max-width: 1080px) { .karte__list.internal .karte__item { flex: 0 0 33.333%; max-width: 33.333%; } }

@media screen and (max-width: 767px) { .karte__list.internal .karte__item { flex: 0 0 50%; max-width: 50%; padding-right: 5px; padding-left: 5px; } }

.karte__list.internal .karte__item-inner { height: 234px; margin-bottom: 21px; }

@media screen and (max-width: 767px) { .karte__list.internal .karte__item-inner { height: 135px; margin-bottom: 10px; } }

.karte__list.internal .karte__item-detail { align-content: center; }

.karte__list.internal .karte__maker { margin-bottom: 7px; }

.karte__item { flex: 0 0 204px; max-width: 204px; padding: 0 5px; }

@media screen and (max-width: 767px) { .karte__item { flex: 0 0 100%; max-width: 100%; padding: 5px; }
  .karte__item:nth-of-type(n+4) { display: none; } }

.karte__itemInner { background-color: #ffffff; display: flex; justify-content: center; align-items: center; position: relative; margin-bottom: 17px; }

@media screen and (max-width: 767px) { .karte__itemInner { margin-bottom: 9px; } }

.karte__itemInner:hover { cursor: pointer; }

.karte__itemInner:hover:before { content: ""; display: block; width: 100%; height: 100%; background-color: rgba(20, 20, 20, 0.75); position: absolute; top: 0; left: 0; }

.karte__itemInner:hover .karte__itemDetail { position: absolute; left: 0; top: 0; z-index: 2; display: flex; align-items: center; flex-wrap: wrap; }

@media screen and (max-width: 767px) { .karte__itemThumb img { max-width: 100%; } }

.karte__itemDetail { display: none; height: 100%; padding: 45px 20px; align-content: center; }

@media screen and (max-width: 767px) { .karte__itemDetail { padding: 10px; } }

.karte__name { flex: 0 0 100%; max-width: 100%; border-bottom: 1px solid #919191; padding-bottom: 10px; margin-bottom: 10px; }

.karte__nameTerm { color: #ec6c0e; margin-bottom: 5px; }

.karte__nameDesc { color: #ffffff; letter-spacing: -.02em; font-weight: 700; }

.karte__maker { flex: 0 0 100%; max-width: 100%; }

.karte__maker-term { color: #ec6c0e; min-width: 47px; }

.karte__maker-desc { color: #ffffff; }

.karte__name .karte__taste { flex: 0 0 100%; max-width: 100%; }

.karte__taste-term { color: #ec6c0e; min-width: 47px; }

.karte__taste-desc { color: #ffffff; }

.karte__headline { font-size: 1.8rem; font-weight: 700; color: #212121; margin-bottom: 7px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

@media screen and (max-width: 767px) { .karte__headline { font-size: 1.2rem; margin-bottom: 5px; } }

.karte__detailList { font-size: 1.4rem; }

@media screen and (max-width: 767px) { .karte__detailList { font-size: 1.0rem; } }

.karte__detailItem { color: #212121; }

.karte-detail-section { padding-bottom: 120px; }

.itemDetails { display: flex; margin-bottom: 70px; align-items: flex-start; }

@media screen and (max-width: 767px) { .itemDetails { margin-bottom: 45px; flex-wrap: wrap; } }

.itemDetails__thumb { flex: 0 0 506px; max-width: 506px; background-color: #ffffff; display: flex; align-items: center; align-content: center; flex-wrap: wrap; }

@media screen and (max-width: 1080px) { .itemDetails__thumb { flex: 0 0 42%; max-width: 42%; } }

@media screen and (max-width: 767px) { .itemDetails__thumb { flex: 0 0 100%; max-width: 100%; margin-bottom: 15px; height: auto; width: 100%; margin-left: auto; margin-right: auto; text-align: center; } }

.itemDetails__image { width: 100%; padding-top: 88%; margin-bottom: -4%; position: relative; overflow: hidden; font-size: 0; line-height: 0; background-size: cover; background-position: center center; background-repeat: no-repeat; }

.itemDetails .slick-list { width: 100%; }

.itemDetails .slick-dots { flex: 0 0 100%; max-width: 100%; position: absolute; left: 20px; bottom: 23px; text-align: left; }

@media screen and (max-width: 767px) { .itemDetails .slick-dots { margin-top: 10px; height: 10px; } }

.itemDetails .slick-dots li { width: 9px; height: 9px; }

.itemDetails .slick-dots li.slick-active button:before { color: #ec6c0e; opacity: 1; }

.itemDetails .slick-dots li button { width: 9px; height: 9px; padding: 0; }

@media screen and (max-width: 767px) { .itemDetails .slick-dots li button { width: 20px; height: 10px; } }

.itemDetails .slick-dots li button:hover:before { color: #ec6c0e; }

.itemDetails .slick-dots li button:before { width: 9px; height: 9px; font-size: 9px; line-height: 9px; text-align: center; margin: 0; padding: 0; color: #DCDDDD; opacity: 1; }

@media screen and (max-width: 767px) { .itemDetails .slick-dots li button:before { width: 9px; height: 9px; font-size: 9px; line-height: 9px; position: static; } }

.karte-content { margin-bottom: 95px; box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08); }

@media screen and (max-width: 767px) { .karte-content { margin-bottom: 45px; } }

.karte-content__ttl { font-size: 2.8rem; font-weight: 700; display: flex; justify-content: center; align-items: center; padding: 19.5px 0; background: url(../img/bg_pt_or03.png); }

@media screen and (max-width: 767px) { .karte-content__ttl { font-size: 1.8rem; padding: 13px 0; } }

.karte-content__ttl:before { content: ""; background: url(../img/icon_karte.svg) no-repeat center center/cover; width: 30px; height: 37px; margin-right: 13px; }

@media screen and (max-width: 767px) { .karte-content__ttl:before { margin-right: 8px; width: 20px; height: 25px; } }

.karte-content__ttl--iagnosis:before { background: url(../img/icon_comment.svg) no-repeat center center/cover; width: 41px; height: 37px; }

@media screen and (max-width: 767px) { .karte-content__ttl--iagnosis:before { margin-right: 8px; width: 25px; height: 28px; } }

.karte-content__inner { background-color: #ffffff; padding: 0 40px; }

@media screen and (max-width: 767px) { .karte-content__inner { padding: 0 20px 0px 20px; } }

.karte__impression { width: 100vw; position: relative; left: 50%; right: 50%; margin-left: -50vw; margin-right: -50vw; height: 373px; display: flex; align-items: flex-end; justify-content: center; margin-bottom: 160px; }

@media screen and (max-width: 767px) { .karte__impression { height: 180px; margin-bottom: 140px; } }

.karte__impression:before { content: ""; background: url(../img/karte_impression.png) no-repeat center center/cover; width: 100vw; height: 373px; display: block; position: absolute; z-index: 0; left: 0; top: 0; }

@media screen and (max-width: 767px) { .karte__impression:before { height: 180px; } }

.karte__impression-inner { position: relative; z-index: 2; }

.karte__impression-inner .karte-content { position: relative; top: 70px; z-index: 2; margin-bottom: 0; }

@media screen and (max-width: 767px) { .karte__impression-inner .karte-content { top: 100px; } }

.karte__impression-inner .karte-content__inner { padding: 35px 45px; font-size: 1.6rem; }

@media screen and (max-width: 767px) { .karte__impression-inner .karte-content__inner { font-size: 1.2rem; padding: 20px; } }

.karte__othersTtl { margin-bottom: 35px; }

@media screen and (max-width: 767px) { .karte__othersTtl { margin-bottom: 15px; } }

.news { padding-bottom: 100px; }

@media screen and (max-width: 767px) { .news { padding-bottom: 60px; } }

.news .newsList { padding-top: 50px; }

@media screen and (max-width: 767px) { .news .newsList { padding-top: 0; } }

.news__article { padding-top: 80px; padding-bottom: 45px; }

@media screen and (max-width: 767px) { .news__article { padding-top: 0; } }

.news__article .articleDetails__ttl { margin-bottom: 37px; }

@media screen and (max-width: 767px) { .news__article .articleDetails__ttl { margin-bottom: 20px; } }

.news__ttl { text-align: center; margin-bottom: 40px; }

@media screen and (max-width: 767px) { .news__ttl { text-align: left; margin-bottom: 18px; } }

.news__ttl-en { display: block; }

.news__list { margin-bottom: 50px; }

@media screen and (max-width: 767px) { .news__list { margin-bottom: 13px; } }

.news__item { padding-bottom: 28px; border-bottom: 1px solid #DCDDDD; margin-bottom: 26px; }

.news__item:last-of-type { border: none; margin-bottom: 0; padding-bottom: 0; }

@media screen and (max-width: 767px) { .news__item { margin-bottom: 20px; padding-bottom: 14px; } }

.news__link { text-decoration: none; color: #212121; flex-wrap: nowrap; }

@media screen and (max-width: 1080px) { .news__link { flex-wrap: nowrap; } }

@media screen and (max-width: 767px) { .news__link { flex-wrap: wrap; } }

.news__cat { font-size: 1.2rem; background-color: #EDEDED; padding: 5px 0; text-align: center; width: 94px; flex: 0 0 94px; margin-right: 30px; }

@media screen and (max-width: 767px) { .news__cat { font-size: 1.0rem; width: 60px; flex: 0 0 60px; padding: 1px 0; margin-right: 10px; } }

.news__date { font-size: 1.2rem; margin-right: 30px; }

@media screen and (max-width: 767px) { .news__date { font-size: 1.1rem; } }

.news__headline { font-size: 1.6rem; }

@media screen and (max-width: 1080px) { .news__headline { margin-top: 10px; } }

@media screen and (max-width: 767px) { .news__headline { font-size: 1.3rem; margin-top: 6px; } }

.news__bt { width: 180px; margin-left: auto; margin-right: auto; }

.newsList { padding-bottom: 100px; max-width: 800px; margin-left: auto; margin-right: auto; }

@media screen and (max-width: 767px) { .newsList { padding-bottom: 60px; } }

.newsList__item { border-bottom: 1px solid #efefef; }

.newsList__item:last-of-type { margin-bottom: 0; }

.newsList__link { text-decoration: none; color: #212121; flex-wrap: nowrap; padding: 20px 0; align-items: center; }

@media screen and (max-width: 767px) { .newsList__link { flex-wrap: wrap; } }

.newsList__link:hover .newsList__ttl { text-decoration: underline; }

.newsList__date { margin-right: 20px; }

@media screen and (max-width: 767px) { .newsList__date { order: 2; font-size: 1.1rem; } }

.newsList__cat { font-size: 1.2rem; background-color: #fff; padding: 3px 0; text-align: center; width: 92px; flex: 0 0 92px; margin-right: 22px; }

@media screen and (max-width: 767px) { .newsList__cat { order: 1; font-size: 1.0rem; width: 60px; flex: 0 0 60px; padding: 1px 0; margin-right: 10px; } }

.newsList__ttl { font-size: 1.6rem; }

@media screen and (max-width: 767px) { .newsList__ttl { order: 3; font-size: 1.3rem; margin-top: 6px; flex: 0 0 100%; max-width: 100%; } }

.snsList { position: relative; z-index: 2; }

.snsList__item { width: 148px; padding: 0 4px; }

@media screen and (max-width: 767px) { .snsList__item { width: 50%; } }

.snsList__link { font-size: 1.3rem; font-weight: 700; text-align: center; color: #ffffff; display: block; text-decoration: none; padding: 7px 0; position: relative; display: flex; justify-content: center; align-items: center; border-radius: 4px; }

@media screen and (max-width: 767px) { .snsList__link { font-size: 1.2rem; } }

.snsList__link:hover { position: relative; top: 4px; }

.snsList__link--tw { background-color: #1DA1F2; }

.snsList__link--tw:before { content: ""; background: url(../img/icon_twitter.svg) no-repeat center center/cover; width: 23px; height: 19px; display: block; margin-right: 6px; }

@media screen and (max-width: 767px) { .snsList__link--tw:before { width: 19px; height: 16px; margin-right: 5px; } }

.snsList__link--fb { background-color: #1777F2; }

.snsList__link--fb:before { content: ""; background: url(../img/icon_facebook.svg) no-repeat center center/cover; width: 10px; height: 18px; display: block; margin-right: 6px; }

@media screen and (max-width: 767px) { .snsList__link--fb:before { width: 9px; height: 16px; margin-right: 5px; } }

.oem { padding-bottom: 105px; }

@media screen and (max-width: 767px) { .oem { padding-bottom: 30px; } }

.oem__container { padding-top: 65px; margin-bottom: -40px; }

@media screen and (max-width: 767px) { .oem__container { padding-top: 0; margin-bottom: -20px; } }

.oem__ttl { margin-bottom: 45px; }

@media screen and (max-width: 767px) { .oem__ttl { margin-bottom: 20px; } }

.oem__section { margin-bottom: 40px; }

@media screen and (max-width: 767px) { .oem__section { margin-bottom: 20px; } }

.oem__section_col_02 { width: 50%; flex-basis: 50%; max-width: 50%; display: flex; flex-direction: column; }

@media screen and (max-width: 767px) { .oem__section_col_02 { width: 100%; flex-basis: 100%; max-width: 100%; } }

.oem__subTtl { font-size: 2.4rem; font-weight: 700; text-align: center; background: url(../img/bg_pt_or03.png); line-height: 1; padding: 27px 0; }

@media screen and (max-width: 767px) { .oem__subTtl { font-size: 1.6rem; padding: 10px 15px; } }

.oem__list { display: flex; padding: 40px 16px 43px 16px; background-color: #fff; flex-wrap: wrap; }

@media screen and (max-width: 767px) { .oem__list { padding: 30px 20px; } }

.oem__item { width: 33.333%; flex-basis: 33.333%; max-width: 33.333%; padding: 0 24px; position: relative; display: flex; flex-direction: column; }

@media screen and (max-width: 767px) { .oem__item { width: 100%; flex-basis: 100%; max-width: 100%; padding: 0; }
  .oem__item:last-of-type { margin-bottom: 0; } }

.oem__item:last-of-type:after { display: none; }

.oem__item:after { content: ""; width: 1px; height: 100%; background-color: #efefef; display: block; position: absolute; right: 0px; top: 0; }

@media screen and (max-width: 767px) { .oem__item:after { width: 100%; height: 1px; position: static; margin: 20px 0; } }

.oem__itemInner { height: 100%; }

.oem__fig { margin-bottom: 16px; }

@media screen and (max-width: 767px) { .oem__fig { margin-bottom: 13px; } }

.oem__fig a:hover { opacity: .75; }

.oem__fig img { width: 100%; }

.oem__name { font-size: 2.0rem; font-weight: 600; text-align: center; margin-bottom: 8px; }

@media screen and (max-width: 767px) { .oem__name { font-size: 1.6rem; margin-bottom: 10px; } }

.oem__desc { font-size: 1.4rem; }

@media screen and (max-width: 767px) { .oem__desc { font-size: 1.1rem; line-height: 1.63; } }

.oem__bt { width: 100%; margin-top: 16px; }

@media screen and (max-width: 767px) { .oem__bt { margin-top: 13px; max-width: 100%; } }

.oem__wrap { display: flex; flex-wrap: wrap; margin-left: -18px; margin-right: -18px; }

@media screen and (max-width: 767px) { .oem__wrap { margin-left: 0; margin-right: 0; } }

.oem__wrap .oem__section { padding-left: 18px; padding-right: 18px; }

@media screen and (max-width: 767px) { .oem__wrap .oem__section { padding: 0; } }

.oem__wrap .oem__itemInner { padding: 40px 40px 0 40px; background-color: #ffffff; }

@media screen and (max-width: 767px) { .oem__wrap .oem__itemInner { padding: 20px 20px 0 20px; } }

.oem__wrap .oem__bt { max-width: 100%; margin-top: 0; }

.oem__wrap .oem__btInner { background-color: #fff; padding: 21px 40px 40px 40px; }

@media screen and (max-width: 767px) { .oem__wrap .oem__btInner { padding: 13px 20px 20px 20px; } }

.performance { padding-bottom: 105px; }

@media screen and (max-width: 767px) { .performance { padding-bottom: 30px; } }

.performance__inner { padding-top: 65px; }

@media screen and (max-width: 767px) { .performance__inner { padding-top: 0; } }

.performance__ttl { margin-bottom: 50px; }

@media screen and (max-width: 767px) { .performance__ttl { margin-bottom: 20px; } }

.performance__list { max-width: 1000px; margin-left: auto; margin-right: auto; }

.performance__item { display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 35px; position: relative; }

@media screen and (max-width: 767px) { .performance__item { margin-bottom: 20px; } }

.performance__item:last-of-type { margin-bottom: 0; }

.performance__image { width: 412px; flex-basis: 412px; max-width: 412px; padding: 75px 30px 40px 30px; text-align: center; background-color: #fff; position: relative; display: flex; align-items: center; align-content: center; justify-content: center; flex-wrap: wrap; position: absolute; top: 0; left: 0; height: 100%; }

@media screen and (max-width: 1080px) { .performance__image { padding: 30px; width: 40%; flex-basis: 40%; max-width: 40%; padding: 30px; } }

@media screen and (max-width: 767px) { .performance__image { width: 100%; flex-basis: 100%; max-width: 100%; padding: 30px 20px 0 20px; padding-bottom: 0; position: static; } }

.performance__image img { width: 210px; }

@media screen and (max-width: 767px) { .performance__image img { max-width: 160px; } }

.performance__image:after { content: ""; background: url(../img/img_performance_shadow.png) no-repeat bottom center/100% auto; width: 220px; height: 32px; display: block; position: relative; margin: 40px auto 0 auto; }

@media screen and (max-width: 767px) { .performance__image:after { margin-top: 15px; } }

.performance__text { width: 582px; flex-basis: 582px; max-width: 582px; background-color: #fff; display: flex; flex-direction: column; margin-left: auto; }

@media screen and (max-width: 1080px) { .performance__text { width: calc(60% - 5px); flex-basis: calc(60% - 5px); max-width: calc(60% - 5px); } }

@media screen and (max-width: 767px) { .performance__text { width: 100%; flex-basis: 100%; max-width: 100%; } }

.performance__name { font-size: 2.4rem; font-weight: 700; background: url(../img/bg_pt_or03.png); line-height: 1; padding: 29px 30px; }

@media screen and (max-width: 767px) { .performance__name { font-size: 1.6rem; padding: 10px 15px; } }

.performance__detail { padding: 35px 40px 40px 40px; height: 100%; display: flex; flex-wrap: wrap; align-content: space-between; }

@media screen and (max-width: 1080px) { .performance__detail { padding: 20px; } }

.performance__desc { font-size: 1.6rem; margin-bottom: 32px; }

@media screen and (max-width: 1080px) { .performance__desc { margin-bottom: 30px; } }

@media screen and (max-width: 767px) { .performance__desc { font-size: 1.3rem; margin-bottom: 15px; } }

.performance__table { width: 100%; }

.performance__table th { background-color: #f7f8f8; padding: 17px 23px; border: 1px solid #efefef; text-align: left; }

@media screen and (max-width: 767px) { .performance__table th { font-size: 1.2rem; padding: 12px 15px; } }

.performance__table td { padding: 17px 23px; border: 1px solid #efefef; }

@media screen and (max-width: 767px) { .performance__table td { font-size: 1.2rem; padding: 12px 15px; } }

.performance__bt { font-size: 1.6rem; max-width: 100%; margin-top: 20px; width: 100%; }

.privacy { padding-top: 70px; padding-bottom: 100px; }

@media screen and (max-width: 767px) { .privacy { padding-top: 0; padding-bottom: 30px; } }

.privacy__inner { max-width: 1000px; margin-left: auto; margin-right: auto; }

.text-body__section { margin-top: 1.5em; }

@media screen and (max-width: 767px) { .text-body__para { font-size: 1.2rem; line-height: 1.6; } }

.text-body__ttl { font-weight: 600; }

.process { padding-bottom: 100px; }

@media screen and (max-width: 767px) { .process { padding-bottom: 60px; } }

.processSection { padding-top: 70px; }

@media screen and (max-width: 767px) { .processSection { padding-top: 0; } }

.process__ttl { margin-bottom: 47px; }

@media screen and (max-width: 767px) { .process__ttl { margin-bottom: 20px; } }

.tabSection { margin-bottom: 35px; }

@media screen and (max-width: 767px) { .tabSection { margin-bottom: 20px; } }

.tabSection:last-of-type { margin-bottom: 0; }

.tabSection__ttl { font-size: 2.4rem; font-weight: 700; text-align: center; background: url(../img/bg_pt_or03.png); line-height: 1; padding: 25px 0; }

@media screen and (max-width: 767px) { .tabSection__ttl { font-size: 1.6rem; padding: 10px 15px; } }

.tabSection__body { background-color: #fff; padding: 33px 40px; display: flex; justify-content: space-between; }

@media screen and (max-width: 767px) { .tabSection__body { padding: 17px 20px; display: block; } }

.processContent__para { font-size: 1.6rem; line-height: 1.65; margin-bottom: 1.5em; }

@media screen and (max-width: 767px) { .processContent__para { font-size: 1.3rem; } }

.processContent__para:last-of-type { margin-bottom: 0; }

.processContent__fig { display: flex; align-items: flex-start; margin-left: 20px; }

@media screen and (max-width: 1080px) { .processContent__fig { flex-direction: column; min-width: 260px; } }

@media screen and (max-width: 767px) { .processContent__fig { flex-direction: row; display: block; margin-left: 0; margin-top: 22px; } }

.processContent__img { min-width: 260px; width: 260px; flex-basis: 260px; height: auto; }

.processContent__img:nth-of-type(n+2) { margin-left: 10px; }

@media screen and (max-width: 1080px) { .processContent__img:nth-of-type(n+2) { margin-left: 0; margin-top: 10px; } }

@media screen and (max-width: 1080px) { .processContent__img { flex-basis: auto; } }

@media screen and (max-width: 767px) { .processContent__img { width: 100%; max-width: 100%; flex-basis: 100%; min-width: 100%; display: block; } }

.tabContents { display: none; }

.tabContents_current { display: block; }

.tabContents .page-section { margin-bottom: 36px; }

@media screen and (max-width: 767px) { .tabContents .page-section { margin-bottom: 25px; } }

.tabContents .page-section:last-of-type { margin-bottom: 0; }

.tabList { margin-left: -10px; margin-right: -10px; margin-bottom: 60px; justify-content: center; }

@media screen and (max-width: 767px) { .tabList { margin-left: -5px; margin-right: -5px; margin-bottom: 30px; } }

.tabList__item { flex-basis: 353px; max-width: 353px; padding: 0 10px; position: relative; }

.tabList__item--col-2 { flex: 0 0 50%; max-width: 50%; }

@media screen and (max-width: 767px) { .tabList__item { padding: 0 5px; flex: 0 0 50%; max-width: 50%; } }

.tabList__item_current:after { content: ""; display: block; width: 0; height: 0; border-style: solid; border-width: 14px 17.5px 0 17.5px; border-color: #ee7c28 transparent transparent transparent; position: absolute; bottom: -14px; left: calc(50% - 17.5px); }

@media screen and (max-width: 767px) { .tabList__item_current:after { border-width: 13px 10px 0 10px; left: calc(50% - 10px); bottom: -11px; } }

.tabList__item_current .tabList__link { background: #ee7c28; color: #fff; }

.tabList__link { font-size: 1.8rem; font-weight: 700; color: #212121; text-decoration: none; display: block; text-align: center; padding: 13px 0; border-radius: 4px; background: url(../img/bg_bl_pt01.png); }

@media screen and (max-width: 767px) { .tabList__link { font-size: 1.4rem; padding: 10px 0; font-weight: 700; letter-spacing: -.085em; } }

.tabList__link:hover { background: #ee7c28; color: #fff; }

.project { padding-top: 65px; padding-bottom: 100px; position: relative; }

@media screen and (max-width: 767px) { .project { padding-bottom: 30px; padding-top: 0; } }

.project__ttl { margin-bottom: 50px; }

@media screen and (max-width: 767px) { .project__ttl { margin-bottom: 25px; } }

.rowSection { margin-bottom: 30px; }

@media screen and (max-width: 767px) { .rowSection { margin-bottom: 20px; } }

.rowSection:last-of-type { margin-bottom: 0; }

.rowSection:nth-of-type(odd) .rowSection__texts { margin-left: -110px; order: 2; }

@media screen and (max-width: 767px) { .rowSection:nth-of-type(odd) .rowSection__texts { margin-left: 0; } }

.rowSection:nth-of-type(odd) .rowSection__image { order: 1; }

.rowSection:nth-of-type(even) .rowSection__texts { margin-right: -110px; order: 1; }

@media screen and (max-width: 767px) { .rowSection:nth-of-type(even) .rowSection__texts { margin-right: 0; order: 2; } }

.rowSection:nth-of-type(even) .rowSection__image { order: 2; }

@media screen and (max-width: 767px) { .rowSection:nth-of-type(even) .rowSection__image { order: 1; } }

.rowSection__container { display: flex; flex-wrap: wrap; }

@media screen and (max-width: 1080px) { .rowSection__container { justify-content: space-between; } }

.rowSection__texts { width: calc(100% - 450px); background-color: #fff; padding: 43px 50px 45px 50px; position: relative; margin-top: 35px; z-index: 2; }

@media screen and (max-width: 1080px) { .rowSection__texts { width: 60vw; padding: 30px; } }

@media screen and (max-width: 767px) { .rowSection__texts { width: 100%; margin-top: 0; padding: 20px; } }

.rowSection__subTtl { font-size: 2.2rem; font-weight: 600; line-height: 1.54; margin-bottom: 23px; }

@media screen and (max-width: 767px) { .rowSection__subTtl { font-size: 1.8rem; margin-bottom: 13px; } }

.rowSection__para { font-size: 1.6rem; line-height: 1.75; }

@media screen and (max-width: 767px) { .rowSection__para { font-size: 1.3rem; line-height: 1.6; } }

.rowSection__image { flex: 0 0 560px; max-width: 560px; }

@media screen and (max-width: 1080px) { .rowSection__image { flex: 0 0 40vw; max-width: 40vw; } }

@media screen and (max-width: 767px) { .rowSection__image { flex: 0 0 100%; max-width: 100%; text-align: center; } }

.iso { margin-top: 140px; }

@media screen and (max-width: 767px) { .iso { margin-top: 45px; } }

.iso__ttl { margin-bottom: 50px; }

@media screen and (max-width: 767px) { .iso__ttl { margin-bottom: 25px; } }

.iso__container { display: flex; align-items: flex-start; justify-content: space-between; }

@media screen and (max-width: 767px) { .iso__container { flex-wrap: wrap; } }

.iso__texts { width: calc(100% - 430px); background-color: #fff; padding: 43px 50px 60px 50px; position: relative; margin-top: 35px; z-index: 2; margin-right: -110px; }

@media screen and (max-width: 1080px) { .iso__texts { width: 60vw; padding: 30px; } }

@media screen and (max-width: 767px) { .iso__texts { width: 100%; margin-right: 0; margin-top: 0; padding: 20px; order: 2; } }

.iso__subTtl { font-size: 2.2rem; font-weight: 600; line-height: 1.54; margin-bottom: 23px; }

@media screen and (max-width: 767px) { .iso__subTtl { font-size: 1.8rem; margin-bottom: 13px; } }

.iso__para { font-size: 1.6rem; line-height: 1.75; margin-bottom: 2em; }

.iso__para:last-of-type { margin-bottom: 0; }

@media screen and (max-width: 767px) { .iso__para { font-size: 1.3rem; line-height: 1.6; } }

.iso__fig { flex: 0 0 560px; max-width: 560px; }

@media screen and (max-width: 1080px) { .iso__fig { flex: 0 0 40vw; max-width: 40vw; } }

@media screen and (max-width: 767px) { .iso__fig { flex: 0 0 100%; max-width: 100%; text-align: center; order: 1; } }

.rule { padding-top: 70px; padding-bottom: 100px; }

@media screen and (max-width: 767px) { .rule { padding-top: 0; padding-bottom: 30px; } }

.rule__inner { max-width: 1000px; margin-left: auto; margin-right: auto; }

.sitemap { padding-top: 70px; padding-bottom: 100px; }

@media screen and (max-width: 767px) { .sitemap { padding-bottom: 30px; padding-top: 0; } }

.sitemap__ttl { font-size: 2.4rem; font-weight: 700; text-align: center; margin-bottom: 43px; }

@media screen and (max-width: 767px) { .sitemap__ttl { font-size: 1.8rem; margin-bottom: 25px; } }

.sitemap__inner { border-top: 1px solid #d3d9dd; }

.sitemap__list { padding: 19px 0; border-bottom: 1px solid #d3d9dd; }

@media screen and (max-width: 767px) { .sitemap__list { padding: 10px 0; } }

.sitemap__list:last-of-type { border: none; }

.sitemap__link { font-size: 1.6rem; color: #212121; text-decoration: none; display: inline-flex; justify-content: flex-start; align-items: center; line-height: 2.5; }

.sitemap__link:hover { text-decoration: underline; }

@media screen and (max-width: 767px) { .sitemap__link { font-size: 1.3rem; } }

.sitemap__link:before { content: ""; background: url(../img/icon_bg_arrow.svg) no-repeat center center/cover; width: 18px; height: 18px; display: block; margin-right: 10px; }

@media screen and (max-width: 767px) { .sitemap__link:before { width: 14px; height: 14px; margin-right: 7px; } }

.sitemap__childList { padding-left: 28px; }

@media screen and (max-width: 767px) { .sitemap__childList { padding-left: 21px; } }

.sitemap__childLink { color: #212121; font-size: 1.6rem; text-decoration: none; display: inline-flex; align-items: center; line-height: 2.5; }

.sitemap__childLink:before { content: ""; width: 5px; height: 2px; background-color: #212121; display: block; margin-right: .5em; }

.sitemap__childLink:hover { text-decoration: underline; }

.sitemap__childLink:hover:before { text-decoration: none; }

.interview { font-size: 1.6rem; padding: 0 40px 100px 40px; background-color: #fff; color: #333333; margin-top: 37px; }

@media (max-width: 768px) { .interview { margin-top: 0; font-size: 1.3rem; padding: 0 20px 40px 20px; } }

@media (max-width: 768px) { .interview.wrap { padding-bottom: 40px; } }

.interview__header { margin-left: -40px; margin-right: -40px; margin-bottom: 40px; }

@media (max-width: 768px) { .interview__header { margin-left: -20px; margin-right: -20px; margin-bottom: 14px; } }

.interview__headerSp { display: none; }

@media (max-width: 768px) { .interview__headerSp { display: block; padding: 20px 20px 0 20px; } }

.interview__headerComp { display: flex; align-items: center; margin-bottom: 10px; }

.interview__headerCat { font-size: 1.0rem; color: #fff; font-weight: bold; background-color: #333; padding: 4px 10px; border-radius: 5px; }

.interview__headerDetail { font-size: 1.3rem; font-weight: bold; padding-left: 8px; }

.interview__headerSpTtl { font-size: 2.4rem; font-weight: bold; }

.interview .toc { background-color: #f9f9f9; border-radius: 5px; display: flex; }

.interview .toc__fig { width: 407px; max-width: 407px; flex-basis: 407px; }

@media (max-width: 1080px) { .interview .toc__fig { width: 40%; max-width: 40%; flex-basis: 40%; } }

@media (max-width: 768px) { .interview .toc__fig { width: 100%; flex-basis: 100%; max-width: 100%; } }

.interview .toc__img { border-radius: 5px 0 0 5px; object-fit: cover; font-family: 'object-fit: cover;'; height: 100%; }

@media (max-width: 768px) { .interview .toc__img { width: 100%; max-height: 180px; object-fit: cover; border-radius: 0; } }

.interview .toc__list { padding: 24px 30px; width: calc(100% - 407px); max-width: calc(100% - 407px); flex-basis: calc(100% - 407px); }

@media (max-width: 1080px) { .interview .toc__list { width: calc(100% - 40%); max-width: calc(100% - 40%); flex-basis: calc(100% - 40%); } }

@media (max-width: 768px) { .interview .toc__list { display: none; } }

.interview .toc__item { margin-bottom: 8px; display: flex; align-items: flex-start; list-style: none; }

.interview .toc__item::before { content: "・"; margin-top: -2px; }

.interview .toc__item:last-of-type { margin-bottom: 0; }

.interview .toc__link { font-size: 1.4rem; }

.interview .toc__link:hover { text-decoration: none; }

.interview__intro { margin-top: 2em; }

.interview__section { margin: 0; padding-top: 36px; }

.interview__section:last-of-type { margin-bottom: 0; }

@media (max-width: 768px) { .interview__section { margin: 18px 0 18px 0; padding-top: 0; } }

.interview__ttl { font-size: 2.2rem; font-weight: bold; padding-bottom: 12px; border-bottom: 2px solid #333333; margin-bottom: 30px; }

@media (max-width: 768px) { .interview__ttl { font-size: 1.8rem; padding-bottom: 7px; margin-bottom: 15px; } }

.interview__item { display: flex; align-items: flex-start; margin-bottom: 20px; background-color: #f9f9f9; padding: 25px 30px; border-radius: 5px; }

@media (max-width: 768px) { .interview__item { flex-wrap: wrap; padding: 12px 15px; margin-bottom: 10px; } }

.interview__name { max-width: 90px; flex-basis: 90px; text-align: center; justify-content: center; height: 40px; align-items: center; display: flex; background-color: #333333; color: #fff; font-weight: bold; border-radius: 40px; font-size: 1.4rem; }

@media (max-width: 768px) { .interview__name { font-size: 1.1rem; max-width: auto; flex-basis: auto; height: 20px; padding: 0 9px; margin-bottom: 9px; } }

.interview__name_type_cli { font-size: 1.6rem; background-color: #d80c17; color: #fff; }

@media (max-width: 768px) { .interview__name_type_cli { font-size: 1.1rem; } }

.interview__message { max-width: calc(100% - 90px); flex-basis: calc(100% - 90px); padding-left: 30px; line-height: 1.625; }

@media (max-width: 768px) { .interview__message { max-width: 100%; flex-basis: 100%; padding-left: 0; line-height: 1.4; } }

.interview__messagePara { margin-bottom: 1.5em; }

.interview__messagePara:last-of-type { margin-bottom: 0; }

.interview__messageLink:hover { text-decoration: none; }

.interview__messageSt { color: #ff9c00; font-weight: bold; }

.interview__fig { margin-bottom: 20px; }

@media (max-width: 768px) { .interview__fig { margin-bottom: 10px; } }

.interview__img { width: 100%; height: auto; }

.interview__footer { background-color: #f9f9f9; padding: 25px 30px; border-radius: 5px; margin-top: 20px; }

@media (max-width: 768px) { .interview__footer { margin-top: 10px; } }

.interview__footerTtl { font-size: 1.8rem; font-weight: bold; margin-bottom: 12px; }

@media (max-width: 768px) { .interview__footerTtl { font-size: 1.6rem; margin-bottom: 8px; } }

.interview__footerItem { line-height: 1.625; }

.interview__footerLink:hover { text-decoration: none; }
