@charset "utf-8";


////////////////////
//whatsCandy
////////////////////
@keyframes icon01 {
	0% { transform: translate(0, 0); }
	50% { transform: translate(0, 10px); }
	100% { transform: translate(0, 0); }
}
.whatsCandy {
	&__container {
		padding-top: 70px;
		@include media() {
			padding-top: 38px;
		}
	}
	&__history {
		padding-bottom: 105px;
		@include media() {
			padding-bottom: 42px;
		}
		&Inner {
			background: url(../img/bg_orange.png) repeat;
			border-radius: 40px;
			padding: 72px 0;
			position: relative;
			@include media('spm') {
				padding: 38px 0;
			}
		}
		&Inner:before {
			animation: icon01 1s infinite;
			background: url(../img/img_whats-candy03.svg) no-repeat center center / 86px 128px;
			content: "";
			display: block;
			height: 128px;
			position: absolute;
			top: 72px;
			left: 85px;
			width: 86px;
			@include media('tab') {
				background-size: 72px auto;
				left: 1%;
			}
			@include media() {
				background-size: 40px auto;
				top: 20px;
				right: -10px;
				top: 0;
			}
			@include media('spm') {
				left: -20px;
			}
		}
		&Inner:after {
			animation: icon01 1s 0.5s infinite;
			background: url(../img/img_whats-candy04.svg) no-repeat center center / 108px 152px;
			content: "";
			display: block;
			height: 152px;
			position: absolute;
			top: 66px;
			right: 95px;
			width: 108px;
			@include media('tab') {
				background-size: 96px auto;
				right: 1%;
			}
			@include media() {
				background-size: 48px auto;
				top: -9px;
				right: -10px;
			}
			@include media('spm') {
				right: -20px;
			}
		}
		&Txt {
			flex-wrap: wrap;
			justify-content: center;
			text-align: center;
		}
		&Para{
			font-size: 1.6rem;
			line-height: 1.625;
			margin-bottom: 1.5em;
			&:last-of-type{
				margin-bottom: 0;
			}
			@include media() {
				font-size: 1.3rem;
				padding: 0 15px;
			}
		}
		&Para br {
			@include media() {
				display: none;
			}
		}
		&Ttl{
			font-size: 3.6rem;
			font-weight: 700;
			margin-bottom: 36px;
			@include media() {
				font-size: 2rem;
			}
		}
		&Ttl br {
			display: none;
			@include media('spm') {
				display: block;
			}
		}
		&Fig{
			max-width: 928px;
			margin: 26px 0 48px;
			width: 100%;
			@include media('tab') {
				padding: 0 20px;
			}
			@include media() {
				margin: 0 0 20px;
				max-width: 100%;
				padding: 0 15px;
				width: 100%;
			}
		}
	}
}

.thing {
	padding: 105px 0 78px;
	@include media() {
		padding: 62px 0 32px;
	}
	&__inner {
		position: relative;
	}
	&__iconleft:before,
	&__iconleft:after,
	&__iconRight:before,
	&__iconRight:after {
		content: "";
		display: block;
		position: absolute;
	}
	&__iconleft:before {
		animation: icon01 1s infinite;
		background: url(../img/img_whats-candy05.png) no-repeat center center / 132px 152px;
		height: 152px;
		top: -95px;
		left: 0px;
		width: 132px;
		@include media('tab') {
			background-size: 100px auto;
			left: 0;
		}
		@include media() {
			background-size: 60px auto;
			left: -40px;
		}
	}
	&__iconleft:after {
		animation: icon01 1s .5s infinite;
		background: url(../img/img_whats-candy06.png) no-repeat center center / 142px 152px;
		height: 152px;
		top: -127px;
		left: 129px;
		width: 142px;
		@include media('tab') {
			background-size: 100px auto;
			left: 100px;
		}
		@include media() {
			background-size: 60px auto;
			left: 20px;
		}
	}
	&__iconRight:before {
		animation: icon01 1s .5s infinite;
		background: url(../img/img_whats-candy07.png) no-repeat center center / 149px 149px;
		height: 149px;
		top: -110px;
		right: 94px;
		width: 149px;
		@include media('tab') {
			background-size: 100px auto;
			right: 70px;
		}
		@include media() {
			background-size: 60px auto;
			right: 10px;
			top: -130px;
		}
	}
	&__iconRight:after {
		animation: icon01 1s 1s infinite;
		background: url(../img/img_whats-candy08.png) no-repeat center center / 149px 149px;
		height: 149px;
		top: -52px;
		right: -52px;
		width: 149px;
		@include media('tab') {
			background-size: 100px auto;
			right: -20px;
		}
		@include media() {
			background-size: 60px auto;
			right: -40px;
			top: -80px;
		}
	}
	&__ttl {
		font-size: 3.4rem;
		font-weight: 700;
		margin-bottom: 36px;
		@include media() {
			font-size: 2rem;
			margin-bottom: 16px;
		}
	}
	&__ttl_color_or {
		color: #ec6c0e;
	}
	&__para{
		font-size: 1.6rem;
		line-height: 1.625;
		margin-bottom: 1.5em;
		text-align: center;
		&:last-of-type{
			margin-bottom: 32px;
		}
		@include media() {
			font-size: 1.3rem;
		}
	}
	&__contents {
		justify-content: space-between;
		margin: 0 auto 105px;
		max-width: 1080px;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media() {
			flex-wrap: wrap;	
			margin: 0 auto 28px;
		}
	}
	&__list {
		background: #fff;
		max-width: 522px;
		@include media('tab') {
			width: calc(50% - 15px);
		}
		@include media() {
			flex-wrap: wrap;
			max-width: none;
			width: 100%;	
		}
	}
	&__itemTtl {
		align-items: center;
		background: url(../img/bg_orange.png) repeat;
		justify-content: center;
		min-height: 78px;
		height: 78px;
		@include media() {
			height: auto;
			min-height: 42px;
		}
	}
	&__subTtl {
		font-size: 2.4rem;
		font-weight: 700;
		@include media() {
			font-size: 1.6rem;
		}
	}
	&__itemTxt {
		background: #fff;
		padding: 32px 40px;
		@include media() {
			padding: 12px 20px 16px;
		}
		@include media('spm') {
			padding: 8px 14px 16px;
		}
	}
	&__itemPara {
		font-size: 1.4rem;
		line-height: 1.857;
		margin-bottom: 28px;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media() {
			font-size: 1.2rem;
			margin-bottom: 12px;
		}
	}
	&__ttl_color_bl {
		color: #00a199;
	}
	&__itemTtl_bg_bl {
		background: url(../img/bg_blue.png) repeat;
	}
	&__headList {
		background: #fff;
		border-radius: 80px;
		height: 55px;
		margin-bottom: 42px;
		@include media() {
			flex-wrap: wrap;
			height: auto;
		}
	}
	&__headItem {
		align-items: center;
		display: flex;
		font-size: 1.4rem;
		justify-content: center;
		position: relative;
		width: 16.6666%;
		a{
			text-decoration: none;
			color: #212121;
			&:hover{
				text-decoration: underline;
			}
		}
		@include media() {
			font-size: 1.2rem;
			padding: 10px 0;
			width: 33.3333%;
		}
		@include media('se') {
			font-size: 1.1rem;
		}
	}
	&__headItem:last-of-type:after {
		content: none;
	}
	&__headItem:nth-of-type(3):after {
		@include media() {
			content: none;
		}
	}
	&__headItem:after {
		content: "";
		background: #dddddd;
		height: 33px;
		position: absolute;
		top: 10px;
		right: 0;
		width: 1px;
		@include media() {
			height: 20px;
		}
	}
	&__contents_type_represent &__list {
		display: flex;
		margin-bottom: 26px;
		max-width: 100%;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media('tab') {
			width: 100%;
		}
	}
	&__contents_type_represent &__itemTtl {
		background: url(../img/bg_blue.png) repeat;
		flex-basis: 223px;
		height: auto;
		width: 223px;
		@include media() {
			flex-basis: 100%;
			width: 100%;
		}
	}
	&__contents_type_represent &__subTtl {
		font-size: 2.2rem;
		@include media() {
			font-size: 1.8rem;
		}
	}
	&__contents_type_represent &__itemTxt {
		max-width: 857px;
		width: 79%;
		@include media() {
			width: 100%;
		}
	}

}

.canDo {
	position: relative;
	z-index: 2;
	@include media() {
		padding:30px 0 35px 0;
	}
	&__inner{
		position: relative;
	}
	&__contents{
		position: relative;
		width: 100%;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 80px;
		@include media('spm') {
			flex-wrap: wrap;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__ttl{
		font-size: 3.2rem;
		font-weight: 700;
		margin-bottom: 28px;
		@include media() {
			font-size: 2rem;
			margin-bottom: 15px;
		}
	}
	&__para{
		font-size: 1.6rem;
		line-height: 1.6;
		margin-bottom: 1.5em;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include media() {
			font-size: 1.3rem;
		}
	}

	&__texts{
		flex-basis: 62.1%;
		max-width: 62.1%;
		background-color: #ffffff;
		padding:43px 52px 26px 112px;
		position: relative;
		left: -50px;
		margin-right: -50px;
		@include media('tab') {
			flex-basis: 68%;
			left: 0;
			max-width: 68%;
			margin-right: -30px;
			padding:30px 50px 30px 30px;
		}
		@include media() {
			flex-basis: 100%;
			max-width: 100%;
			margin-right: 0;
			padding:20px;
		}
	}
	&__contents{
		align-items: center;
	}
	&__image{
		width: 47.5%;
		// max-width: 45.25%;
		position: relative;
		z-index:2;
		img{
			width: 100%;
		}
		@include media('tab') {
			max-width: 42%;
		}
		@include media() {
			width: 100%;
			flex-basis: 100%;
			max-width: 100%;
			position: static;
			margin-bottom: 15px;
			text-align: center;
		}
		@include media('spm') {
			order: -1;
		}
	}
}