@charset "utf-8";


////////////////////
//blog
////////////////////

.blog{
	padding-bottom: 105px;
	@include media(){
		padding-bottom: 60px;
	}
	&__container{
		padding-top: 65px;
		@include media(){
			padding-top: 15px;
		}
	}
	&__ttl{
		margin-bottom: 40px;
		@include media(){
			margin-bottom: 30px;
			font-size: 1.6rem;
		}
	}
	&List{
		max-width: 850px;
		margin-left: auto;
		margin-right: auto;
		@include media() {
			// padding-left: 15px;
		}
		&__item{
			margin-bottom: 16px;
			@include media() {
				margin-bottom: 10px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&__link{
			background-color: #ffffff;
			text-decoration: none;
			color: #212121;
			box-shadow:0px 0px 6px 0px rgba(0,0,0,.05);
			align-items: unset;
			border-radius: 4px;
			@include media() {
			}
			&:hover{
				box-shadow:0px 0px 12px 0px rgba(0,0,0,.3);
			}
		}
		&__thumb{
			flex: 0 0 247px;
			max-width: 247px;
			@include media() {
				flex: 0 0 100px;
				max-width: 100px;
			}

			img{
				border-radius: 4px 0 0 4px;
				@include media(){
					border-radius: 4px 0 0 4px;
					width: 100%;
					height: 82px;
					object-fit: cover;
				}
			}
		}
		&__texts{
			padding: 26px 68px 26px 27px;
			width:100%;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			position: relative;
			&:after{
				content: "";
				display: block;
				background: url(../img/icon_arrow_or.svg) no-repeat center center / cover;
				width: 7px;
				height: 14px;
				position: absolute;
				top: calc(50% - 7px);
				right: 20px;
				@include media() {
					width: 6px;
					height: 12px;
					top: calc(50% - 6px);
					right: 13px;
				}
			}
			@include media('tab') {
				flex-basis: calc(100% - 284px);
			}
			@include media() {
				flex-basis: calc(100% - 100px);
				padding:10px 30px 10px 13px;
			}
		}
		&__status{
			width: 100%;
			display: flex;
			align-items: center;
			padding-bottom: 18px;
			border-bottom: 1px solid #f7f8f8;
			margin-bottom: 10px;
			@include media() {
				padding-bottom: 5px;
				margin-bottom: 0;
				border-bottom: none;
			}
		}
		&__date{
			font-size: 1.4rem;
			display: block;
			padding-left: 1em;

			@include media() {
				font-size: 1.1rem;
			}
		}
		&__head{
			font-size: 1.6rem;
			@include media() {
				font-size: 1.2rem;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
		&__cat{
			font-size: 1.2rem;
			background-color: #f3a56c;
			color: #fff;
			padding:0 14px;
			height: 24px;
			border-radius: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			&_planning{
				background-color: #76ccc8;
			}
			&_study{
				background-color: #8e9ea9;
			}
			@include media() {
				font-size: 1.0rem;
				padding:1px 15px;
				height: 20px;
			}
		}
	}
	&__article{
		background-color: #fff;
		padding:47px 50px;
		margin-top: 70px;
		@include media() {
			margin-top: 0;
			padding:30px 15px 45px 15px;
			width: calc(100% - 30px)
		}
	}
	&__bt{
		margin: 45px auto -10px auto;
		@include media() {
			margin-top: 30px;
		}
	}
}



.blog-section{
	padding-bottom: 100px;
	@include media() {
		padding-bottom: 60px;
	}
}

.blogList{
	
}