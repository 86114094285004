@charset "utf-8";

////////////////////
//error-page
////////////////////
.errorPage{
	padding-bottom: 105px;
	@include media(){
		padding-bottom: 60px;
	}
	&__container{
		padding-top: 70px;
		@include media(){
			padding-top: 0;
		}
	}
	&__ttl{
		font-size: 3.6rem;
		font-weight: 600;
		margin-bottom: 36px;
		@include media(){
			font-size: 2.0rem;
			margin-bottom: 30px;
			margin-bottom: 20px;
		}
	}
	&__desc{
		font-size: 1.6rem;
		text-align: center;
		line-height: 1.75;
		margin-bottom: 50px;

		@include media(){
			font-size: 1.3rem;
			margin-bottom: 25px;
		}
	}
	&__bt{
		max-width:324px;
		margin-right: auto;
		margin-left: auto;

		@include media(){
			width:100%;
		}
	}
}