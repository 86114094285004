@charset "utf-8";
.performance{
    padding-bottom: 105px;
    @include media(){
        padding-bottom: 30px;
    }
    &__inner{
        padding-top: 65px;
        @include media(){
            padding-top: 0;
        }
    }
    &__ttl{
        margin-bottom: 50px;
        @include media(){
            margin-bottom: 20px;
        }
    }
    &__list{
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    &__item{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 35px;
        position: relative;
        @include media(){
            margin-bottom: 20px;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    &__image{
        width: 412px;
        flex-basis: 412px;
        max-width: 412px;
        padding: 75px 30px 40px 30px;
        text-align: center;
        background-color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        @include media('tab'){
            padding: 30px;
            width: 40%;
            flex-basis: 40%;
            max-width: 40%;
            padding: 30px;
        }
        @include media(){
            width: 100%;
            flex-basis: 100%;
            max-width: 100%;
            padding: 30px 20px 0 20px;
            padding-bottom:0;
            position: static;
        }
        img{
            width: 210px;
            @include media(){
                max-width: 160px;
            }
        }
        &:after{
            content: "";
            background: url(../img/img_performance_shadow.png) no-repeat bottom center / 100% auto;
            width: 220px;
            height: 32px;
            display: block;
            position: relative;
            margin: 40px auto 0 auto;
            @include media(){
                margin-top: 15px;
            }
        }
    }
    &__text{
        width: 582px;
        flex-basis: 582px;
        max-width: 582px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        @include media('tab'){
            width: calc(60% - 5px);
            flex-basis: calc(60% - 5px);
            max-width: calc(60% - 5px);
        }
        @include media(){
            width: 100%;
            flex-basis: 100%;
            max-width: 100%;
        }
    }
    
    &__name{
        font-size: 2.4rem;
        font-weight: 700;
        background: url(../img/bg_pt_or03.png);
        line-height: 1;
        padding: 29px 30px;
        @include media(){
            font-size: 1.6rem;
            padding: 10px 15px;
        }
    }
    &__detail{
        padding: 35px 40px 40px 40px;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        @include media('tab'){
            padding: 20px;
        }
    }
    &__desc{
        font-size: 1.6rem;
        margin-bottom: 32px;
        @include media('tab'){
            margin-bottom: 30px;
        }
        @include media(){
            font-size: 1.3rem;
            margin-bottom: 15px;
        }
    }
    &__table{
        width: 100%;
        tr{}
        th{
            background-color: #f7f8f8;
            padding: 17px 23px;
            border: 1px solid #efefef;
            text-align: left;
            @include media(){
                font-size: 1.2rem;
                padding: 12px 15px;
            }
        }
        td{
            padding: 17px 23px;
            border: 1px solid #efefef;
            @include media(){
                font-size: 1.2rem;
                padding: 12px 15px;
            }
        }
    }
    &__bt{
        font-size: 1.6rem;
        max-width: 100%;
        margin-top: 20px;
        width: 100%;
    }
}