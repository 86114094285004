@charset "utf-8";

////////////////////
//contact
////////////////////
.contact{
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 64px;
	padding-bottom: 100px;
	@include media() {
		padding-top: 0;
		padding-bottom: 30px;
	}
	&__headline{
		font-size: 2.4rem;
		font-weight: 600;
		text-align: center;
		line-height: 1.75;
		margin-bottom: 42px;
		@include media() {
			font-size: 1.8rem;
			line-height: 1.6;
			margin-bottom: 25px;
		}
	}
	&__form{
		&_page{
			&_confirm{
				.contact__term{
					&:after{
						display: none;
					}
				}
				.contact__caution-item{
					display: none;
				}
				.contact__caution{
					display: none;
				}
			}
		}
	}
	&__ttl{
		margin-bottom: 52px;
		@include media() {
			margin-bottom: 30px;
		}
	}
	&__scTtl{
		font-size: 2.2rem;
		font-weight: 700;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 23px;
		&:before{
			display: block;
			margin-right: 10px;
			@include media() {
				margin-right: 5px;
			}
		}
		@include media() {
			font-size: 1.6rem;
			margin-bottom: 15px;
		}
		&_type{
			&_tel{
				&:before{
					content:"";
					background: url(../img/icon_tel.svg) no-repeat center center / 43.5px 43.5px;
					width: 43.5px;
					height: 43.5px;
					@include media() {
						background: url(../img/icon_tel.svg) no-repeat center center / 30px 30px;
						width: 30px;
						height: 30px;
					}
				}
			}
			&_mail{
				margin-bottom: 17px;
				&:before{
					content:"";
					background: url(../img/icon_mail.svg) no-repeat center center / 43.5px 43.5px;
					width: 43.5px;
					height: 43.5px;
					@include media() {
						background: url(../img/icon_mail.svg) no-repeat center center / 30px 30px;
						width: 30px;
						height: 30px;
					}
				}
			}
		}
	}
	&__telNum{
		font-size: 4.4rem;
		font-weight: 700;
		text-align: center;
		margin-bottom: 20px;
		line-height: 1;
		@include media() {
			font-size: 3.2rem;
			margin-bottom: 15px;
		}
		a{
			color:#212121;
			text-decoration: none;
			line-height: 1;
		}
	}
	&__inner{
		background-color: #fff;
		&_type{
			&_tel{
				padding: 45px 40px 37px 40px;
				margin-bottom: 35px;
				
				@include media() {
					padding:20px;
				}
			}
			&_form{
				padding:45px 50px 80px 45px;
				@include media() {
					padding: 20px;
				}
			}
		}
	}
	&__innerPara{
		font-size: 1.4rem;
		text-align: center;
		@include media() {
			font-size: 1.2rem;
		}
	}
	&__scTtl{
	}
	&__para{}
	&__stList{
		margin-top:32px;
		margin-bottom: 50px;
		@include media('tab') {
			margin-right: -10px;
			margin-left: -10px;
		}
		@include media() {
			margin-bottom: 30px;
			margin-top:15px;
			margin-left: -5px;
			margin-right: -5px;
		}
	}
	&__stItem{
		font-size: 1.4rem;
		font-weight: 700;
		text-align: center;
		width:240px;
		padding:10px 0;
		background-color: #D7D7D7;
		margin:0 5px;
		@include media('tab') {
			width: calc(33.3333% - 10px);
		}
		@include media() {
			font-size: 1.2rem;
			width: calc(33.3333% - 10px);
		}
		&_current{
			background-color: #ee7c28;
			color: #fff;
		}
	}
	&__section{
		&_type{
			&_body{
				border-top: 1px solid #d7d7d7;
				margin-bottom: 43px;
				@include media() {
					margin-bottom: 30px;
				}
			}
			&_info{
				padding-bottom: 80px;
				@include media() {
					padding-bottom: 30px;
				}
			}
		}
	}
	&__subttl{
		font-size: 2.0rem;
		font-weight: 700;
		background: url(../img/bg_bl_pt01.png) repeat center center/2px 2px;
		line-height: 1;
		padding: 15px;
		text-align: center;
		@include media() {
			font-size: 1.6rem;
			padding: 12px;
		}
	}
	&__form-tab{
		display: none;
	}
	&__list{
		border-bottom: 1px solid #D7D7D7;
		padding:20px 0;
		display: flex;
		align-items: center;
		width:100%;
		@include media() {
			padding:15px 0;
			flex-wrap: wrap;
		}
	}
	&__term{
		width:260px;
		font-size: 1.6rem;
		font-weight: 700;
		margin-right: 35px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include media('tab') {
			width: 35%;
		}
		@include media() {
			font-size: 1.3rem;
			width: 100%;
			justify-content: flex-start;
			margin-bottom: 10px;
		}
		&:after{
			font-size: 1.2rem;
			width:43px;
			height: 24px;
			text-align: center;
			color:#ffffff;
			display: block;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media() {
				font-size: 1.0rem;
				width:32px;
				height: 16px;
				margin-left: 10px;
			}
		}
		&_type{
			&_required{
				&:after{
					content:"必須";
					background-color: #ea5514;
				}
			}
			&_any{
				&:after{
					content:"任意";
					background-color: #898989;
				}
			}
		}
	}
	&__detail{
		width:calc(100% - 295px);
		@include media() {
			width: 100%;
		}
		input{
			background-color: #FAFAFA;
			font-size: 1.4rem;
			padding:11.5px 15px;
			border:1px solid #D7D7D7;
			max-width: 100%;
			box-sizing: border-box;
			@include media() {
				font-size: 1.2rem;
				padding:10px;
			}
			&.roText{
				border:none;
				outline: none;
				padding:0;
				background: none;
			}
		}
		textarea{
			width:100%;
			background-color: #FAFAFA;
			font-size: 1.4rem;
			padding:11.5px 15px;
			border:1px solid #D7D7D7;
			box-sizing: border-box;
			height: 190px;
			@include media() {
				font-size: 1.2rem;
				padding:10px;
				height: 120px;
			}
		}
		select{
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			background: transparent;
			border-radius: 0;
			padding:11.5px 15px;
			background-color: #FAFAFA;
			font-size: 1.4rem;
			padding:8.5px 15px;
			border:1px solid #D7D7D7;
			line-height: 1.5;
			box-sizing: border-box;
			width: 100%;
			color: #212121;
			@include media() {
				font-size: 1.2rem;
				padding:10px;
			}
			&::-ms-expand {
    			display: none;
    		}
		}
		input::placeholder, select::placeholder {
			color: #A0A0A0;
		}
		option{}
		&_type_code{
			.flb{
				align-items: center;
			}
			.contact__input_type_code{
				width: 286px;
				@include media() {
					width:200px;
				}
			}
		}
		&_type_tel{
			input{
				width:371px;
				@include media() {
					width:100%;
				}
			}
		}
	}
	&__detail-sp{
		display: inline-block;
		font-size: 1.4rem;
		padding-left: 10px;
		padding-right: 13px;
		@include media() {
			font-size: 1.2rem;
			padding-left: 5px;
			padding-right: 8px;
		}
	}
	&__input{
		width: 100%;
		&_type_year{
			width: 100px;
			@include media() {
				width: 70px;
			}
		}
		&_type_month{
			width: 60px;
			@include media() {
				width: 45px;
			}
		}
		&_type_day{
			width: 60px;
			@include media() {
				width: 45px;
			}
		}
	}
	&__labels{
		width: 286px;
		display: block;
		position: relative;
		br{
			display: none;
		}
		@include media() {
			width: 200px;
		}
		&:after{
			content: "";
			display: block;
			background: url(../img/icon_tb_arrow.svg) no-repeat center center / 6px 20px;
			width: 6px;
			height: 20px;
			background-size: cover;
			position: absolute;
			right: 12px;
			top: calc(50% - 10px);
		}
	}
	
	&__caution-item{
		font-size: 1.4rem;
		margin-top:7px;
		@include media() {
			font-size: 1.2rem;
			margin-top:5px;
		}
	}
	&__caution{
		font-size: 1.4rem;
		margin-top:47px;
		text-align: center;
		@include media() {
			font-size: 1.2rem;
			margin-top:25px;
			text-align: left;
		}
		a{
			text-decoration: underline;
			color:#212121;
			&:hover{
				text-decoration: none;
			}
		}
	}
	&__button{
		width:376px;
		background-color: #ee7c28;
		font-size: 1.6rem;
		text-align: center;
		border:1px solid #ee7c28;
		color:#ffffff;
		padding:22px 0;
		font-weight: 700;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		display: block;
		margin-top:21px;
		cursor: pointer;
		@include media() {
			width: 100%;
			font-size: 1.3rem;
    		padding: 18.5px 0;
		}
		
		&:hover{
			opacity: .75;
		}
		&:after{
			content: "";
			width: 8px;
			height: 14px;
			background: url(../img/arrow_icon_wh.svg) no-repeat center center /8px 14px;
			display: block;
			position: absolute;
			right:25px;
			top:calc(50% - 7px);
			
			@include media() {
				content: "";
				display: block;
				width: 8px;
				height: 13.4px;
				background: url(../img/arrow_icon_wh.svg) no-repeat center center /cover;
				right: 20px;
				top: calc(50% - 6.7px);
				position: absolute;
			}
		}
		&_type{
			&_buck{
				background-color: #1e1e1e;
				border-color: #1e1e1e;
				&:after{
					background: url(../img/arrow_icon_wh_left.svg) no-repeat center center/8px 14px;
					right: auto;
					left: 25px;
					@include media() {
						background: url(../img/arrow_icon_wh_left.svg) no-repeat center center/cover;
					}
				}
			}
		}
	}
	&__btList{
		display: none;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 50px;
		@include media() {
			margin-top: 20px;
		}
	}
	&__btItem{
		padding: 0 7.5px;
		@include media() {
			width: 100%;
			padding: 0;
			&:first-of-type{
				margin-bottom: 10px;
			}
		}
		.contact__button{
			width: 280px;
			margin:0;
			@include media() {
				margin:0;
				width: 100%;
			}
		}
	}
}

.radios{
	
	&__list{}
	&__item{
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.mwform-radio-field{
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&__lab{
		position: relative;
	}
	label{
		position: relative;
		br{
			display: none;
		}
	}
	&__inp {
		display: none;
		&:checked + .radios__texts::after{
			content: "";
			display: block;
			position: absolute;
			top: calc(50% - 7px);
			left: 6px;
			width: 14px;
			height: 14px;
			background: #323232;
			border-radius: 50%;
			@include media() {
				width: 8px;
				height: 8px;
				top: calc(50% - 4px);
				left: 5px;
			}
		}

		&:checked + .mwform-radio-field-text::after{
			content: "";
			display: block;
			position: absolute;
			top: calc(50% - 7px);
			left: 6px;
			width: 14px;
			height: 14px;
			background: #323232;
			border-radius: 50%;
			@include media() {
				width: 8px;
				height: 8px;
				top: calc(50% - 4px);
				left: 5px;
			}
		}
	}
	&__texts {
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		position: relative;
		@include media() {
			font-size: 1.3rem;
		}
		&:before{
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			border: 1px solid #323232;
			border-radius: 50%;
			margin-right: 10px;
			@include media() {
				width: 16px;
				height: 16px;
			}
		}
	}

	.mwform-radio-field-text {
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		position: relative;
		@include media() {
			font-size: 1.3rem;
		}
		&:before{
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			border: 1px solid #323232;
			border-radius: 50%;
			margin-right: 10px;
			@include media() {
				width: 16px;
				height: 16px;
			}
		}
	}
	&_type{
		&_col{
			display: flex;
			.radios__item{
				padding-right: 40px;
				margin-bottom: 0;
				@include media() {
					padding-right: 20px;
				}
			}
			.mwform-radio-field{
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				padding-right: 40px;
				margin-bottom: 0;
				@include media() {
					padding-right: 20px;
				}
			}
		}
	}
}
////////////////////
//確認画面
////////////////////
.mw_wp_form_confirm{
	.contact__term{
		&:after{
			display: none;
		}
	}
	.contact__caution-item{
		display: none;
	}
	.contact__caution{
		display: none;
	}
	.contact__labels:after{
		display: none;
	}
	.contact__btList{
		display: flex;
	}
}

.thanks{
	&__ttl{
		font-size: 2.0rem;
		font-weight: 600;
		text-align: center;
		margin-bottom: 32px;
		@include media() {
			font-size: 1.6rem;
			margin-bottom: 15px;
		}
	}
	&__para{
		font-size: 1.4rem;
		margin-bottom: 1.5em;
		@include media() {
			font-size: 1.2rem;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
	}

	&__bt{
		margin-top: 46px;
		margin-left: auto;
		margin-right: auto;
		@include media() {
			margin-top: 25px;
		}
	}
}