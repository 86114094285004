////////////////////
// var
////////////////////
$m-color : #E49E32;

////////////////////
// mixin
////////////////////

$responsive: (
  'se': 'screen and (max-width: 321px)',
	'spm': 'screen and (max-width: 480px)',
	'sp': 'screen and (max-width: 767px)',
	'tab': 'screen and (max-width: 1080px)',
	'otab': 'screen and (min-width: 1080px)',
	'lpc': 'screen and (max-width: 1170px)',
	'pc': 'screen and (min-width: 1200px)',
) !default;

@mixin media($breakpoint: sp) {
	@media #{map-get($responsive, $breakpoint)} {
		@content;
	}
}