////////////////////////////////////////
//tags
////////////////////////////////////////
img{
	max-width: 100%;
	vertical-align: bottom;
}

html{
	font-size: 62.5%;
}

body{
	color:#212121;
	font-size: 1.4rem;
	&.fix{
		@include media('tab') {
			position: fixed;
		}
	}
}

////////////////////////////////////////
//bg
////////////////////////////////////////
.bg{
	&_color{
		&_gr{
			background-color: #f7f8f8;
		}
	}
}

////////////////////////////////////////
//layouts
////////////////////////////////////////
.wrap{
	width:100%;
	max-width: 1080px;
	margin-left: auto;
	margin-right: auto;
	box-sizing:border-box;

	@include media('tab') {
		max-width: 100%;
		padding-left: 30px;
		padding-right: 30px;
	}
	@include media('sp') {
		padding: 0 20px;
	}
}

.flb{
	display: flex;
	&_type{
		&_t{
			@extend .flb;
			align-items: flex-start;
			&l{
				@extend .flb_type_t;
				justify-content: flex-start;
			}
			&c{
				@extend .flb_type_t;
				justify-content: center;
			}
			&r{
				@extend .flb_type_t;
				justify-content: flex-end;
			}
		}
		&_c{
			@extend .flb;
			align-items: center;
			&l{
				@extend .flb_type_c;
				justify-content: flex-start;
			}
			&c{
				@extend .flb_type_c;
				justify-content: center;
			}
			&r{
				@extend .flb_type_c;
				justify-content: flex-end;
			}
			&b{
				@extend .flb_type_c;
				justify-content: space-between;
			}
		}
		&_b{
			@extend .flb;
			align-items: flex-end;
			&l{
				@extend .flb_type_b;
				justify-content: flex-start;
			}
			&c{
				@extend .flb_type_b;
				justify-content: center;
			}
			&r{
				@extend .flb_type_b;
				justify-content: flex-end;
			}
		}
	}
}

////////////////////////////////////////
//lPage
////////////////////////////////////////

.lPage{
	&__ttl{
		font-size: 2.4rem;
		font-weight: 800;
		line-height: 1.75;
		text-align: center;
	}
}

////////////////////////////////////////
//common
////////////////////////////////////////
.spbr{
	display: none;
	@include media('tab') {
		display: block;
	}
}

.pcbr{
	@include media('sp') {
		display: none;
	}
}


////////////////////////////////////////
//header
////////////////////////////////////////
.header{
	position: relative;
	z-index: 3;
	width:100vw;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	&_page_lp{
		@include media('tab'){
			.header__desc{
				flex-wrap: wrap;
			}
		}
	}
	&.scrolled{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		@include media('otab'){
			&.header_page_lp{
				.header__desc{
					width: 100%;
					flex-basis: 100%;
					max-width: 100%;
					padding-top: 17px;
					padding-bottom: 17px;
					flex-wrap: wrap;
				}
				.header__logo{
					width: 240px;
					max-width: 240px;
					flex-basis: 240px;
				}
				.gnavi__link{
					border:none;
				}
			}
			.header__desc{
				width: 240px;
				max-width: 240px;
				flex-basis: 240px;
			}
			.header__logo{
				width: 100%;
				flex-basis: 100%;
				max-width: 100%;
			}
			.header__cap{
				display: none;
			}
			.header__topList{
				display: none;
			}
			.gnavi{
				max-width: 1080px;
				width: calc(100% - 240px);
				margin-left: auto;
				&_page_lp{
					width: auto;
					max-width: auto;
				}
			}
			.gnavi__list{
				height: 100%;
			}
			.gnavi__item{
				&_type_contact{
					.gnavi__link{
						background-color: #ec6c0e;
					}
					
				}
			}
			.gnavi__link{
				font-size: 1.5rem;
				letter-spacing: -.05em;
				height: 100%;
				background-color: transparent;
				border-color: #f3f3f3;
				
			}
			.mm__inner{
				top: 51px;
			}
		}
	}
	
	&__desc{
		flex: 0 0 100%;
		max-width: 100%;
		padding:24px 25px 24px 25px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		@include media('tab') {
			flex: 0 0 100%;
			max-width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding:0 0 0 20px;
		}

		@include media() {
			padding: 0 0 0 0;
		}
	}
	&__logo{
		flex: 0 0 325px;
		max-width: 325px;
		font-size: 0;
		line-height: 1;
		margin-top: -8px;
		margin-right: 20px;

		&_page{
			&_lp{
				flex: 0 0 252px;
				max-width: 252px;
			}
		}

		a{
			&:hover{
				opacity: .75;
			}
		}

		@include media() {
			flex: 0 0 45vw;
			width: 45vw;
			max-width: 230px;
			padding-left: 10px;
		}
	}
	&__cap{
		font-size: 1.2rem;
		line-height: 1.6;
		position: relative;
		top:2px;
		@include media('lpc') {
			display: none;
		}
	}
	&__topList{
		margin-left: auto;
		display: flex;
		align-items: center;
		@include media('tab') {
			display: none;
		}
	}
	&__topItem{
		margin-left: 30px;
	}
	&__topLink{
		font-size: 1.2rem;
		text-decoration: none;
		color:#212121;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		&:hover{
			opacity: .75;
		}
		&:before{
			content:"";
			background: url(../img/icon_bg_arrow.svg) no-repeat center center/cover;
			width: 14px;
			height: 14px;
			display: block;
			margin-right: 5px;
		}
	}
	&__lang{
		display: flex;
		align-items: center;
	}
	&__lang-term{
		font-size: 1.2rem;
		margin-right: 10px;
	}
	&__lang-detail{}
	&__lang-label{
		position: relative;
		&:after{
			content:"";
			display: block;
			background: url(../img/icon_arrow_wh_bottom.svg) no-repeat center center / 10px 6.7px;
			width:10px;
			height: 6.7px;
			position: absolute;
			right:8px;
			top: calc(50% - 3.35px);
		}
	}
	&__lang-select{
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background:none;
		border:none;
		box-shadow: none;
		background-color: #212121;
		border-radius: 4px;
		font-size: 1.4rem;
		color: #ffffff;
		width: 110px;
		padding:5px 8px 5px 10px;
		&::-ms-expand {
			display: none;
		}
	}
	&.active{
		@include media('lpc') {
			overflow: scroll;
			.gnavi{
				display: block;
				position: static;
				width:100vw;
				height: calc(100vh - 80px);
				z-index: 3;
				top:80px;
				overflow: scroll;
				background-color: #fff;
				@include media() {
					height: calc(100vh - 55px);
					padding-bottom: 70px;
				}
				
				&__list{
					position: relative;
					z-index: 4;
					padding-left: 45px;
					padding-right: 45px;
					flex-wrap: wrap;
					height: 100%;
					
					overflow: scroll;

					@include media() {
						padding-left: 31px;
						padding-right: 31px;
					}
				}
				&__item{
					flex: 0 0 100%;
					max-width: 100%;
					margin-bottom: 0;
					order:2;

					// &_page{
					// 	&_karte{
					// 		display: none;
					// 	}
					// 	&_blog{
					// 		display: none;
					// 	}
					// }

					&_type_contact{
						padding:30px 0 0 0;
						@include media() {
							padding:16px 0 0 0;
						}
						margin-bottom: 0;
						order:1;
						.gnavi__link{
							background-color: #ec6c0e;
							text-align: center;
							display: block;
							color: #ffffff;
							border-radius: 30px;
							position: relative;
							@include media() {
								padding:13px 0;
							}
							&:before{
								display: none;
							}
							&:after{
								content: "";
								display: block;
								background: url(../img/arrow_icon_wh.svg) no-repeat center center/6px 12px;
								width: 6px;
								height: 12px;
								position: absolute;
								top: calc(50% - 6px);
								right: 11px;
							}
						}
					}
					&--tw{
						display: none;
						@include media('tab') {
							display: flex;
						}
						margin-top:15px;
						order:3;
						width: 35px;
						height: 35px;
						text-align: left;
						img{
							width: 35px;
							height: 35px;
						}
					}
					&.mm{
						.gnavi__link{
							justify-content: flex-start;
							position: relative;
							// &:after{
							// 	content:"";
							// 	width:12px;
							// 	height: 2px;
							// 	background: #212121;
							// 	background-image: none;
							// 	position: absolute;
							// 	right:11px;
							// 	top:calc(50% - 1px);
							// }
							&:before{
								
							}
							&.active{
								
							}
						}
						.mm__nav{
							flex-wrap: wrap;
							padding: 0;
						}
						.mm__bgInner{
							flex-wrap: wrap;
							padding:0;
						}
						.mm__inner{
							position: static;
							margin-left: 0;
							margin-right: 0;
							width:100%;
							background: none;
						}
						.mm__desc{
							display: none;
						}
						.mm__arrow{
							&List{
								order: 2;
								width: 100%;
							}
							&Item{}
							&Link{
								font-weight: 600;
								padding: 12px;
								display: block;
								border-bottom: 1px solid #f3f3f3;
								position: relative;
								padding-left: 1em;
								line-height: 1.5;
								font-size: 1.1rem;
								text-align: left;
								&:after{
									content: "";
									background: url(../img/icon_arrow_or.svg) no-repeat center center/6px 12px;
									width: 6px;
									height: 12px;
									position: absolute;
									top: calc(50% - 6px);
									right: 15px;
									opacity: 1;
									left: auto;
								}
								&:before{
									display: none;
								}
							}
						}
						.mm__btDl{
							padding:0;
							background-color: transparent;
						}
						.mm__btList{
							flex: 0 0 100%;
							max-width: 100%;
							padding:0;
							margin:0;
							padding:0;
							order: 1;
							.mm__btItem{
								margin-bottom: 0px;
								flex: 0 0 100%;
								max-width: 100%;
								padding:0;
								a{
									font-weight: 600;
									padding: 12px;
									display: block;
									border-bottom: 1px solid #f3f3f3;
									color: #ec6c0e;
									position: relative;
									padding-left: 1em;
									font-size: 1.1rem;
									&:after{
										content: "";
										background: url(../img/icon_arrow_or.svg) no-repeat center center/6px 12px;
										width: 6px;
										height: 12px;
										position: absolute;
										top: calc(50% - 6px);
										right: 15px;
										opacity: 1;
										left: auto;
									}
								}
								&:last-of-type{
									margin-bottom: 0;
								}
							}
							.mm__btLink{
								font-size: 1.3rem;
								font-weight: 700;
								padding:15px;
							}
						}
						.mm__btTerm{
							display: none;
						}
						.mm__btLink{
							display: none;
						}
						.mm__btDetail{
							flex-basis: 100%;
							width: 100%;
							max-width: 100%;
							padding:0;
						}
						.mm__btname{
							font-size: 1.1rem;
							margin-bottom:0;
						}

					}
				}
				&__link{
					background-color: transparent;
					border:none;
					border-bottom: 1px solid #f3f3f3;
					font-size: 1.6rem;
					padding:17px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: #212121;
					position: relative;
					@include media(){
						font-size: 1.3rem;
						font-weight: 600;
						padding:15px 0;
					}
					
					&:after{
						content:"";
						background: url(../img/icon_arrow_or.svg) no-repeat center center/cover;
						width: 7px;
						height: 14px;
						position: absolute;
						top: calc(50% - 7px);
						right: 0;
						opacity: 1;
						left: auto;
					}
					&_tri{
						&:before{
							content: "";
							width: 2px;
							height: 12px;
							right: 5px;
							top: calc(50% - 6px);
							position: absolute;
							background: none;
							background-color: #ec6c0e;
							opacity: 1;
							
						}
						&:after{
							content: "";
							width: 12px;
							height: 2px;
							background: #ec6c0e;
							background-image: none;
							position: absolute;
							right: 0px;
							top: calc(50% - 1px);
						}
						&.active{
							&:before{
								opacity: 0;
							}
						}
					}
				}
			}
			.mm{
				&__inner{
					
				}
			}
			.navBt{
				background: #00a199;
				position: relative;
				z-index: 4;
				span{
					width:100%;
					height: 2px;
					background: #ffffff;
					display: block;
					position: absolute;
					left: 0;
					&:nth-of-type(1){
						top:calc(50% - 1px);
						transform: rotate(315deg);
					}
					&:nth-of-type(2){
						display: none;
					}
					&:nth-of-type(3){
						top:calc(50% - 1px);
						transform: rotate(-315deg);
					}
				}
			}
		}
	}
}
.gnavi{
	color: #000;
	width: 100%;
	@include media('tab'){
		border-top:none;
		display: none;
	}
	&_page{
		&_lp{
			@include media('otab'){
				margin-left: auto;
				width: auto;
				.gnavi__list{
					align-items: center;
				}
				.gnavi__item{
					width: auto;
					flex-basis: auto;
					max-width: none;
					display: flex;
					&:before{
						content:"｜";
						padding:0 1em;
						display: block;
					}
					&:first-of-type{
						&:before{
							display: none;
						}
					}
					&_type{
						&_sp{
							display: none;
							&:before{
								display: none;
							}
						}
						&_contact{
							margin-left: 25px;
							&:before{
								display: none;
							}
							.gnavi__link{
								width: 194px;
								padding: 16px 0;
								border-radius: 50px;
							}
							
						}
					}
				}
				.gnavi__link{
					padding:0;
					background: none;
					font-size: 1.4rem;
					z-index: 5;
					&:after{
						bottom: -10px;
					}
					
					&_tri{
						&:before{
							content: "";
							position: absolute;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 12px 18px 12px;
							border-color: transparent transparent #00a199 transparent;
							opacity: 1;
							display: block;
							top: 36px;
							left: calc(50% - 12px);
							opacity: 0;
						}
					}
				}
				.mm{
					&:hover{
						.gnavi__link{
							&:before , &:after{
								opacity: 1;
							}
						}
					}
					&__inner{
						top: 60px;
						padding-top: 32px;
						background-color: transparent !important;
					}
					&__contents{
						background-color: #fff;
					}
				}
			}	
		}
	}
	&__list{
		@include media('sp'){
			overflow: scroll;
			height: calc(100vh + -70px);
			align-content: flex-start;
		}
	}
	&__item{
		flex: 0 0 20%;
		max-width: 20%;
		text-align: center;
		&_type_contact{
			.gnavi__link{
				border-bottom-color: #E30ec6c0e07F;
				background-color: #ec6c0e;
				color: #ffffff;
				display: flex;
				justify-content: center;
				&:hover{
					opacity: .75;
					&:after{
						opacity: 0;
					}
				}
				&:before{
					// content:"";
					// width:21px;
					// height: 16px;
					// background: url(../img/mail_icon.svg) no-repeat center center /cover;
					// display: block;
					// margin-right: 10px;
				}
			}
		}
		&_type{
			&_sp{
				display: none;
				@include media('tab'){
					display: block;
				}
			}
			
		}

	}
	&__link{
		font-size: 1.6rem;
		font-weight: 700;
		color:#212121;
		background-color: #f3f3f3;
		text-decoration: none;
		padding:26px 0px 25px 0px;
		display: block;
		border-left: 1px solid #fff;
		letter-spacing: -.03em;
		position: relative;
		&_page{
			&_lp{
				background: none;
				font-size: 1.4rem;
				padding:0;
				border-left: none;
			}
		}
		&:after{
			content: "";
			width: 100%;
			height: 4px;
			position: absolute;
			left:0;
			bottom: 0;
			display: block;
			background-color: #00a199;
			opacity: 0;
		}
		&:hover{
			&:after{
				opacity: 1;
			}
		}
	}
	.mm{
		.gnavi__link{
			&:hover{
				@include media('tab') {
					background-color: #ffffff;
				}
			}
		}
		&:hover{
			@media screen and (min-width:1080px) {
				.mm__inner{
					display: block;
				}
			}
		}
		&__inner{
			display: none;
			position: absolute;
			width: 100vw;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			background-color: #ffffff;
			z-index: 3;
			margin-bottom: -5px;
		}
		&__desc{
			background-color: #00a199;
			padding:15px 0;
		}
		&__ttl{
			font-size: 2.6rem;
			font-weight: 800;;
			margin-right: 30px;
			color: #fff;
		}
		&__para{
			font-size: 1.4rem;
			color: #fff;
		}
		&__nav{
			padding: 36px 0 42px;
			justify-content: space-between;
			flex-wrap: nowrap;
			background-color: #ffffff;
		}
		&__arrowList{}
		&__arrowItem{
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&__arrowLink{
			font-size: 1.4rem;
			font-weight: 700;
			color:#212121;
			text-decoration: none;
			line-height: 2.25;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			&:before{
				content:"";
				background: url("../img/icon_arrow_02.svg") no-repeat center center / cover;
				width:14px;
				height: 14px;
				display: block;
				margin-right: 10px;
			}
			&:hover{
				text-decoration: underline;
			}
		}
		&__btList{
			flex: 0 0 855px;
			max-width: 855px;
			margin-left: auto;
			margin-right: -2px;
			flex-wrap: wrap;
		}
		&__btItem{
			flex: 0 0 285px;
			max-width: 285px;
			padding: 0 2px;
			a{
				color: #212121;
				text-decoration: none;
				&:hover{
					.mm__btLink{
						opacity: .75;
					}
				}
			}
			&_type_sp{
				display: none;
				@include media('tab') {
					display: block;
				}
			}
			&_type_dev{
				@include media('sp') {
					display: none;
				}
			}
		}
		&__btDl{
			background-color: #f2f2f5;
			padding:27px 17px 27px 10px;
		}
		&__btTerm{
			flex-basis: 80px;
			width: 80px;
			max-width: 80px;
		}
		&__btDetail{
			flex-basis: calc(100% - 80px);
			width: calc(100% - 80px);
			max-width: calc(100% - 80px);
			padding-left: 10px;
			text-align: left;
		}
		&__btname{
			font-size: 1.6rem;
			font-weight: 600;
			letter-spacing: -.08em;
			margin-bottom: 7px;
		}
		&__btLink{}

		&__btLink{
			background-color: #fff;
			display: block;
			font-size: 1.2rem;
			font-weight: 600;
			line-height: 1;
			text-decoration: none;
			padding:10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: inherit;
			&--wh{
				background-color: #ffffff;
			}
			&--dev{
				background-color: #FFE106;
			}
			&:after{
				content:"";
				background: url("../img/arrow_icon_bl.svg") no-repeat center center / cover;
				width:4.8px;
				height: 8px;
				display: block;
			}
			&:hover{
			}
		}
	}
	.mm__btItem_type_dev{
		@include media('tab') {
			display: none !important;
		}
	}
}
.navBt{
	display: none;
	background: #ec6c0e;
	@include media('tab') {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 80px;
		max-width: 80px;
		height: 80px;
	}
	@include media('sp') {
		flex: 0 0 55px;
		max-width: 55px;
		height: 55px;
	}
	&__inner{
		position: relative;
		height: 100%;

		@include media('lpc') {
			width:40px;
			height: 30px;
		}
		
		@include media() {
			width:20px;
			height: 15px;
		}
		span{
			width:100%;
			height: 2px;
			background: #ffffff;
			display: block;
			position: absolute;
			left: 0;
			&:nth-of-type(1){
				top:0;
			}
			&:nth-of-type(2){
				top:calc(50% - 1.5px);
			}
			&:nth-of-type(3){
				bottom:0;
			}
		}
	}
}
.gnavi__item_type_tw{
	display: none;
	@include media('tab') {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.header__lang-term{
			color: #ffffff;
			@include media() {
				font-size: 1.1rem;
			}
		}
		.header__lang-select{
			color: #ffffff;
			@include media() {
				width:115px;
				font-size: 1.1rem;
				padding:9px 8px 9px 10px;
			}
		}
	}
}

////////////////////////////////////////
//main
////////////////////////////////////////
.main{
	display: block;
	@include media(){
		width:100vw;
		overflow: hidden;
	}
}

.scrolled{
	.main{
		margin-top: 180px;
		@include media(){
			margin-top: 55px;
		}
	}
}

////////////////////////////////////////
//mv
////////////////////////////////////////
.mv{
	position: relative;
	z-index: 2;
	overflow: hidden;
	&__slider{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
	}
	&__slide{
		background: no-repeat center center / cover;
		width: 100%;
		height: 100%;
	}
	.slick-list{
		height: 100%;
	}
	.slick-track{
		height: 100%;
	}
	&_page{
		&_top{
			// background: url(../img/mv_img.png) no-repeat top center / cover;
			height: 730px;
			@include media('tab'){
				height: 70vw;
			}
			@include media(){
				height: 400px;
			}
			&:before{
				content: "";
				display: block;
				width: 161px;
				height: 302px;
				background:url(../img/img_mv_icon01.svg) no-repeat top center / cover;
				position: absolute;
				z-index:2;
				left:40px;
				top:-20px;
				@include media(){
					display: none;
				}
			}
			&:after{
				content: "";
				display: block;
				width: 167px;
				height: 380px;
				background:url(../img/img_mv_icon02.svg) no-repeat top center / cover;
				position: absolute;
				z-index:2;
				right:45px;
				bottom:-21px;
				@include media(){
					display: none;
				}
			}
			.mv__inner{
				z-index:2;
				position: relative;
				justify-content: flex-start;
			}
			.mv__catch{
				width: 228px;
				position: relative;
				margin-left: -50px;
				margin-top: -78px;
				@include media(){
					position: static;
					width: 41.4%;
					margin-left: -20px;
					margin-bottom: 10px;
					margin-top: 0;
				}
			}
			.mv__ttl{
				width: 100%;
				height: auto;
				margin-left: -20px;
				margin-bottom: 30px;
				@include media(){
					margin-left: -10px;
					margin-bottom: 20px;
				}
			}
			.mv__desc{
				font-size: 1.6rem;
				color: #fff;
				font-weight: 600;
				line-height: 1.75;
				position: relative;
				@include media(){
					font-size: 1.1rem;
					line-height: 1.63;
					br{
						display: none;
					}
				}
			}
		}
		&_lp{
			height: 260px;
			&:before{
				content: "";
				background: url(../img/bg_pt.png) repeat top center / auto;;
				width: 100%;
				height: 100%;
				position: absolute;
				top:0;
				left: 0;
				z-index:1;
			}
			@include media('tab'){
				height: 160px;
			}
			@include media(){
				margin-bottom: 30px;
			}
		}
		&_ot{
			position: relative;
			background: url(../img/bg_mv02.png) center center / cover;
		}
		&_news{
			background: url(../img/bg_mv_news.png) no-repeat center center /  cover;
		}
		&_blog{
			background: url(../img/blog_mv.png) no-repeat center center /  cover;
		}
		&_about{
			background: url(../img/bg_mv_about.png) no-repeat center center /  cover;
		}
		&_sitemap{
			background: url(../img/bg_mv_sitemap.png) no-repeat center center /  cover;
		}
		&_sugarfree{
			background: url(../img/bg_mv_sugarfree.png) no-repeat center center /  cover;
		}
		&_center{
			background: url(../img/bg_mv_center.png) no-repeat center center /  cover;
		}
		&_process{
			background: url(../img/bg_mv_process.png) no-repeat center center /  cover;
		}
		&_project{
			background: url(../img/bg_mv_project.png) no-repeat center center /  cover;
		}
		&_oem{
			background: url(../img/bg_mv_oem.png) no-repeat center center /  cover;
		}
		&_performance{
			background: url(../img/bg_mv_performance.png) no-repeat center center /  cover;
		}
		&_karte{
			background: url(../img/bg_mv_karte.png) no-repeat center center /  cover;
		}
		&_karteDetail{
			background: no-repeat center center /  cover;
			background-size: 230% auto;
		}
		&_karteDetail01{
			background: url(../img/img_case_02.png) no-repeat center center /  cover;
		}
		&_company{
			background: url(../img/bg_mv_company.png) no-repeat center center /  cover;
		}
	}
	&__filter{
		background: url(../img/bg_pt.png) repeat top center / auto;;
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;
		left: 0;
		z-index:1;

	}
	
	
	&__error{
		background: url(../img/experiment_mv.png) no-repeat center center /  cover;
	}
	&__sitemap{
		background: url(../img/experiment_mv.png) no-repeat center center /  cover;
	}
	&__company{
		background: url(../img/company_mv.png) no-repeat center center /  cover;
	}
	
	&__process{
		background: url(../img/process_mv.png) no-repeat center center /  cover;
	}
	&__production{
		background: url(../img/production_mv.png) no-repeat center center /  cover;
	}
	&__project{
		background: url(../img/project_mv.png) no-repeat center center /  cover;
	}
	&__oem{
		background: url(../img/news_mv.png) no-repeat center center /  cover;
	}
	&__possibility{
		background: url(../img/possibility_mv.png) no-repeat center center /  cover;
	}
	&__flow{
		background: url(../img/flow_mv.png) no-repeat center center /  cover;
	}
	&__develop{
		background: url(../img/technology_mv.png) no-repeat center center /  cover;
	}
	&__full-order{
		background: url(../img/full-order_mv.png) no-repeat center center /  cover;
	}
	&__semi-order{
		background: url(../img/semi-order_mv.png) no-repeat center center /  cover;
	}
	&__contact{
		background: url(../img/contact_mv.png) no-repeat center center /  cover;
	}
	&__whatsCandy{
		background: url(../img/whats-candy_mv.png) no-repeat center center /  cover;
	}
	&__hardCandy{
		background: url(../img/hard-candy_mv.png) no-repeat center center /  cover;
	}
	&__schedule{
		background: url(../img/schedule_mv.png) no-repeat center center /  cover;
	}
	
	


	
	&__inner{
		width: 580px;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		align-items: center;
		@include media('tab') {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
	
	&__img{
		position: relative;
		top:-20px;

		@include media('tab') {
			flex: 0 0 30%;
			max-width: 30%;
		}

		@include media()m{
			top:-11px;
			flex: 0 0 45%;
			max-width: 45%;
			right:39px;
		}
	}
	&__ttl{
		text-align: center;
		position: relative;
		z-index: 2;
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 1;
		color:#ffffff;
		@include media(){
			font-size: 2.1rem;
			line-height: 1.5;
		}
	}
}

////////////////////////////////////////
//productList
////////////////////////////////////////
.productList{
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
	&__item{
		flex-basis: calc(33.333% - 11px);
		max-width: calc(33.333% - 11px);
		width: calc(33.333% - 11px);
		display: flex;
		flex-direction: column;
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
		@include media(){
			flex-basis: 100%;
			max-width: 100%;
			width: 100%;
			margin-bottom: 12px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
	&__inner{
		background-color: #fff;
		padding: 16px 16px 0 16px;
		height: 100%;
		@include media(){
			padding:15px 15px 0 15px;
		}
		
	}
	&__fig{
		margin-bottom: 16px;
		@include media(){
			margin-bottom: 13px;
		}
		a{
			&:hover{
				opacity: .75;
			}
		}
		img{
			@include media(){
				width: 100%;
				height: 31.25vw;
				object-fit: cover;
			}
		}
	}
	&__name{
		font-size: 2.0rem;
		font-weight: 600;
		text-align: center;
		margin-bottom: 8px;
		@include media(){
			font-size: 1.6rem;
			margin-bottom: 10px;
		}
	}
	&__desc{
		font-size: 1.4rem;
		@include media(){
			font-size: 1.1rem;
			line-height: 1.63;
		}
	}
	&__btInner{
		background-color: #fff;
		padding: 16px;
		@include media(){
			padding:13px 15px 15px 15px;
		}
	}
	&__bt{
		display: block;
		text-align: center;
		background-color: #212121;
		color: #fff;
		font-size: 1.4rem;
		text-decoration: none;
		padding:16.5px 0;
		position: relative;
		@include media(){
			font-size: 1.1rem;
			padding:14px 0;
		}
		&:hover{
			opacity: .75;
		}
		&:after{
			content: "";
			display: block;
			background: url(../img/arrow_right_thin.svg) no-repeat center center/6px 12px;
			width: 6px;
			height: 12px;
			position: absolute;
			top:calc(50% - 6px);
			right: 11px;
			@include media(){
				background: url(../img/arrow_right_thin.svg) no-repeat center center / 5px 10px;
				width: 5px;
				height: 10px;
				top: calc(50% - 5px);
				right: 8px;
			}
		}
	}
}



////////////////////
//articleDetails
////////////////////
.articleDetails{
	max-width: 850px;
	padding-bottom: 55px;
	@include media() {
		padding-bottom: 45px;
	}
	&__header{
		margin-bottom: 45px;
		@include media() {
			margin-bottom: 30px;
		}
	}
	&__list{
		margin-bottom: 15px;
	}
	&__item{}
	&__date{
		font-size: 1.4rem;
		margin-left: 1em;
		@include media() {
			order:2;
			font-size: 1.1rem;
		}
	}
	&__cat{
		font-size: 1.2rem;
		background-color: #f3a56c;
		color: #fff;
		padding:0 14px;
		height: 24px;
		border-radius: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		&_planning{
			background-color: #76ccc8;
		}
		&_study{
			background-color: #8e9ea9;
		}
		@include media() {
			font-size: 1.0rem;
			padding:1px 15px;
			height: 20px;
		}
		&_type_blog{
			font-size: 1.2rem;
			background-color: #fff;
			padding: 3px 0;
			text-align: center;
			width: 92px;
			flex: 0 0 92px;
			margin-right: 22px;
			border-radius: 0;
			color: #212121;
			margin-right: 0;
			@include media() {
				order: 1;
				font-size: 1.0rem;
				width: 60px;
				flex: 0 0 60px;
				padding: 1px 0;
			}
		}
	}
	&__ttl{
		font-size: 3.0rem;
		line-height: 1.3;
		font-weight: 800;
		margin-bottom: 25px;
		@include media() {
			font-size: 2.1rem;
			margin-bottom: 30px;
		}
	}
	&__eyecatch{
		position: relative;
		z-index: 0;
		margin-bottom: -16px;
		@include media() {
			margin-bottom: 10px;
		}
		img{
			width: 100%;
		}
	}
	&__body{
		max-width: 650px;
		margin-left: auto;
		margin-right: auto;
		p{
			font-size: 1.6rem;
			line-height: 1.625;
			margin:20px 0;
			@include media() {
				font-size: 1.2rem;
				margin:15px 0;
			}
			img{
				margin: 27px 0;
				height: auto;
				@include media() {
					margin-bottom: 3px;
				}
			}
		}
		h2{
			font-size: 2.4rem;
			font-weight: 800;
			margin:30px 0;
			@include media() {
				font-size: 1.8rem;
				margin:15px 0;
			}
		}
		h3{
			font-size: 1.8rem;
			font-weight: 600;
			margin: 20px 0;
			@include media() {
				font-size: 1.6rem;
				margin:15px 0;
			}
		}
		img{
			height: auto;
		}
	}
	&__footer{
		margin-top:55px;
		@include media() {
			margin-top:30px;
		}
	}
}

////////////////////
//contactTop
////////////////////
.contactTop{
	display: block;
	padding-top:90px;
	padding-bottom: 90px;
	background: url(../img/bg_contact.png) no-repeat center center / cover;
	position: relative;

	@include media('tab') {
		padding-left: 30px;
		padding-right: 30px;
	}
	@include media() {
		position: relative;
		padding:33px 20px 37px 20px;
		&:before{
			background: url(../img/bg_pt.png) center center / 3px 3px;
		}
	}
	&__wrap{
		@include media() {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__ttl{
		font-size: 3.2rem;
		font-weight: 700;
		text-align: center;
		color: #ffffff;
		margin-bottom: 20px;
		position: relative;
		z-index: 2;
		@include media() {
			position: relative;
			z-index: 2;
			font-size: 1.5rem;
			margin-bottom: 7px;
			line-height: 1.66;
			text-align: left;
		}
		&-st{
			color:#F9C900;
			font-size: 4.0rem;
			@include media() {
				font-size: 2.4rem;
				line-height: 1.3;
			}
		}
	}
	&__para{
		font-size: 1.5rem;
		line-height: 2;
		text-align: center;
		color:#ffffff;
		margin-bottom: 1.5em;
		position: relative;
		z-index: 2;
		@include media() {
			position: relative;
			z-index: 3;
			font-size: 1.05rem;
			text-align: left;
			line-height: 1.5;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__bt{
		font-size: 1.6rem;
		font-weight: 700;
		color:#ffffff;
		background-color: #E3007F;
		display: block;
		width:324px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		text-decoration: none;
		text-align: center;
		line-height: 1;
		padding:25px 0;
		margin-top: 43px;
		position: relative;
		z-index: 2;
		&:after{
			content:"";
			display: block;
			width:8px;
			height: 13.4px;
			background: url(../img/arrow_icon_wh.svg) no-repeat center center / cover;
			right:20px;
			top:calc(50% - 6.7px);
			position: absolute;
			
		}
		&:hover{
			opacity: .75;
		}
		@include media() {
			position: relative;
			z-index: 3;
			font-size: 1.2rem;
			padding:18.5px 0;
			margin-top:21px;
			width: 100%;
		}
	}
}

////////////////////////////////////////
//footer
////////////////////////////////////////
.footer{
	position: relative;
	z-index: 2;
	&__nav{
		padding:65px 0;
		@include media('tab') {
			padding-top:0;
			display: none;
		}
		&List{
			border-right: 1px solid #f1f3f3;
			&:nth-of-type(1){
				width: 21.7%;
				flex-basis: 21.7%;
				max-width: 21.7%;
				padding-right: 45px;
			}
			&:nth-of-type(2){
				width: 27.2%;
				flex-basis: 27.2%;
				max-width: 27.2%;
				padding-left: 55px;
				padding-right: 50px;
			}
			&:nth-of-type(3){
				width: 22.4%;
				flex-basis: 22.4%;
				max-width: 22.4%;
				padding-left: 55px;
				padding-right: 50px;
			}
			&:nth-of-type(4){
				width: 28.7%;
				flex-basis: 28.7%;
				max-width: 28.7%;
				border:none;
				padding-left: 55px;
			}
		}
		&Item{
			line-height: 2.14;
		}
		&Link{
			text-decoration: none;
			color: #212121;
			font-size: 1.4rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			letter-spacing: -.04em;
			line-height: 2.14;
			&:before{
				content: "";
				display: block;
				background: url(../img/icon_bg_arrow.svg) no-repeat center center / 14px 14px;
				width: 14px;
				height: 14px;
				display: block;
				margin-right: 5px;
			}
			&:hover{
				text-decoration: underline;
			}
		}
	}
	&__child{
		&List{}
		&Term{
			padding-left: 1em;
			line-height: 2.14;
			&Link{
				text-decoration: none;
				color: #212121;
				font-size: 1.4rem;
				font-weight: 600;
				line-height: 2.14;
				&:before{
					content:"-";
					padding-right: .5em;
				}
				&:hover{
					text-decoration: underline;
				}
			}
		}
		&Detail{
			padding-left: 2em;
			line-height: 2.14;
		}
		&Link{
			text-decoration: none;
			color: #212121;
			font-size: 1.4rem;
			line-height: 2.14;
			display: block;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	&__bn-inner{
	}
	&__bnList{
		padding:17px 0;
		flex-wrap: wrap;
		@include media() {
			padding:24px 40px;
		}

	}
	&__bnItem{
		padding:0 6px;
		@include media() {
			flex: 0 0 100%;
			max-width:100%;
			padding:0;
			margin-bottom: 5px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&_type{
			&_gummi{
				img{
					width:150px;
					@include media() {
						width:96px;
					}
				}
			}
			&_nisshin{
				img{
					width:162px;
					@include media() {
						width:112px;
					}
				}
			}
			&_dolce{
				img{
					width:134px;
					@include media() {
						width:89px;
					}
				}
			}
		}
		
		
		a{
			display: flex;
			justify-content: center;
			align-items: center;
			width:190px;
			background: #ffffff;
			text-align: center;
			height: 56px;
			&:hover{
				opacity: .75;
			}
			@include media() {
				width:100%;
				height: 45px;
			}
		}
	}
	&__desc{
		@include media('tab') {
			padding:30px 0  30px 0;
		}
		@include media() {
			padding:20px 0  20px 0;
		}
		.wrap{
			@include media('tab') {
				flex-wrap: wrap;
				justify-content: center;
			}
		}
	}
	&__logo{
		flex: 0 0 266px;
		max-width: 266px;
		margin-right: 20px;
		position: relative;
		top: -6px;
		@include media() {
			flex: 0 0 60%;
			max-width: 60%;
			margin-right: 0;
			margin-bottom: 0;
			position: static;
		}
		a{
			&:hover{
				opacity: .75;
			}
		}
	}
	&__copy{
		background-color: #ec6c0e;
		&Text{
			font-size: 1.2rem;
			color: #fff;
			@include media() {
				font-size: .9rem;
				padding:10px 0;
				text-align: center;
				flex: 0 0 100%;
			}
		}
	}
	&__otherList{
		padding:28px 0;
		@include media() {
			display: none;
		}
	}
	&__otherItem{
		&:after{
			content:"｜";
			padding:0 2em;
			display: inline-block;
			color: #fff;
		}
		&:last-of-type{
			&:after{
				display: none;
			}
		}
		a{
			font-size: 1.2rem;
			color:#fff;
			text-decoration: none;
		}
	}
	
}
.fn-list{
	&__term{
		margin-bottom: 5px;
		a{
			font-size: 1.6rem;
			font-weight: 700;
			text-decoration: none;
			color:#212121;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	&__desc{}
}
.fn-child{
	&__list{}
	&__item{
		&--tw{
			width:30px;
			height: 30px;
			img{
				width:30px;
				height: 30px;
			}
			a{
				&:hover{
					opacity: .75;
				}
			}
		}
	}
	&__link{
		font-size: 1.4rem;
		text-decoration: none;
		color:#212121;
		line-height: 2.2;
		&:hover{
			text-decoration: underline;
		}
	}
	&__list--bd{

	}
	&__item--bd{
		font-size: 1.6rem;
		margin-bottom: 12px;
		&:last-of-type{
			margin-bottom: 0;
		}
		a{
			font-size: 1.6rem;
			color:#212121;
			font-weight: 700;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}
.company-detail{
	padding:42px 0;
	@include media('tab') {
		padding:15px 0 0 0;
		text-align: center;
	}
	&__term{
		font-size: 1.4rem;
		font-weight: 700;
		margin-bottom: 2px;
		@include media() {
			font-size: 1.0rem;
			line-height: 1.6;
		}
	}
	&__desc{
		font-size: 1.4rem;
		@include media() {
			font-size: 1.0rem;
			line-height: 1.6;
		}
	}
	&__list{
		@include media() {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	&__ad{
		&:after{
			content:"/";
			display: block;
			padding:0 1em;
			@include media() {
				display: none;
			}
		}
	}
	&__tel{}
}