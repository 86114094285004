@charset "utf-8";


////////////////////
//schedule
////////////////////
.schedule {
	&__container {
		padding: 70px 0 96px;
		@include media() {
			padding: 12px 0 38px;
		}
	}
	&__sectionTtl {
		font-size: 2.4rem;
		font-weight: 700;
		margin-bottom: 46px;
		text-align: center;
		@include media() {
			font-size: 1.6rem;
			margin-bottom: 16px;
		}
	}
	&__term {
		align-items: center;
		background: url(../img/bg_dark-green.png) repeat;
		color: #fff;
		flex-shrink: 0;
		font-size: 1.4rem;
		display: flex;
		justify-content: center;
		letter-spacing: 2px;
		margin: 0 26px 28px 0;
		text-orientation: upright;
		width: 40px;
		writing-mode: vertical-rl;
		@include media() {
			font-size: 1.2rem;
			letter-spacing: 0.5px;
			margin: 0 12px 14px 0;
			width: 30px;
		}
	}
	&__flow {
		width: calc(100% - 76px);
		@include media() {
			width: calc(100% - 42px);
		}
	}
	&__item {
		margin-bottom: 28px;
		position: relative;
		@include media() {
			margin-bottom: 16px;
		}
	}
	&__item:after {
		background: #f5ae7a;
		content: "";
		display: block;
		height: 50px;
		left: 38px;
		position: absolute;
		top: 100%;
		width: 10px;
		@include media() {
			left: 18px;
			height: 20px;
			width: 8px;
		}
	}
	&__item_type_last:after {
		content: none;
	}
	&__ttl {
		background: #fff url(../img/bg_orange.png) repeat;
		font-size: 2.4rem;
		font-weight: 700;
		padding: 15px 0 15px 26px;
		position: relative;
		z-index: 1;
		@include media() {
			display: flex;
			font-size: 1.4rem;
			padding: 8px 0 8px 20px;
		}
	}
	&__num {
		color: #ec6c0e;
		margin-right: 10px;
	}
	&__text {
		background: #fff;
		font-size: 1.6rem;
		line-height: 1.75;
		padding: 22px 26px;
		position: relative;
		z-index: 1;
		@include media() {
			font-size: 1.3rem;
			padding: 8px 20px;
		}
	}
	&__notice {
		padding-left: 70px;
		@include media() {
			font-size: 1.2rem;
			padding-left: 42px;
		}
	}
}